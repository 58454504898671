import { WhatsappTable } from "../utils/databaseTypes";

export const createWhatsappClient = () => {
  fetch(process.env.REACT_APP_SERVER_URL + "/phoneConection", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      console.log("whatsapp Client created:", data);
    })
    .catch((error) => console.error("Error:", error));
};

export const getWhatsappConnections = (setWhatsappConnections: React.Dispatch<React.SetStateAction<WhatsappTable[]>>) => {
  fetch(process.env.REACT_APP_SERVER_URL + "/phoneConection", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      setWhatsappConnections(data.data ? data.data : []);
    })
    .catch((error) => console.error("Error:", error));
}

export const deleteWhatsappConnection = (id: number, setUpdate: (data: {
  conectionUpdated: WhatsappTable;
}, remove : boolean) => void) => {
  fetch(process.env.REACT_APP_SERVER_URL + "/phoneConection", {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify({ id })
  })
    .then((response) => response.json())
    .then((data) => {
      setUpdate({ conectionUpdated: data.data }, true);
    })
    .catch((error) => console.error("Error:", error));
}

export const updateWhatsappConnection = (id: number, comand: string , updateParams: Partial<WhatsappTable> , setUpdate: (data: {
  conectionUpdated: WhatsappTable;
}) => void) => {
  fetch(process.env.REACT_APP_SERVER_URL + "/phoneConection" , {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify({ id, comand, updateParams })
  })
    .then((response) => response.json())
    .then((data) => {
      setUpdate({ conectionUpdated: data.data });
    })
    .catch((error) => console.error("Error:", error));
}