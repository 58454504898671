import * as React from "react";
import { LineChart } from "@mui/x-charts/LineChart";
import { Box, Button, ButtonGroup, Card, Typography } from "@mui/joy";
import InsightsIcon from "@mui/icons-material/Insights";
import { range } from "@mui/x-data-grid/internals";
import { ChatTable, MessageTable } from "../../utils/databaseTypes";
import { periods } from "./DashboardPage";
import { CurveType } from "@mui/x-charts";

const ChartData = {
  title: "Contatos",
};

const CurveStyles = [
  "catmullRom",
  "linear",
  "monotoneX",
  "monotoneY",
  "step",
  "natural",
  "stepAfter",
  "stepBefore",
];

export default function GridChartPane({
  setPeriod,
  period,
  chatsData,
  messagesData,
}: {
  period: string;
  setPeriod: React.Dispatch<React.SetStateAction<string>>;
  chatsData: ChatTable[];
  messagesData: MessageTable[];
}) {
  const [curveStyle, setCurveStyle] = React.useState<CurveType>("catmullRom");
  const [dataType, setBarsDataType] = React.useState("NOVOS");

  const toggleCurveStyle = () => {
    const currentIndex = CurveStyles.indexOf(curveStyle);
    const nextIndex = (currentIndex + 1) % CurveStyles.length;
    setCurveStyle(CurveStyles[nextIndex] as CurveType);
  };

  const [timeSlice, setTimeSlice] = React.useState<number[]>([]);
  const [dataPoints, setdataPoints] = React.useState<number[]>([]);

  React.useEffect(() => {
    const now = new Date();
    let timeSlice: number[] = [];
    let startTime = new Date(now.getTime());

    if (period === "DIARIA") {
      timeSlice = range(0, 24);
      startTime.setHours(0, 0, 0, 0); // Começa no início do dia atual
    } else if (period === "SEMANAL") {
      timeSlice = range(0, 7);
      startTime.setDate(now.getDate() - now.getDay()); // Começa no início da semana atual
      startTime.setHours(0, 0, 0, 0); // Ajusta para o início do dia
    } else if (period === "MENSAL") {
      timeSlice = range(0, 30);
      startTime.setDate(1); // Começa no início do mês atual
      startTime.setHours(0, 0, 0, 0); // Ajusta para o início do dia
    } else if (period === "ANUAL") {
      timeSlice = range(0, 12);
      startTime.setMonth(0, 1); // Começa no início do ano atual
      startTime.setHours(0, 0, 0, 0); // Ajusta para o início do dia
    }

    const timePeriod = periods.get(period) ?? 0;

    const dataPoints = timeSlice.map((_, index): number => {
      const startTimeSlice = startTime.getTime() / 1000 + (timePeriod / timeSlice.length) * index;
      const endTimeSlice = startTimeSlice + (timePeriod / timeSlice.length);
      
      if (dataType === "NOVOS") {
        return chatsData.filter(
          (chat) => chat.initial_date >= startTimeSlice && chat.initial_date < endTimeSlice
        ).length;
      } else if (dataType === "MENSAGENS") {
        return messagesData.filter(
          (message) => message.timestamp >= startTimeSlice && message.timestamp < endTimeSlice
        ).length;
      } else if (dataType === "FINALIZADOS") {
        const finalizadosChats = chatsData.filter(chat => chat.status === "FINALIZADO");
        return messagesData.filter(
          (message) => 
            finalizadosChats.some(chat => chat.id === message.chat_id) &&
            message.timestamp >= startTimeSlice && message.timestamp < endTimeSlice
        ).length;
      }
      return 0;
    });

    setTimeSlice(timeSlice);
    setdataPoints(dataPoints);
  }, [period, dataType, chatsData, messagesData]);

  const getLabel = (value: number): string => {
    const now = new Date();
    if (period === "DIARIA") {
      const hour = new Date(now.getTime());
      hour.setHours(value);
      return `${hour.getHours().toString().padStart(2, "0")}:00`;
    } else if (period === "SEMANAL") {
      const day = new Date(now.getTime());
      day.setDate(day.getDate() - day.getDay() + value);
      return day.toLocaleDateString("pt-BR", { weekday: "short" });
    } else if (period === "MENSAL") {
      if ( !Number.isInteger(value)) return ''
      const day = new Date(now.getFullYear(), now.getMonth(), value + 1);
      return day.getDate().toString();
    } else if (period === "ANUAL") {
      const month = new Date(now.getFullYear(), value, 1);
      return month.toLocaleDateString("pt-BR", { month: "short" });
    }
    return value.toString();
  };

  return (
    <Card
      sx={{
        backgroundColor: "transparent",
        borderRadius: 10,
        height: "100%",
        width: "100%",
        border: "1px solid",
        borderColor: "divider",
        padding: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography level="h2">{ChartData.title}</Typography>

          <ButtonGroup
            buttonFlex={1}
            aria-label="flex button group"
            sx={{
              mx: 1,
              width: { xs: 250, sm: 400, md: 400, lg: 400 },
              overflow: "auto",
            }}
          >
            <Button
              variant={dataType === "NOVOS" ? "solid" : "outlined"}
              onClick={() => setBarsDataType("NOVOS")}
            >
              NOVOS
            </Button>
            <Button
              variant={dataType === "MENSAGENS" ? "solid" : "outlined"}
              onClick={() => setBarsDataType("MENSAGENS")}
            >
              MENSAGENS
            </Button>
            <Button
              variant={dataType === "FINALIZADOS" ? "solid" : "outlined"}
              onClick={() => setBarsDataType("FINALIZADOS")}
            >
              FINALIZADOS
            </Button>
          </ButtonGroup>

          <Button onClick={toggleCurveStyle}>
            <InsightsIcon />
          </Button>
        </Box>
      </Box>
      <Box>
        <LineChart
          sx={{
            "& .MuiAreaElement-series-vendas": {
              fill: "url('#myGradient')",
            },
          }}
          xAxis={[
            {
              id: "Years",
              // scaleType: 'time',
              valueFormatter: getLabel,
              data: timeSlice,
            },
          ]}
          series={[
            {
              id: dataType,
              data: dataPoints,
              area: true,
              curve: curveStyle,
            },
          ]}
          height={300}
          margin={{ left: 25, right: 20, top: 20, bottom: 20 }}
          grid={{ vertical: true, horizontal: true }}
        >
          <defs>
            <linearGradient id="myGradient" gradientTransform="rotate(90)">
              <stop offset="5%" stopColor="rgba(2,178,175, 0.8)" />
              <stop offset="95%" stopColor="rgba(2,178,175, 0.1)" />
            </linearGradient>
          </defs>
        </LineChart>
      </Box>
    </Card>
  );
}
