import * as React from "react";
import Box from "@mui/joy/Box";
import ListDivider from "@mui/joy/ListDivider";
import ListItem from "@mui/joy/ListItem";
import ListItemButton, { ListItemButtonProps } from "@mui/joy/ListItemButton";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import { toggleMessagesPane } from "../../utils/utils";
import { ChatTable } from "../../utils/databaseTypes";
import AvatarWithStatus from "./AvatarWithStatus";
import CircleIcon from '@mui/icons-material/Circle';
import { formatarMensagemDataHora } from "../../utils/formatTimestamp";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import BlockIcon from '@mui/icons-material/Block';

type ChatListItemProps = ListItemButtonProps & {
  chat: ChatTable;
  selectedChatId: number | undefined;
  setSelectedChat: (chat: ChatTable) => void;
};

export default function ChatListItem(props: ChatListItemProps) {
  const { chat, selectedChatId, setSelectedChat } = props;
  const selected = selectedChatId === chat.id;

  return (

    <React.Fragment>
      <ListItem >
        <ListItemButton
          onClick={() => {
            toggleMessagesPane();
            setSelectedChat({ ...chat });
          }}
          selected={selected}
          color="neutral"
          sx={{ flexDirection: "column", alignItems: "initial", gap: 1 }}
        >
          <Stack direction="row" spacing={1.5}>
            <AvatarWithStatus chat={chat} />
            <Box sx={{ flex: 1 }}>
              <Typography width={150} noWrap level="title-sm">{chat.contact_name}</Typography>
              <Typography level="body-sm">{chat.contact_phone.split("@")[0]}</Typography>
            </Box>
            <Box sx={{ lineHeight: 1.5, textAlign: "right" }}>
              <Typography
                level="body-xs"
                noWrap
                sx={{ display: { xs: "none", md: "block" }, mb: 0.5 }}
              >
                {formatarMensagemDataHora(new Date(chat.last_date * 1000).toISOString())}
              </Typography>
              <Stack justifyContent={"right"} direction="row" spacing={1}>
                {chat.status === "FINISHED" && <TaskAltIcon color="success" />}
                {chat.is_bot_blocked && <BlockIcon color="warning" />}
              </Stack>
            </Box>
          </Stack>
        </ListItemButton>
      </ListItem>
      <ListDivider sx={{ margin: 0 }} />
    </React.Fragment>
  );
}
