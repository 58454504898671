import { formatDistanceToNow, parseISO } from "date-fns";
import { ptBR } from "date-fns/locale";

export const formatarMensagemDataHora = (dataHora: string) => {
  const formatTimeAgo = (isoString: string): string => {
    const date = parseISO(isoString);
    return formatDistanceToNow(date, { addSuffix: true, locale: ptBR });
  };

  const isoString = dataHora;
  return formatTimeAgo(isoString);
};

export function formatTimestamp(isoString: string) {
  const date = new Date(isoString);
  return date.toLocaleString("pt-BR", {
    weekday: "long",
    hour: "numeric",
    minute: "numeric",
  });
}

export function formatTimestampFromSeconds(seconds: number) {
  const date = new Date(seconds * 1000);
  return date.toLocaleString("pt-BR", {
    weekday: "long",
    hour: "numeric",
    minute: "numeric",
  });
}

