import { MessageBlock } from "../utils/types";

export async function uploadMessageBlocksMedia(messageBlocks: MessageBlock[], folderName: string, fluxoId: number) {
    const formData = new FormData();
    formData.append('folderName', folderName);  // Nome da pasta
    console.log('mensages:', messageBlocks);
    console.log('folderName:', folderName);
    console.log('fluxoId:', fluxoId);

    const mediaPromises = messageBlocks.map(async (block) => {
      if (block.hasMidia && block.media && block.media.media_filename && block.media.media_type && !block.media.media_url?.includes("$SERVER_URL")) {
        const media = block.media;
        console.log('Processando arquivo:', media.media_filename);
        try {
          const response = await fetch(media.media_url ?? '');
          const blob = await response.blob();
          const file = new File([blob], media.media_filename ?? '', { type: media.media_type });
          formData.append('files', file);
        } catch (error) {
          console.error(`Erro ao processar o arquivo ${media.media_filename}:`, error);
        }
      }
    });

    // Aguarda todas as promessas serem resolvidas
    console.log(mediaPromises);
    await Promise.all(mediaPromises);

    if (formData.getAll('files').length === 0) {
        return messageBlocks;
    }

    // console.log('Arquivos prontos para envio:', formData.getAll('files'));

    try {
      const uploadResponse = await fetch(process.env.REACT_APP_SERVER_URL + '/script/media', {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: formData
      });
  
      const result = await uploadResponse.json();

      console.log('Resposta do envio de arquivos:', result);
  
      // Atualiza os MessageBlocks com as novas URLs de mídia
      if (result.fileUrls && result.fileUrls.length > 0) {
        result.fileUrls.forEach(({ originalFilename, url }: any) => {
          const blockToUpdate = messageBlocks.find(
            block => block.media && block.media.media_filename === originalFilename
          );
          if (blockToUpdate && blockToUpdate.media) {
            blockToUpdate.media.media_url = url;  // Atualiza a URL da mídia
          }
        });
      }
  
      console.log('Arquivos enviados e MessageBlocks atualizados:', messageBlocks);
      return messageBlocks;  // Retorna os MessageBlocks atualizados
  
    } catch (error) {
      console.error('Erro no envio dos arquivos:', error);
      throw error;
    }
}

export const deleteFluxoMedia = async (fluxoId: number) => {
  console.log('Deletando mídias do fluxo:', fluxoId);
  try {
    const response = await fetch(process.env.REACT_APP_SERVER_URL + '/script/media', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      },
      body: JSON.stringify({ fluxoId }) // Envia o fluxoId no corpo da requisição
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error:', error);
    return undefined;
  }
};

