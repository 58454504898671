import * as React from "react";
import Box from "@mui/joy/Box";
import FormControl from "@mui/joy/FormControl";
import Textarea from "@mui/joy/Textarea";
import { Autocomplete, AutocompleteOption, IconButton, ListItemContent, Stack, Typography } from "@mui/joy";

import SendRoundedIcon from "@mui/icons-material/SendRounded";

export type MessageInputProps = {
  onSubmit: (msg: string) => void;
};

export default function MessageInput(props: MessageInputProps) {
  const { onSubmit } = props;
  const [textAreaValue, setTextAreaValue] = React.useState("");
  const textAreaRef = React.useRef<HTMLDivElement>(null);
  const handleClick = () => {
    if (textAreaValue.trim() !== "") {
      onSubmit(textAreaValue);
      setTextAreaValue("");
    }
  };
  return (
    <Box sx={{ px: 2, pb: 3 }}>
      <FormControl>
        <Textarea
          placeholder="Escreva sua mensagem"
          aria-label="Message"
          ref={textAreaRef}
          onChange={(e) => {
            setTextAreaValue(e.target.value);
          }}
          value={textAreaValue}
          minRows={3}
          maxRows={10}
          endDecorator={
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              flexGrow={1}
              sx={{
                py: 1,
                pr: 1,
                borderTop: "1px solid",
                borderColor: "divider",
              }}
            >
              <Box></Box>
              <Box sx={{display: 'flex', gap: 1}}>
              {/* <IconButton
                size="sm"
                variant="solid"
                color="primary"
                sx={{ alignSelf: "center", borderRadius: "sm" }}
                onClick={handleClick}
              >
                <MicIcon />
              </IconButton> */}

              {/* <IconButton
                size="sm"
                variant="solid"
                color="primary"
                sx={{ alignSelf: "center", borderRadius: "sm" }}
                onClick={handleClick}
              >
                <VolumeUpIcon />
              </IconButton> */}

              <IconButton
                size="sm"
                variant="solid"
                color="primary"
                sx={{ alignSelf: "center", borderRadius: "sm" }}
                onClick={handleClick}
              >
                <SendRoundedIcon />
              </IconButton>
              </Box>
            </Stack>
          }
          onKeyDown={(event) => {
            if (event.key === "Enter" && (event.metaKey || event.ctrlKey)) {
              handleClick();
            }
          }}
          sx={{
            "& textarea:first-of-type": {
              minHeight: 72,
            },
          }}
        />
      </FormControl>
    </Box>
  );
}
