import { ProductOffer } from "../../utils/types";
import { useState } from "react";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  IconButton,
  Input,
  Textarea,
  Typography,
} from "@mui/joy";
import { RowTableForm, StringRow, StringRowRender } from "./RowTableForm";
import BorderColorRoundedIcon from "@mui/icons-material/BorderColorRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

export const ProductOfferCard = ({
  offer,
  updateOffer,
}: {
  offer: ProductOffer;
  updateOffer: (offer: ProductOffer, remove?: boolean) => void;
}) => {
  const [editMode, setEditMode] = useState(false);
  const [announcement, setAnnouncement] = useState(offer.announcement);
  const [price, setPrice] = useState(offer.price);

  const insertInstallment = () => {
    updateOffer({
      ...offer,
      installments: [
        ...offer.installments,
        { id: Date.now(), content: "Nova opção de parcelamento" },
      ],
    });
  };

  const updateInstallment = (row: StringRow, remove?: boolean) => {
    if (remove) {
      offer.installments = offer.installments.filter((i) => i.id !== row.id);
    } else {
      offer.installments = offer.installments.map((i) =>
        i.id === row.id ? row : i
      );
    }
    updateOffer(offer);
  };

  const handleSave = () => {
    updateOffer({ ...offer, announcement, price });
    setEditMode(false);
  };

  return (
    <Card>
      {editMode ? (
        <Box>
          <Textarea
            startDecorator={<BorderColorRoundedIcon />}
            value={announcement}
            onChange={(e) => setAnnouncement(e.target.value)}
          />
          <Divider sx={{ my: 1 }} />
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <Input
              fullWidth
              startDecorator={<BorderColorRoundedIcon />}
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
            <Button onClick={handleSave}>Salvar</Button>
          </Box>
        </Box>
      ) : (
        <Box>
          <Typography>{offer.announcement}</Typography>
          <Divider sx={{ my: 1 }} />
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <Typography sx={{ flex: 1 }}>{offer.price}</Typography>
            <IconButton
              sx={{
                cursor: "pointer",
                opacity: 0.2,
                "&:hover": {
                  opacity: 1,
                },
              }}
              onClick={() => setEditMode(true)}
            >
              <EditRoundedIcon />
            </IconButton>
            <IconButton
              sx={{
                cursor: "pointer",
                opacity: 0.2,
                "&:hover": {
                  opacity: 1,
                },
              }}
              onClick={() => {
                updateOffer(offer, true);
              }}
            >
              <CloseRoundedIcon />
            </IconButton>
          </Box>
        </Box>
      )}

      <RowTableForm
        title="Opções de parcelamento"
        rows={offer.installments.map((row) => (
          <StringRowRender row={row} updateRow={updateInstallment} />
        ))}
        insertButtonTitle="Adicionar opção"
        insert={insertInstallment}
      />

      
    </Card>
  );
};
