import { ScriptStep } from "./fluxoDataTypes";
import { MessageBlock, VariableBlock } from "./types";

export const convertScriptBlocksToFluxoBlocks = (
  scriptBlocks: (MessageBlock | VariableBlock)[],
  has_confirmation: boolean,
  endBlocks: MessageBlock[]
): {
  steps_blocks: ScriptStep[];
  message_blocks: { [key: string]: MessageBlock[] };
} => {
  if (!scriptBlocks[0]) return { steps_blocks: [], message_blocks: {} };
  const groups: (MessageBlock | VariableBlock)[][] = [[]];
  const message_blocks: { [key: string]: MessageBlock[] } = {};
  const variable_blocks: VariableBlock[] = [];

  let blockType = scriptBlocks[0].type;

  for (const block of scriptBlocks) {
    if (block.type !== blockType) {
      blockType = block.type;
      groups.push([]);
    }
    groups[groups.length - 1].push(block);
  }

  const createAutoReturnBlock = (group: MessageBlock[]): string => {
    const index = Object.keys(message_blocks).length;
    const key = `$MESSAGES_BLOCK_${index}`;
    message_blocks[key] = group;
    return key;
  };

  const steps_blocks: ScriptStep[] = groups.map((group) => {
    if (group[0].type === "variable") {
      variable_blocks.push(...(group as VariableBlock[]));
    }

    return {
      type: group[0].type === "message" ? "auto" : "info",
      has_confirmation: false,
      automatic_return:
        group[0].type === "message"
          ? createAutoReturnBlock(group as MessageBlock[])
          : null,
      info_interest:
        group[0].type === "variable"
          ? (group as VariableBlock[])
              .filter((item) => item.required)
              .map((block) => block.varKey)
          : null,
    };
  });

  steps_blocks.push({
    type: "confirmation",
    automatic_return: null,
    info_interest: null,
  });

  message_blocks["$END_BLOCK"] = endBlocks;

  steps_blocks.push({
    type: "auto",
    automatic_return: "$END_BLOCK",
    info_interest: null,
  });

  steps_blocks.push({
    type: "conversation",
    automatic_return: null,
    info_interest: null,
  });

  return { steps_blocks, message_blocks };
};
