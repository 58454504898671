import { createContext, useEffect, useState } from "react";
import { JsonFluxoData, FluxoData } from "../utils/fluxoDataTypes";
import { json_exemplo } from "../utils/demos/fluxoDemoSuporteEscova";
import { json_exemplo2 } from "../utils/demos/fluxoDemoCalcinha";
import { jsonToFluxoData } from "../utils/convertJsonToFluxoData";
import { getFluxoData, getFluxos } from "../hooks/fluxos";
import { ScriptTable } from "../utils/databaseTypes";

interface FluxoContextData {
  fluxosData: FluxoData[];
  setFluxosData: React.Dispatch<React.SetStateAction<FluxoData[]>>;
  jsonfluxosData: JsonFluxoData[];
  setJsonFluxosData: React.Dispatch<React.SetStateAction<JsonFluxoData[]>>;
  scriptData: ScriptTable[];
  setScriptData: React.Dispatch<React.SetStateAction<ScriptTable[]>>;
}

export const FluxoDataContext = createContext<FluxoContextData>(
  {} as FluxoContextData
);

interface FluxoProviderProps {
  children: React.ReactNode;
}

export const FluxoDataProvider: React.FC<FluxoProviderProps> = ({
  children,
}) => {
  const [jsonfluxosData, setJsonFluxosData] = useState<JsonFluxoData[]>([]);
  const [fluxosData, setFluxosData] = useState<FluxoData[]>([]);
  const [scriptData, setScriptData] = useState<ScriptTable[]>([]);

  useEffect(() => {
    // console.log("fluxo context",JSON.stringify(json_exemplo2) );
    // setJsonFluxosData([json_exemplo, json_exemplo2]);
    getData();
  }, []);

  const getData = async () => {
    const scriptData = (await getFluxos()) ?? [];
    setScriptData(scriptData);
  };

  useEffect(() => {
    const fetchFluxos = async () => {
      const updated = scriptData.map(
        async (script) => await getFluxoData(script.fluxo_url)
      );

      Promise.all(updated).then((updatedFluxos) => {
        const fluxos = updatedFluxos.filter((fluxo) => fluxo !== undefined);
        setFluxosData(fluxos);
      });
    };

    fetchFluxos();
  }, [scriptData]);


  useEffect(() => {
    if (jsonfluxosData.length > 0) {
      const fluxos = jsonfluxosData.map((json) => jsonToFluxoData(json));
      setFluxosData(fluxos);
    }
  }, [jsonfluxosData]); // carrega fluxos dos jsons

  return (
    <FluxoDataContext.Provider
      value={{
        fluxosData,
        setFluxosData,
        jsonfluxosData,
        setJsonFluxosData,
        scriptData,
        setScriptData,
      }}
    >
      {children}
    </FluxoDataContext.Provider>
  );
};
