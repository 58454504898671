import * as React from "react";
import AspectRatio from "@mui/joy/AspectRatio";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Divider from "@mui/joy/Divider";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import FormHelperText from "@mui/joy/FormHelperText";
import Input from "@mui/joy/Input";
import IconButton from "@mui/joy/IconButton";
import Textarea from "@mui/joy/Textarea";
import Stack from "@mui/joy/Stack";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import Typography from "@mui/joy/Typography";
import Tabs from "@mui/joy/Tabs";
import TabList from "@mui/joy/TabList";
import Tab, { tabClasses } from "@mui/joy/Tab";
import Card from "@mui/joy/Card";
import CardActions from "@mui/joy/CardActions";
import CardOverflow from "@mui/joy/CardOverflow";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import KeyRoundedIcon from "@mui/icons-material/KeyRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { Badge, TabPanel } from "@mui/joy";
import { Avatar } from "@mui/material";
import { UserDataContext } from "../../contexts";
import { updateUser } from "../../hooks/user";

export function SettingsPage() {
  return (
    <Box sx={{ flex: 1, width: "100%" }}>
      <Box>
        <Box sx={{ px: { xs: 2, md: 6 }, mt: 2 }}>
          <Typography level="h2" component="h1" sx={{ mt: 1, mb: 2 }}>
            Configurações
          </Typography>
        </Box>
        <Tabs
          defaultValue={0}
          sx={{
            bgcolor: "transparent",
          }}
        >
          <TabList
            tabFlex={1}
            size="sm"
            sx={{
              pl: { xs: 0, md: 4 },
              justifyContent: "left",
              [`&& .${tabClasses.root}`]: {
                fontWeight: "600",
                flex: "initial",
                color: "text.tertiary",
                [`&.${tabClasses.selected}`]: {
                  bgcolor: "transparent",
                  color: "text.primary",
                  "&::after": {
                    height: "2px",
                    bgcolor: "primary.500",
                  },
                },
              },
            }}
          >
            <Tab sx={{ borderRadius: "6px 6px 0 0" }} indicatorInset value={0}>
              Perfil
            </Tab>
            <Tab sx={{ borderRadius: "6px 6px 0 0" }} indicatorInset value={1}>
              Time
            </Tab>
            <Tab sx={{ borderRadius: "6px 6px 0 0" }} indicatorInset value={2}>
              Planos
            </Tab>
            <Tab sx={{ borderRadius: "6px 6px 0 0" }} indicatorInset value={3}>
              Custos
            </Tab>
          </TabList>

          <TabPanel value={0}>
            <Stack spacing={2} sx={{ mt: 2, mx: "auto", maxWidth: 700 }}>
              <Profile></Profile>
            </Stack>
          </TabPanel>
        </Tabs>
      </Box>
    </Box>
  );
}

const Profile = () => {
  const { userData, setUserData } = React.useContext(UserDataContext);
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const cleanForm = () => {
    setName("");
    setEmail("");
    setPassword("");
  }

  const submitUpdateData = async () => {

    const updatedData = await updateUser({
      name: name !== "" ? name : undefined,
      email: email !== "" ? email : undefined,
      password: password !== "" ? password : undefined,
    });
    cleanForm();
    if (updatedData) setUserData(updatedData);
  };

  return (
    <Card sx={{}}>
      <Box sx={{ mb: 1 }}>
        <Typography level="title-md">Perfil</Typography>
        <Typography level="body-sm">Informações pessoais da conta</Typography>
      </Box>
      <Divider />
      <Stack direction={{ xs: "column", md: "row" }} spacing={3} sx={{ my: 1 }}>
        <Stack direction="column" spacing={1} sx={{ alignItems: "center" }}>
          <Badge
            variant="plain"
            badgeInset="14%"
            badgeContent={
              <IconButton
                aria-label="upload new picture"
                size="sm"
                variant="outlined"
                sx={{
                  bgcolor: "transparent",
                  zIndex: 2,
                  borderRadius: "50%",
                  boxShadow: "sm",
                }}
              >
                <EditRoundedIcon />
              </IconButton>
            }
          >
            <Avatar sx={{ width: 120, height: 120 }} />
          </Badge>
        </Stack>
        <Stack spacing={2} sx={{ flexGrow: 1 }}>
          <Stack spacing={1}>
            <FormLabel>Nome</FormLabel>
            <FormControl
              sx={{ display: { sm: "flex-column", md: "flex-row" }, gap: 2 }}
            >
              <Input
                size="sm"
                placeholder={userData?.name}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </FormControl>
          </Stack>
          <Stack
            direction="row"
            sx={{ flexGrow: 1, flexWrap: "wrap", gap: 1.5 }}
          >
            <FormControl sx={{ flexGrow: 1 }}>
              <FormLabel>Email</FormLabel>
              <Input
                size="sm"
                type="email"
                startDecorator={<EmailRoundedIcon />}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder={userData?.email}
                sx={{ flexGrow: 1 }}
              />
            </FormControl>

            <FormControl sx={{ flexGrow: 1 }}>
              <FormLabel>Senha</FormLabel>
              <Input
                size="sm"
                type="password"
                startDecorator={<KeyRoundedIcon />}
                placeholder="**************"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                sx={{ flexGrow: 1 }}
              />
            </FormControl>
          </Stack>
        </Stack>
      </Stack>

      <CardOverflow sx={{ borderTop: "1px solid", borderColor: "divider" }}>
        <CardActions sx={{ alignSelf: "flex-end", pt: 2 }}>
          <Button size="sm" variant="outlined" color="neutral" onClick={cleanForm}>
            Cancelar
          </Button>
          <Button size="sm" variant="solid" onClick={submitUpdateData}>
            Salvar
          </Button>
        </CardActions>
      </CardOverflow>
    </Card>
  );
};
