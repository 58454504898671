import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import Tabs from "@mui/joy/Tabs";
import TabList from "@mui/joy/TabList";
import Tab, { tabClasses } from "@mui/joy/Tab";
import BookmarkAddIcon from "@mui/icons-material/BookmarkAdd";
import ContactsTable from "./ContactsTable";
import TagsTable from "./TagsTable";
import { Button, TabPanel } from "@mui/joy";
import { FixedSizeList } from "react-window";

export function SavedPage() {
  return (
    <Box sx={{ flex: 1, width: "100%" }}>
      <Box sx={{ display: "flex", px: { xs: 2, md: 6 }, mt: 2 }}>
        <Typography
          level="h2"
          component="h1"
          sx={{ mb: 2 }}
          endDecorator={<BookmarkAddIcon />}
        >
          Salvos
        </Typography>

        <Button
          variant="outlined"
          color="primary"
          sx={{ display: {xs:'none', sm: 'block'}, ml: "auto", mt: 1, height: 40 }}
        >
          Importar
        </Button>
        <Button
          variant="solid"
          color="primary"
          size="sm"
          sx={{ display: {xs:'none', sm: 'block'}, ml: 2, mt: 1, height: 40 }}
        >
          Exportar
        </Button>
      </Box>
      <Tabs
        defaultValue={0}
        sx={{
          bgcolor: "transparent",
        }}
      >
        <TabList
          tabFlex={1}
          size="sm"
          sx={{
            pl: { xs: 0, md: 4 },
            justifyContent: "left",
            [`&& .${tabClasses.root}`]: {
              fontWeight: "600",
              flex: "initial",
              color: "text.tertiary",
              [`&.${tabClasses.selected}`]: {
                bgcolor: "transparent",
                color: "text.primary",
                "&::after": {
                  height: "2px",
                  bgcolor: "primary.500",
                },
              },
            },
          }}
        >
          <Tab sx={{ borderRadius: "6px 6px 0 0" }} indicatorInset value={0}>
            Contatos
          </Tab>
          <Tab sx={{ borderRadius: "6px 6px 0 0" }} indicatorInset value={1}>
            Etiquetas
          </Tab>
        </TabList>
        <TabPanel value={0}>
          <Box
            component="main"
            className="MainContent"
            sx={{
              px: { xs: 2, md: 6 },
              display: "flex",
              flexDirection: "column",
              minWidth: 0,
              gap: 1,
              height: "100%",
            }}
          >
            <ContactsTable />
          </Box>
        </TabPanel>
        <TabPanel value={1}>
          <Box
            component="main"
            className="MainContent"
            sx={{
              px: { xs: 2, md: 6 },
              display: "flex",
              flexDirection: "column",
              minWidth: 0,
              gap: 1,
              height: "100%",
            }}
          >
            <TagsTable />
          </Box>
        </TabPanel>
      </Tabs>
    </Box>
  );
}
