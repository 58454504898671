import * as React from "react";
import Sheet from "@mui/joy/Sheet";
import MessagesPane from "./MessagesPane";
import ChatsPane from "./ChatsPane";
import { ChatsDataContext } from "../../contexts/ChatsContext";
import { useContext, useEffect } from "react";
import { ProfilePane } from "./ProfilePane";
import { ChatTable, MessageTable } from "../../utils/databaseTypes";
import { ConnectionsContext } from "../../contexts";

export function MessagesPage() {
  const [openProfilePane, setOpenProfilePane] = React.useState(false);
  const [selectedChat, setSelectedChat] = React.useState<ChatTable | undefined>();
  const [selectedChatMessages, setSelectedChatMessages] = React.useState<MessageTable[] | undefined>();
  const { chatsData, messagesData } = useContext(ChatsDataContext);
  const { whatsappPhones } = useContext(ConnectionsContext);
  const [chatsAvailable, setChatsAvailable] = React.useState<ChatTable[]>([]);

  useEffect(() => {
      setChatsAvailable(chatsData.filter((chat) => whatsappPhones.some((phone) => phone.id === chat.whatsapp_id)));
  }, [chatsData, whatsappPhones]);

  useEffect(() => {
    if (selectedChat) setSelectedChatMessages(messagesData.filter((msgs) => msgs.chat_id === selectedChat.id));
  }, [selectedChat, messagesData]);

  return (
    <Sheet
      sx={{
        flex: 1,
        width: "100%",
        mx: "auto",
        height: "100%",
        display: "grid",
        gridTemplateColumns: {
          xs: "1fr",
          sm: "minmax(min-content, min(30%, 400px)) 1fr auto",
          lg: "minmax(min-content, min(30%, 400px)) 1fr auto",
        },
      }}
    >
      <Sheet
        sx={{
          position: { xs: "fixed", sm: "sticky" },
          transform: {
            xs: "translateX(calc(100% * (var(--MessagesPane-slideIn, 0) - 1)))",
            sm: "none",
          },
          transition: "transform 0.4s, width 0.4s",
          zIndex: 100,
          width: "100%",
          maxWidth: 400,
          height: "100%",
        }}
      >
        <ChatsPane
          chats={chatsAvailable}
          selectedChatId={selectedChat?.id}
          setSelectedChat={setSelectedChat}
        />
      </Sheet>
      <MessagesPane chat={selectedChat} messages={selectedChatMessages} setOpenProfilePane={setOpenProfilePane} openProfilePane={openProfilePane} />

      <Sheet
        sx={{
          position: { lg: "sticky", md: "fixed", xs: "fixed", sm: "fixed" },
          right: 0,
          transition: {
            lg: "none",
            md: "width 0.4s",
            xs: "width 0.4s",
            sm: "width 0.4s",
          },
          zIndex: 100,
          width: openProfilePane ? "100%" : 2,
          leftt: 400,
          maxWidth: 400,
          height: "100%",
          overflow: "hidden",
        }}
      >
        { selectedChat && <ProfilePane user={selectedChat} setOpenPane={setOpenProfilePane} openPane={openProfilePane}  />}
      </Sheet>
    </Sheet>
  );
}
