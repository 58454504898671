import React from "react";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import PermPhoneMsgRoundedIcon from "@mui/icons-material/PermPhoneMsgRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import BlockIcon from "@mui/icons-material/Block";
import AutorenewRoundedIcon from "@mui/icons-material/AutorenewRounded";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { PieChart } from "@mui/x-charts/PieChart";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import PodcastsIcon from "@mui/icons-material/Podcasts";
import {
  Autocomplete,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Select,
  Option,
  Stack,
  CardOverflow,
  Chip,
  ColorPaletteProp,
} from "@mui/joy";

export const StreamingPage: React.FC = (props) => {
  const [startRightNow, setStartRightNow] = React.useState<boolean>(false);

  return (
    <Box sx={{ flex: 1, width: "100%" }}>
      <Box sx={{ px: { xs: 2, md: 6 }, mt: 2 }}>
        <Typography
          level="h2"
          component="h1"
          sx={{ mt: 1, mb: 2 }}
          endDecorator={<PodcastsIcon />}
        >
          Transmissão
        </Typography>
        
        {/* <Button variant="soft" color="warning">
          EM BREVE
        </Button> */}
      </Box>

      <Box
        sx={{
          mt: 4,
          p: 2,
          px: { xs: 2, md: 6 },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: 2,
          }}
        >
          <Card
            sx={{
              flex: 1,
              p: 2,
              pb: 4,
              maxWidth: { xs: "none", sm: 400 },
              gap: 2,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography level="h3" component="h2" sx={{ mt: 1, mb: 2 }}>
              Configurações da transmissão
            </Typography>

            <FormControl>
              <FormLabel>Nome</FormLabel>
              <Input placeholder="nome da transmissão" />
            </FormControl>

            <FormControl>
              <FormLabel>Fluxo</FormLabel>
              <Autocomplete
                placeholder="nome do fluxo"
                options={[
                  { label: "Fluxo 1", value: "1" },
                  { label: "Fluxo 2", value: "2" },
                ]}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Bloco</FormLabel>
              <Autocomplete
                placeholder="bloco"
                options={[
                  { label: "Fluxo 1", value: "1" },
                  { label: "Fluxo 2", value: "2" },
                ]}
              />
            </FormControl>

            <Divider />
            <FormControl>
              <FormLabel>Data de hora</FormLabel>
              <Autocomplete
                disabled={startRightNow}
                placeholder="00/00/00"
                options={[
                  { label: "Fluxo 1", value: "1" },
                  { label: "Fluxo 2", value: "2" },
                ]}
              />
              <Checkbox
                sx={{ mt: 1 }}
                onChange={(event) => setStartRightNow(event.target.checked)}
                label="Agora"
                size="sm"
              />
            </FormControl>
          </Card>

          <Card
            sx={{
              flex: 1,
              p: 2,
              pb: 4,
              gap: 2,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Box
                sx={{
                  display: "flex",
                  mt: 1,
                  mb: 2,
                  justifyContent: "space-between",
                }}
              >
                <Typography level="h3" component="h2">
                  Publico Alvo
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                  }}
                >
                  <PermPhoneMsgRoundedIcon color="action" />

                  <Typography level="h3">
                    {`${Math.floor(Math.random() * 1000)}`}
                  </Typography>
                  <Typography level="h3" color="neutral">
                    selecionados
                  </Typography>
                </Box>
              </Box>

              {renderFilters()}
            </Box>

            <CardActions buttonFlex="0 1 120px" sx={{ gap: 2 }}>
              <IconButton
                variant="outlined"
                color="neutral"
                sx={{ mr: "auto" }}
              >
                S2
              </IconButton>
              <Button
                variant="solid"
                color="primary"
                sx={{ textWrap: "nowrap" }}
              >
                iniciar Transmissão
              </Button>
            </CardActions>
          </Card>
        </Box>

        <Typography level="h3" component="h2" sx={{ pt: 4, pb: 1 }}>
          Lista de transmissões
        </Typography>
        <Stack>
          <TransmissaoDemo></TransmissaoDemo>
        </Stack>
      </Box>
    </Box>
  );
};

const TransmissaoDemo = () => {
  return (
    <Card orientation="horizontal">
      <CardContent>
        <Typography level="h4">Transmissão 1</Typography>
        <Typography level="body-md" color="neutral">
          20 de setembro de 2021
        </Typography>
      </CardContent>
      <CardOverflow sx={{ px: 2, alignItems: "center" }}>
        <Chip
          variant="soft"
          size="md"
          sx={{ height: 50 }}
          startDecorator={
            {
              Paid: <CheckRoundedIcon />,
              // Refunded: <AutorenewRoundedIcon />,
              // Cancelled: <BlockIcon />,
            }["Paid"]
          }
          color={
            {
              Paid: "success",
              Refunded: "neutral",
              Cancelled: "danger",
            }["Paid"] as ColorPaletteProp
          }
        >
          iniciado
        </Chip>
      </CardOverflow>
    </Card>
  );
};

const renderFilters = () => (
  <Box sx={{ display: "flex" }}>
    <Box sx={{ flex: 1 }}>
      <Box sx={{ display: "flex", gap: 2 }}>
        <Typography
          startDecorator={<FilterAltIcon />}
          id="filter-modal"
          level="h4"
          sx={{ pb: 1 }}
        >
          Adicionar filtro
        </Typography>

        <Select
          size="sm"
          placeholder="Filter"
          slotProps={{ button: { sx: { whiteSpace: "nowrap" } } }}
        >
          <Option value="paid">Paid</Option>
          <Option value="pending">Pending</Option>
          <Option value="refunded">Refunded</Option>
          <Option value="cancelled">Cancelled</Option>
        </Select>
      </Box>
      <FormControl size="sm">
        <FormLabel>Tag</FormLabel>
        <Select
          size="sm"
          placeholder="Filter by status"
          slotProps={{ button: { sx: { whiteSpace: "nowrap" } } }}
        >
          <Option value="paid">Paid</Option>
          <Option value="pending">Pending</Option>
          <Option value="refunded">Refunded</Option>
          <Option value="cancelled">Cancelled</Option>
        </Select>
      </FormControl>
      <FormControl size="sm">
        <FormLabel>Status</FormLabel>
        <Select size="sm" placeholder="All">
          <Option value="all">All</Option>
          <Option value="refund">Refund</Option>
          <Option value="purchase">Purchase</Option>
          <Option value="debit">Debit</Option>
        </Select>
      </FormControl>
      <FormControl size="sm">
        <FormLabel>Fluxo</FormLabel>
        <Select size="sm" placeholder="All">
          <Option value="all">All</Option>
          <Option value="olivia">Olivia Rhye</Option>
          <Option value="steve">Steve Hampton</Option>
          <Option value="ciaran">Ciaran Murray</Option>
          <Option value="marina">Marina Macdonald</Option>
          <Option value="charles">Charles Fulton</Option>
          <Option value="jay">Jay Hoper</Option>
        </Select>
      </FormControl>
    </Box>

    <Box sx={{ display: { xs: "none", sm: "none", md: "none", lg: "block" } }}>
      <PieChart
        series={[
          {
            data: [
              { id: 0, value: 10, label: "series A" },
              { id: 1, value: 15, label: "series B" },
              { id: 2, value: 20, label: "series C" },
            ],
            highlightScope: { faded: "global", highlighted: "item" },
            faded: { innerRadius: 30, additionalRadius: -30, color: "gray" },
            innerRadius: 50,
            outerRadius: 90,
            paddingAngle: 2,
            cornerRadius: 2,
            startAngle: -90,
            endAngle: 300,
            cx: 120,
            cy: 120,
          },
        ]}
        slotProps={{
          legend: { hidden: true },
        }}
        width={220}
        height={250}
      />
    </Box>
  </Box>
);

const filtersData = [
  { value: 5, label: "A" },
  { value: 10, label: "B" },
  { value: 15, label: "C" },
  { value: 20, label: "D" },
];
