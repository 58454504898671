import {
  Box,
  Button,
  Card,
  CardOverflow,
  Checkbox,
  FormControl,
  Typography,
} from "@mui/joy";
import { MessageBlock, ProductOffer } from "../../utils/types";
import { useContext } from "react";
import MessageCard from "./MessageCard";
import { ProductOfferCard } from "./ProductOfferCard";
import { FluxoData } from "../../utils/fluxoDataTypes";
import { DraggableList } from "../../components/draggableList/DraggableList";
import { CardBlockActions } from "./CardBlockActions";
import { FluxoDataContext } from "../../contexts";
import { PostFluxoBottom } from "./PostFluxoBottom";

export const RemarketingSection = ({ script }: { script: FluxoData }) => {
  const { setFluxosData } = useContext(FluxoDataContext);

  console.log("monatndo remarketing card");

  const insertRemarketing = () => {
    const newRemarketing: {
      messages: MessageBlock[];
      activate_next_offer: boolean;
      ativation_hours: number;
    } = {
      messages: [
        {
          id: Date.now(),
          type: "message",
          content: "Nova mensagem",
          hasMidia: false,
          editMode: true,
        },
      ],
      ativation_hours: 0,
      activate_next_offer: false,
    };

    script.remarketing_messages = [
      newRemarketing,
      ...script.remarketing_messages,
    ];

    script.is_saved = false;
    setFluxosData((prev) => [
      script,
      ...prev.filter((f) => f.id !== script.id),
    ]);
  };

  const createOffer = () => {
    const newOffer: ProductOffer = {
      id: Date.now(),
      announcement: "Nova Oferta",
      price: "",
      installments: [],
    };

    script.remarketing_offers = [newOffer, ...script.remarketing_offers];

    script.is_saved = false;
    setFluxosData((prev) => [
      script,
      ...prev.filter((f) => f.id !== script.id),
    ]);
  };

  const updateOffers = (offer: ProductOffer, remove?: boolean) => {
    if (remove) {
      script.remarketing_offers = script.remarketing_offers.filter(
        (o) => o.id !== offer.id
      );
    } else {
      script.remarketing_offers = script.remarketing_offers.map((o) =>
        o.id === offer.id ? offer : o
      );
    }

    script.is_saved = false;
    setFluxosData((prev) => [
      script,
      ...prev.filter((f) => f.id !== script.id),
    ]);
  };

  return (
    <Box
      sx={{
        margin: "auto",
        maxWidth: 500,
        display: "flex",
        flexDirection: "column",
        pb: 10,
        gap: 3,
        position: "relative", // Add relative positioning to the Stack container
      }}
    >
      <Typography level={"h2"}>Remarketings</Typography>

      <Button onClick={insertRemarketing} fullWidth sx={{}}>
        adicionar remarketing
      </Button>

      {script.remarketing_messages.map((remarketing, idx) => (
        <RemarketingCard key={idx} script={script} idx={idx} />
      ))}

      <Typography level={"h2"}>Lista de ofertas de remarketing</Typography>

      {script.remarketing_offers.map((offer, idx) => (
        <ProductOfferCard
          key={offer.id}
          offer={offer}
          updateOffer={updateOffers}
        />
      ))}

      <Button onClick={() => createOffer()} fullWidth sx={{ mt: 2 }}>
        adicionar oferta
      </Button>

      <PostFluxoBottom fluxo={script} />
    </Box>
  );
};

const NumberInput = ({
  value,
  onChange,
  label,
}: {
  value: number;
  onChange: (value: number) => void;
  label: string;
}) => {
  return (
    <Card orientation="horizontal" sx={{ gap: 1, p: 1, m: 0 }}>
      <input
        style={{ width: 40, border: "none", fontSize: 20 }}
        type="number"
        value={value}
        onChange={(e) =>
          Number(e.target.value) >= 0 && Number(e.target.value) < 100
            ? onChange(Number(e.target.value))
            : 0
        }
      />
      <Typography>{label}</Typography>
    </Card>
  );
};

const RemarketingCard = ({
  script,
  idx,
}: {
  script: FluxoData;
  idx: number;
}) => {
  const { setFluxosData } = useContext(FluxoDataContext);
  // console.log(script.remarketing_messages[idx]);
  // console.log(script.remarketing_messages[idx].messages);

  const updateAtivationHours = (hours: number) => {
    script.remarketing_messages[idx].ativation_hours = hours;
    script.is_saved = false;
    setFluxosData((prev) => [
      script,
      ...prev.filter((f) => f.id !== script.id),
    ]);
  };

  const updateActivateNextOffer = (activate: boolean) => {
    script.remarketing_messages[idx].activate_next_offer = activate;
    script.is_saved = false;
    setFluxosData((prev) => [
      script,
      ...prev.filter((f) => f.id !== script.id),
    ]);
  };

  const updateRemarketingPrevMessages = (
    blocks: MessageBlock[],
    remove?: boolean
  ) => {
    const last = script.remarketing_messages[idx].messages.pop();
    script.remarketing_messages[idx].messages = blocks;
    script.remarketing_messages[idx].messages.push(last as MessageBlock);

    const updatedScript = {
      ...script,
      remarketing_messages: script.remarketing_messages,
      is_saved: false,
    };

    setFluxosData((prev) => [
      updatedScript,
      ...prev.filter((f) => f.id !== script.id),
    ]);
  };

  const updateMessage = (block: MessageBlock, remove?: boolean) => {
    if (remove) {
      script.remarketing_messages[idx].messages = script.remarketing_messages[
        idx
      ].messages.filter((b) => b.id !== block.id);
    } else {
      script.remarketing_messages[idx].messages = script.remarketing_messages[
        idx
      ].messages.map((b) => (b.id === block.id ? block : b));
    }

    script.is_saved = false;
    setFluxosData((prev) => [
      script,
      ...prev.filter((f) => f.id !== script.id),
    ]);
  };

  const insertMessage = () => {
    const newMessage: MessageBlock = {
      id: Date.now(),
      type: "message",
      content: "Nova mensagem",
      hasMidia: false,
      media: undefined,
      editMode: true,
    };

    script.remarketing_messages[idx].messages = [
      newMessage,
      ...script.remarketing_messages[idx].messages,
    ];

    script.is_saved = false;
    setFluxosData((prev) => [
      script,
      ...prev.filter((f) => f.id !== script.id),
    ]);
  };

  return (
    <Card variant="outlined">
      <CardOverflow
        sx={{ display: "flex", gap: 2, flexWrap: "wrap", margin: 0 }}
      >
        <FormControl sx={{ width: "100%" }}>
          <NumberInput
            value={script.remarketing_messages[idx].ativation_hours}
            onChange={updateAtivationHours}
            label="horas para ativar o remarketing"
          />
        </FormControl>
      </CardOverflow>

      <Button onClick={insertMessage}>adicionar nova mensagem final</Button>

      <DraggableList
        items={script.remarketing_messages[idx].messages.slice(0, -1)}
        handleUpdatedItems={(updated) => {
          updateRemarketingPrevMessages(updated);
        }}
        renderItem={(item: MessageBlock) => (
          <Box sx={{ textAlign: "center", p: 1 }}>
            <CardBlockActions
              block={item}
              updateItem={(block, remove) =>
                updateMessage(block as MessageBlock, remove)
              }
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <MessageCard block={item} updateItem={updateMessage} />
              </Box>
            </CardBlockActions>
          </Box>
        )}
      />

      <MessageCard
        fixed
        endLabel={"de volta ao Fluxo"}
        block={
          script.remarketing_messages[idx].messages[
            script.remarketing_messages[idx].messages.length - 1
          ]
        }
        updateItem={updateMessage}
      />

      <Checkbox
        checked={script.remarketing_messages[idx].activate_next_offer}
        onChange={(e) => {
          updateActivateNextOffer(e.target.checked);
        }}
        label={"ativar proxima oferta da lista?"}
      />
    </Card>
  );
};
