import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Typography,
  Button,
  Checkbox,
  ToggleButtonGroup,
  Modal,
  Select,
  Option,
  Sheet,
  ModalDialog,
  Textarea,
} from "@mui/joy";
import { FAQBlock, keysUF, ProductOffer } from "../../utils/types";
import {
  KeyValueRow,
  RowTableForm,
  StringRowRender,
  KeyValueRowRender,
  StringRow,
} from "./RowTableForm";
import React, { useContext, useEffect } from "react";
import { ProductOfferCard } from "./ProductOfferCard";
import FAQCard from "./FAQCard";
import { FluxoData } from "../../utils/fluxoDataTypes";
import { FluxoDataContext } from "../../contexts";
import { set } from "date-fns";
import { PostFluxoBottom } from "./PostFluxoBottom";

const daysOfWeek = [
  { value: "1", label: "Seg" },
  { value: "2", label: "Ter" },
  { value: "3", label: "Qua" },
  { value: "4", label: "Qui" },
  { value: "5", label: "Sex" },
  { value: "6", label: "Sáb" },
  { value: "0", label: "Dom" },
];

export const ProductInfoSection = ({ script }: { script: FluxoData }) => {
  const [openCreateAttribute, setOpenCreateAttribute] = React.useState(false);
  const [openCreateCity, setOpenCreateCity] = React.useState(false);
  const [deliveryAllBrazil, setDeliveryAllBrazil] = React.useState(false);
  const { setFluxosData } = useContext(FluxoDataContext);

  const CreateAttributeForm = () => {
    const [key, setKey] = React.useState("");
    const [value, setValue] = React.useState("");
    const [description, setDescription] = React.useState("");

    const handleAddAttribute = () => {
      const keyRow: KeyValueRow = {
        id: Date.now(),
        keyRow: key,
        valueRow: value,
        description,
      };

      script.product.variations.push(keyRow);
      script.is_saved = false; setFluxosData((prev) => [
        ...prev.filter((f) => f.id !== script.id),
        script,
      ]);

      setOpenCreateAttribute(false);
    };

    return (
      <Sheet
        sx={{
          display: "flex",
          flexDirection: "column",
          p: 2,
          borderRadius: 10,
          gap: 1,
        }}
      >
        <FormControl sx={{ width: "100%" }}>
          <FormLabel sx={{ mb: 0 }}>Escolha</FormLabel>
          <Input
            placeholder="Atributo"
            onChange={(event) => setKey(event.target.value)}
            value={key}
          />
        </FormControl>

        <FormControl sx={{ width: "100%" }}>
          <FormLabel sx={{ mb: 0 }}>Descrição</FormLabel>
          <Input
            placeholder="Descrição"
            value={description}
            onChange={(event) => setDescription(event.target.value)}
          />
        </FormControl>

        <Button fullWidth sx={{ mt: 2 }} onClick={handleAddAttribute}>
          adicionar variação
        </Button>
      </Sheet>
    );
  };

  const CreateCity = () => {
    const [keyId, setKeyId] = React.useState<number>(0);
    const [value, setValue] = React.useState("");
    const [isAllState, setIsAllState] = React.useState(false);

    const handleAddCity = () => {
      const keyRow = keysUF.find((key) => key.id === keyId);
      if (!keyRow) return; // Ensure keyRow is defined

      const city: KeyValueRow = {
        id: Date.now(),
        keyRow: keyRow.title,
        valueRow: value,
        description: keyRow.description,
      };

      script.dict_texts.cities.push(city);
      script.is_saved = false; setFluxosData((prev) => [
        script,
        ...prev.filter((f) => f.id !== script.id),
      ]);

      setOpenCreateCity(false);
    };

    return (
      <Sheet
        sx={{
          p: 2,
          borderRadius: 10,
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <FormControl sx={{ width: "100%" }}>
          <FormLabel sx={{ mb: 0 }}>Estado</FormLabel>
          <Select
            defaultValue={0}
            onChange={(event, value) => event && setKeyId(value ?? 0)}
          >
            {keysUF.map((key, idx) => (
              <Option key={idx} value={key.id}>
                {key.description + " - " + key.title}
              </Option>
            ))}
          </Select>
        </FormControl>

        <Checkbox
          defaultValue={0}
          onChange={(event) => event && setIsAllState(event.target.checked)}
          label="Todo o estado?"
        />

        <FormControl disabled={isAllState} sx={{ width: "100%" }}>
          <FormLabel sx={{ mb: 0 }}>Cidade</FormLabel>
          <Input
            placeholder="Cidade"
            onChange={(event) => setValue(event.target.value)}
          />
        </FormControl>

        <Button fullWidth sx={{ mt: 2 }} onClick={handleAddCity}>
          adicionar cidade
        </Button>
      </Sheet>
    );
  };

  const updateName = (name: string) => {
    script.product.name = name;
    script.is_saved = false; setFluxosData((prev) => [
      script,
      ...prev.filter((f) => f.id !== script.id),
    ]);
  };

  const updateDescription = (description: string) => {
    script.product.description = description;
    script.is_saved = false; setFluxosData((prev) => [
      script,
      ...prev.filter((f) => f.id !== script.id),
    ]);
  };

  const insertAttribute = () => {
    setOpenCreateAttribute(true);
  };

  const updateAttribute = (row: KeyValueRow, remove?: boolean) => {
    const idx = script.product.variations.findIndex((r) => r.id === row.id);
    if (idx === -1) return;

    if (remove) {
      script.product.variations.splice(idx, 1);
    } else {
      script.product.variations[idx] = row;
    }

    script.is_saved = false; setFluxosData((prev) => [
      script,
      ...prev.filter((f) => f.id !== script.id),
    ]);
  };

  const insertFAQ = () => {
    const faq: StringRow = {
      id: Date.now(),
      content: "",
      editing: true,
    };
    script.dict_texts.faqs.push(faq);
    script.is_saved = false; setFluxosData((prev) => [
      script,
      ...prev.filter((f) => f.id !== script.id),
    ]);
  };

  const updateFAQ = (row: StringRow, remove?: boolean) => {
    const idx = script.dict_texts.faqs.findIndex((r) => r.id === row.id);
    if (idx === -1) return;

    if (remove) {
      script.dict_texts.faqs.splice(idx, 1);
    } else {
      script.dict_texts.faqs[idx] = row;
    }

    script.is_saved = false; setFluxosData((prev) => [
      script,
      ...prev.filter((f) => f.id !== script.id),
    ]);
  };

  const insertPaymentOption = () => {
    const paymentMethods = script.dict_texts.payment_methods;
    const newPaymentMethod: StringRow = {
      id :Date.now(),
      content: "",
      editing: true,
    };
    paymentMethods.push(newPaymentMethod);
    script.is_saved = false; setFluxosData((prev) => [
      script,
      ...prev.filter((f) => f.id !== script.id),
    ]);
  };

  const updatePaymentOption = (updated: StringRow, remove?: boolean) => {
    const paymentMethods = script.dict_texts.payment_methods;
    const index = paymentMethods.findIndex((row) => row.id === updated.id);
    if (remove) {
      paymentMethods.splice(index, 1);
    } else {
      paymentMethods[index] = updated;
    }
    script.is_saved = false; setFluxosData((prev) => [
      script,
      ...prev.filter((f) => f.id !== script.id),
    ]);
  };

  const insertDeliveryInstruction = () => {
    const deliveryInstructions = script.dict_texts.deliveries_instructions;
    const newDeliveryInstruction: StringRow = {
      id : Date.now(),
      content: "",
      editing: true,
    };
    deliveryInstructions.push(newDeliveryInstruction);
    script.is_saved = false; setFluxosData((prev) => [
      script,
      ...prev.filter((f) => f.id !== script.id),
    ]);
  };

  const updateDeliveryInstruction = (updated: StringRow, remove?: boolean) => {
    const deliveryInstructions = script.dict_texts.deliveries_instructions;
    const index = deliveryInstructions.findIndex(
      (row) => row.id === updated.id
    );
    if (remove) {
      deliveryInstructions.splice(index, 1);
    } else {
      deliveryInstructions[index] = updated;
    }
    script.is_saved = false; setFluxosData((prev) => [
      script,
      ...prev.filter((f) => f.id !== script.id),
    ]);
  };

  const updateHasDeliveryHoliday = (hasDeliveryHoliday: boolean) => {
    script.has_delivery_holiday = hasDeliveryHoliday;
    script.is_saved = false; setFluxosData((prev) => [
      script,
      ...prev.filter((f) => f.id !== script.id),
    ]);
  };

  const updatedAcceptedDeliveryDays = (days: number[]) => {
    script.accepted_delivery_days = days;
    script.is_saved = false; setFluxosData((prev) => [
      script,
      ...prev.filter((f) => f.id !== script.id),
    ]);
  };

  const insertCity = () => {
    setOpenCreateCity(true);
  };

  const updateCity = (row: KeyValueRow, remove?: boolean) => {
    const idx = script.dict_texts.cities.findIndex((r) => r.id === row.id);
    if (idx === -1) return;

    if (remove) {
      script.dict_texts.cities.splice(idx, 1);
    } else {
      script.dict_texts.cities[idx] = row;
    }

    script.is_saved = false; setFluxosData((prev) => [
      script,
      ...prev.filter((f) => f.id !== script.id),
    ]);
  };

  const createOffer = () => {
    const offer: ProductOffer = {
      id: Date.now(),
      announcement: "",
      price: "0",
      installments: [],
    };

    script.offers.push(offer);
    script.is_saved = false; setFluxosData((prev) => [
      script,
      ...prev.filter((f) => f.id !== script.id),
    ]);
  };

  const updateOffer = (offer: ProductOffer, remove?: boolean) => {
    const idx = script.offers.findIndex((o) => o.id === offer.id);
    if (idx === -1) return;

    if (remove) {
      script.offers.splice(idx, 1);
    } else {
      script.offers[idx] = offer;
    }

    script.is_saved = false; setFluxosData((prev) => [
      script,
      ...prev.filter((f) => f.id !== script.id),
    ]);
  };

  const insertSupportInstructions = () => {
    const supportInstructions = script.dict_texts.support_instructions;
    const newSupportInstruction: StringRow = {
      id :Date.now(),
      content: "",
      editing: true,
    };
    supportInstructions.push(newSupportInstruction);
    script.is_saved = false; setFluxosData((prev) => [
      script,
      ...prev.filter((f) => f.id !== script.id),
    ]);
  };

  const updateSupportInstructions = (updated: StringRow, remove?: boolean) => {
    const supportInstructions = script.dict_texts.support_instructions;
    const index = supportInstructions.findIndex((row) => row.id === updated.id);
    if (remove) {
      supportInstructions.splice(index, 1);
    } else {
      supportInstructions[index] = updated;
    }
    script.is_saved = false; setFluxosData((prev) => [
      script,
      ...prev.filter((f) => f.id !== script.id),
    ]);
  };

  return (
    <Box
      sx={{
        margin: "auto",
        maxWidth: 500,
        display: "flex",
        flexDirection: "column",
        pb: 10,
        gap: 3,
      }}
    >
      <FormControl sx={{ width: "100%" }}>
        <FormLabel>Nome do produto</FormLabel>
        <Input
          placeholder="Nome do produto"
          value={script.product.name}
          onChange={(e) => updateName(e.target.value)}
        />
      </FormControl>

      <FormControl sx={{ width: "100%" }}>
        <FormLabel>Descrição</FormLabel>
        <Textarea
          minRows={3}
          placeholder="Descrição"
          value={script.product.description}
          onChange={(e) => updateDescription(e.target.value)}
        />
      </FormControl>

      <RowTableForm
        title="Variações de escolha do produto"
        rows={script.product.variations.map((row, idx) => (
          <KeyValueRowRender key={idx} row={row} updateRow={updateAttribute} />
        ))}
        insertButtonTitle="Adicionar customização"
        insert={insertAttribute}
      />

      <Typography my={2} level="h2">
        Perguntas frequentes
      </Typography>

      {/* {script.dict_texts.faqs.map((block, idx) => (
        <FAQCard key={idx} block={block} updateBlock={() => {}} />
      ))}

      <Button onClick={() => {}} fullWidth sx={{ mt: 2 }}>
        adicionar pergunta
      </Button> */}

      <RowTableForm
        title="Perguntas frequentes"
        rows={script.dict_texts.faqs.map((row, idx) => (
          <StringRowRender key={idx} row={row} updateRow={updateFAQ} />
        ))}
        insertButtonTitle="Adicionar pergunta"
        insert={() => insertFAQ()}
      />

      <Modal
        open={openCreateAttribute}
        onClose={() => setOpenCreateAttribute(false)}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <ModalDialog>
          <CreateAttributeForm />
        </ModalDialog>
      </Modal>

      <Typography my={2} level="h2">
        Ofertas
      </Typography>

      {script.offers.map((offer, idx) => {
        return (
          <ProductOfferCard key={idx} offer={offer} updateOffer={updateOffer} />
        );
      })}

      <Button onClick={() => createOffer()} fullWidth sx={{ mt: 2 }}>
        adicionar oferta
      </Button>

      <Typography my={2} level="h2">
        Pagamento
      </Typography>

      <RowTableForm
        title="Formas de pagamento"
        rows={script.dict_texts.payment_methods.map((row, idx) => (
          <StringRowRender
            key={idx}
            row={row}
            updateRow={updatePaymentOption}
          />
        ))}
        insertButtonTitle="Adicionar forma de pagamento"
        insert={insertPaymentOption}
      />

      <Typography my={2} level="h2">
        Entrega
      </Typography>

      <RowTableForm
        title="Instruções de entrega"
        rows={script.dict_texts.deliveries_instructions.map((row, idx) => (
          <StringRowRender
            key={idx}
            row={row}
            updateRow={updateDeliveryInstruction}
          />
        ))}
        insertButtonTitle="Adicionar instrução"
        insert={insertDeliveryInstruction}
      />

      <FormControl sx={{ width: "100%" }}>
        <Checkbox
          label="entrega em feriados"
          checked={script.has_delivery_holiday}
          onChange={(e) => updateHasDeliveryHoliday(e.target.checked)}
        />
      </FormControl>

      <FormControl>
        <FormLabel>Dias de entrega</FormLabel>
        <ToggleButtonGroup
          buttonFlex={1}
          sx={{
            display: { xs: "none", md: "flex" },
          }}
          value={script.accepted_delivery_days.map((day) => String(day))}
          onChange={(event, newValue) =>
            updatedAcceptedDeliveryDays(newValue.map((v) => Number(v)))
          }
        >
          {daysOfWeek.map((day) => (
            <Button key={day.value} value={day.value}>
              {day.label}
            </Button>
          ))}
        </ToggleButtonGroup>
        <ToggleButtonGroup
          buttonFlex={1}
          sx={{
            display: { xs: "flex", md: "none" },
          }}
          orientation="vertical"
          value={script.accepted_delivery_days.map((day) => String(day))}
          onChange={(event, newValue) =>
            updatedAcceptedDeliveryDays(newValue.map((v) => Number(v)))
          }
        >
          {daysOfWeek.map((day) => (
            <Button key={day.value} value={day.value}>
              {day.label}
            </Button>
          ))}
        </ToggleButtonGroup>
      </FormControl>

      <FormControl sx={{ width: "100%" }}>
        <Checkbox
          onChange={(e) => {
            script.dict_texts.cities = [
              {
                id: 0,
                keyRow: "Brasil",
                valueRow: "todas as cidades",
                description: "todas as cidades do brasil",
              },
            ];
            script.is_saved = false; setFluxosData((prev) => [
              ...prev.filter((f) => f.id !== script.id),
              script,
            ]);
            setDeliveryAllBrazil(e.target.checked);
          }}
          label="Entrega em todo o brasil"
        />
      </FormControl>

      {!deliveryAllBrazil && (
        <RowTableForm
          title="Cidades de entrega"
          rows={script.dict_texts.cities.map((row, idx) => (
            <KeyValueRowRender key={idx} row={row} updateRow={updateCity} />
          ))}
          insertButtonTitle="Adicionar cidade"
          insert={insertCity}
        />
      )}

      <RowTableForm
        title="Instruções de suporte"
        rows={script.dict_texts.support_instructions.map((row, idx) => (
          <StringRowRender
            key={idx}
            row={row}
            updateRow={updateSupportInstructions}
          />
        ))}
        insertButtonTitle="Adicionar instrução"
        insert={insertSupportInstructions}
      />

      <Modal
        open={openCreateCity}
        onClose={() => setOpenCreateCity(false)}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <ModalDialog>
          <CreateCity />
        </ModalDialog>
      </Modal>

      <PostFluxoBottom fluxo={script}  />
    </Box>
  );
};
