import * as React from "react";
import Box from "@mui/joy/Box";
import Stack from "@mui/joy/Stack";
import Sheet from "@mui/joy/Sheet";
import Typography from "@mui/joy/Typography";
import { ChatTable, MessageTable } from "../../utils/databaseTypes";
import { formatTimestampFromSeconds } from "../../utils/formatTimestamp";
import MicRoundedIcon from '@mui/icons-material/MicRounded';
import MediaViewer from "./MediaViewer";

export default function ChatBubble({
  message,
  chat,
}: {
  message: MessageTable;
  chat: ChatTable;
}) {
  const isSent = message.is_from_me;
  const [isHovered, setIsHovered] = React.useState<boolean>(false);
  const [isLiked, setIsLiked] = React.useState<boolean>(false);
  const [isCelebrated, setIsCelebrated] = React.useState<boolean>(false);
  return (
    <Box sx={{ maxWidth: "60%", minWidth: "auto" }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        spacing={2}
        sx={{ mb: 0.25 }}
      >
        <Typography level="body-xs">
          {isSent ? "Voçe" : chat.contact_name}
        </Typography>
        <Typography level="body-xs">
          {formatTimestampFromSeconds(message.timestamp)}
        </Typography>
      </Stack>
      {message.has_media &&
        <MediaViewer attachment={message} isSent={isSent} /> }
        <Box
          sx={{ position: "relative" }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          
          <Sheet
            color={isSent ? "primary" : "neutral"}
            variant={isSent ? "solid" : "soft"}
            sx={{
              p: 1.25,
              borderRadius: "lg",
              borderTopRightRadius: isSent ? 0 : "lg",
              borderTopLeftRadius: isSent ? "lg" : 0,
              backgroundColor: isSent
                ? "var(--joy-palette-primary-solidBg)"
                : "background.body",
            }}
          >
            <Typography
            startDecorator={
              message.is_transcribed && <MicRoundedIcon color="primary" />
            }
              level="body-sm"
              sx={{
                color: isSent
                  ? "var(--joy-palette-common-white)"
                  : "var(--joy-palette-text-primary)",
              }}
            >
              {message.content}
            </Typography>
          </Sheet>
          {(isHovered || isLiked || isCelebrated) && (
            <Stack
              direction="row"
              justifyContent={isSent ? "flex-end" : "flex-start"}
              spacing={0.5}
              sx={{
                position: "absolute",
                top: "50%",
                p: 1.5,
                ...(isSent
                  ? {
                    left: 0,
                    transform: "translate(-100%, -50%)",
                  }
                  : {
                      right: 0,
                      transform: "translate(100%, -50%)",
                    }),
                  }}
                  >
            </Stack>
          )}
        </Box>
      
    </Box>
  );
}
