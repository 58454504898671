import {
  Box,
  Chip,
  Stack,
  IconButton,
  FormControl,
  Checkbox,
  Card,
  Button,
  ModalDialog,
  Typography,
} from "@mui/joy";
import { MessageBlock, VariableBlock } from "../../utils/types";
import { useContext, useEffect, useState } from "react";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import MessageCard from "./MessageCard";
import VariableCard from "./VariableCard";
import { DraggableList } from "../../components/draggableList/DraggableList";

import { FluxoData } from "../../utils/fluxoDataTypes";
import { FluxoDataContext } from "../../contexts";
import { CardBlockActions } from "./CardBlockActions";
import { Modal } from "@mui/material";
import { PostFluxoBottom } from "./PostFluxoBottom";

export const ScriptSection = ({ script }: { script: FluxoData }) => {
  const { setFluxosData } = useContext(FluxoDataContext);

  const [openModal, setOpenModal] = useState(false);

  const updateScriptBlocks = (
    blocks: (MessageBlock | VariableBlock)[],
    remove?: boolean
  ) => {
    script.script_blocks = blocks;
    script.is_saved = false; setFluxosData((prev) => [
      script,
      ...prev.filter((f) => f.id !== script.id),
    ]);
  };

  const updateScriptBlock = (
    block: MessageBlock | VariableBlock,
    remove?: boolean
  ) => {
    if (remove) {
      script.script_blocks = script.script_blocks.filter((b) => b.id !== block.id);
    } else {
      script.script_blocks = script.script_blocks.map((b) =>
      b.id === block.id ? block : b
      );
    }

    script.is_saved = false; setFluxosData((prev) => [
      script,
      ...prev.filter((f) => f.id !== script.id),
    ]);
  };

  const updateHasConfirmation = (hasConfirmation: boolean) => {
    script.has_confirmation = hasConfirmation;
    script.is_saved = false; setFluxosData((prev) => [
      script,
      ...prev.filter((f) => f.id !== script.id),
    ]);
  };

  const updateEndSteps = (blocks: MessageBlock[], remove?: boolean) => {
    if (remove) {
      script.script_end_blocks = script.script_end_blocks.filter(
      (b) => !blocks.some((block) => block.id === b.id)
      );
    } else {
      script.script_end_blocks = [
      ...blocks,
      script.script_end_blocks[script.script_end_blocks.length - 1],
      ];
    }
    script.is_saved = false; setFluxosData((prev) => [
      script,
      ...prev.filter((f) => f.id !== script.id),
    ]);
  };

  const updateEndBlock = (block: MessageBlock, remove?: boolean) => {
    if (remove) {
      script.script_end_blocks = script.script_end_blocks.filter(
      (b) => b.id !== block.id
      );
    } else {
      script.script_end_blocks = script.script_end_blocks.map((b) =>
      b.id === block.id ? block : b
      );
    }
    script.is_saved = false; setFluxosData((prev) => [
      script,
      ...prev.filter((f) => f.id !== script.id),
    ]);
  };

  return (
    <Box
      sx={{
        margin: "auto",
        maxWidth: 500,
        display: "flex",
        flexDirection: "column",
        pb: 10,
        gap: 3,
      }}
    >
      <Stack
        spacing={3}
        alignItems={"center"}
        sx={{
          position: "relative", // Add relative positioning to the Stack container
        }}
      >
        <Chip size="lg" sx={{ p: 1 }} variant="solid" color="primary">
          inicio da conversa
        </Chip>

        <DraggableList
          items={script.script_blocks}
          handleUpdatedItems={(updated) => {
            updateScriptBlocks(updated);
          }}
          renderItem={(item: MessageBlock | VariableBlock) => {
            return (
              <Box sx={{ textAlign: "center", p: 1 }}>
                <CardBlockActions block={item} updateItem={updateScriptBlock}>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {item.type === "message" && (
                      <MessageCard
                        block={item}
                        updateItem={updateScriptBlock}
                      />
                    )}
                    {item.type === "variable" && (
                      <VariableCard
                        block={item}
                        updateItem={updateScriptBlock}
                      />
                    )}
                  </Box>
                </CardBlockActions>
              </Box>
            );
          }}
        />

        <BlockAddSelector setOpenModal={setOpenModal} />

        <Card sx={{ zIndex: 5 }} variant="soft">
          <FormControl>
            <Checkbox
              checked={script.has_confirmation}
              onChange={() => updateHasConfirmation(!script.has_confirmation)}
              sx={{ color: "primary" }}
              label="Confirmar informações do cliente"
            />
          </FormControl>
        </Card>

        <DraggableList
          items={script.script_end_blocks.slice(0, -1)}
          handleUpdatedItems={(updated) => {
            updateEndSteps(updated);
          }}
          renderItem={(item: MessageBlock) => {
            return (
              <Box sx={{ textAlign: "center", p: 1 }}>
                <CardBlockActions
                  block={item}
                  updateItem={
                    updateEndBlock as (
                      block: MessageBlock | VariableBlock,
                      remove?: boolean
                    ) => void
                  }
                >
                  {/* // esse gambiarra se resolvaria com herença de interfaces, com o Block sendo a primitiva de Messageblock e VarviableBlock */}
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <MessageCard block={item} updateItem={updateEndBlock} />
                  </Box>
                </CardBlockActions>
              </Box>
            );
          }}
        />

        <MessageCard
          fixed
          endLabel={"Fim do Fluxo"}
          block={script.script_end_blocks[script.script_end_blocks.length - 1]}
          updateItem={updateEndBlock}
        />

        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "2px",
            zIndex: 0,
            height: "calc(100% - 48px)",
            background: "gray",
            content: '""',
          }}
        />
      </Stack>
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <ModalDialog>
          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: 2,
                p: 2,
              }}
            >
              <Typography level={"title-lg"}>Adicionar Bloco</Typography>
              <Button
                fullWidth
                variant="outlined"
                color={"neutral"}
                onClick={() => {
                  setOpenModal(false);
                  updateScriptBlocks([
                    ...script.script_blocks,
                    {
                      type: "message",
                      id: Date.now(),
                      hasMidia: false,
                      editMode: true,
                      content: "nova mensagem",
                    },
                  ]);
                }}
              >
                mensagem
              </Button>
              <Button
                fullWidth
                variant="solid"
                color="neutral"
                onClick={() => {
                  setOpenModal(false);
                  updateScriptBlocks([
                    ...script.script_blocks,
                    {
                      type: "variable",
                      id: Date.now(),
                      varKey: "age",
                      required: true,
                      editMode: true,
                    },
                  ]);
                }}
              >
                info obrigatoria
              </Button>
              <Button
                fullWidth
                variant="soft"
                color="neutral"
                sx={{ border: "1px solid", borderColor: "divider" }}
                onClick={() => {
                  setOpenModal(false);
                  updateScriptBlocks([
                    ...script.script_blocks,
                    {
                      type: "variable",
                      id: Date.now(),
                      varKey: "age",
                      required: true,
                      editMode: true,
                    },
                  ]);
                }}
              >
                info opcional
              </Button>
              <Button
                fullWidth
                variant="outlined"
                color={"primary"}
                onClick={() => {
                  setOpenModal(false);
                  updateEndSteps([
                    {
                      type: "message",
                      id: Date.now(),
                      hasMidia: false,
                      editMode: true,
                      content: "mensagem final",
                    },
                    ...script.script_end_blocks.slice(0, -1),
                  ]);
                }}
              >
                mensagem final
              </Button>
            </Box>
          </Box>
        </ModalDialog>
      </Modal>
      <PostFluxoBottom fluxo={script}  />
    </Box>
  );
};

const BlockAddSelector = ({
  setOpenModal,
}: {
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 0,
      }}
    >
      <IconButton
        variant="solid"
        color="primary"
        sx={{ p: 2, borderRadius: 50, zIndex: 1 }}
        onClick={() => setOpenModal(true)}
      >
        <AddRoundedIcon />
      </IconButton>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          // gap: 4,
        }}
      ></Box>
    </Box>
  );
};
