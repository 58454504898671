import * as React from "react";
import Avatar from "@mui/joy/Avatar";
import Button from "@mui/joy/Button";
import Chip from "@mui/joy/Chip";
import IconButton from "@mui/joy/IconButton";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import CircleIcon from "@mui/icons-material/Circle";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import { toggleMessagesPane } from "../../utils/utils";
import Menu from "@mui/joy/Menu";
import MenuButton from "@mui/joy/MenuButton";
import MenuItem from "@mui/joy/MenuItem";
import Dropdown from "@mui/joy/Dropdown";
import BlockRoundedIcon from "@mui/icons-material/BlockRounded";
import { ChatTable } from "../../utils/databaseTypes";
import { updateChat } from "../../hooks/chats";
import { useContext } from "react";
import { ChatsDataContext } from "../../contexts/ChatsContext";
import {
  Box,
  Divider,
  List,
  ListItemButton,
  Modal,
  ModalDialog,
} from "@mui/joy";
import { FluxoDataContext, WSConnectionContext } from "../../contexts";
import MessageCard from "../fluxosPage/MessageCard";
import { ms } from "date-fns/locale";
import { MessageBlock } from "../../utils/types";

type MessagesPaneHeaderProps = {
  sender: ChatTable | undefined;
  openPane: boolean;
  setOpenPane: (show: boolean) => void;
};

export default function MessagesPaneHeader({
  sender,
  openPane,
  setOpenPane,
}: MessagesPaneHeaderProps) {
  const { handleUpdateChats, chatsData } = useContext(ChatsDataContext);
  const contact = chatsData.find((chat) => chat.id === sender?.id) ?? sender;
  const [openTransmissionModal, setOpenTransmissionModal] =
    React.useState(false);

  const handleBlockIA = (id: number, block: boolean) => {
    updateChat({ is_bot_blocked: block }, [id], handleUpdateChats);
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{
        borderBottom: "1px solid",
        borderColor: "divider",
        backgroundColor: "background.body",
      }}
      py={{ xs: 2, md: sender ? 2 : 0 }}
      px={{ xs: 1, md: 2 }}
    >
      <Stack direction="row" spacing={{ xs: 1, md: 2 }} alignItems="center">
        <IconButton
          variant="plain"
          color="neutral"
          size="sm"
          sx={{
            display: { xs: "inline-flex", sm: "none" },
          }}
          onClick={() => toggleMessagesPane()}
        >
          <ArrowBackIosNewRoundedIcon />
        </IconButton>
        {contact && <Avatar size="lg" src={contact?.avatar_url} />}
        <div>
          <Typography
            fontWeight="lg"
            fontSize="lg"
            component="h2"
            noWrap
            endDecorator={
              contact?.is_bot_blocked ? (
                <Chip
                  variant="outlined"
                  size="sm"
                  color="warning"
                  sx={{
                    borderRadius: "sm",
                  }}
                  startDecorator={
                    <CircleIcon sx={{ fontSize: 8 }} color="warning" />
                  }
                  slotProps={{ root: { component: "span" } }}
                >
                  Chat IA bloqueado
                </Chip>
              ) : undefined
            }
          >
            {contact?.contact_name}
          </Typography>
          <Typography level="body-lg">{contact?.contact_phone}</Typography>
        </div>
      </Stack>
      {contact && (
        <Stack spacing={1} direction="row" alignItems="center">
          <Dropdown>
            <MenuButton size="sm">
              <MoreHorizRoundedIcon />
            </MenuButton>
            <Menu>
              <MenuItem onClick={() => setOpenPane(!openPane)}>
                {openPane ? "Ocultar perfil" : "Ver perfil"}
              </MenuItem>
              <MenuItem onClick={() => setOpenTransmissionModal(true)}>
                Transmitir bloco
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={() =>
                  handleBlockIA(
                    contact.id,
                    contact.is_bot_blocked ? false : true
                  )
                }
              >
                {!contact.is_bot_blocked && <BlockRoundedIcon color="error" />}
                <Typography
                  color={contact.is_bot_blocked ? "success" : "danger"}
                  level="body-sm"
                >
                  {contact.is_bot_blocked ? "Desbloquear IA" : "Bloquear IA"}
                </Typography>
              </MenuItem>
            </Menu>
          </Dropdown>
        </Stack>
      )}
      <Modal
        open={openTransmissionModal}
        onClose={() => setOpenTransmissionModal(false)}
      >
        <ModalDialog>
          {contact && (
            <BlockTransmissionPanel
              setOpen={setOpenTransmissionModal}
              chat={contact}
            />
          )}
        </ModalDialog>
      </Modal>
    </Stack>
  );
}

const BlockTransmissionPanel = ({
  chat,
  setOpen,
}: {
  chat: ChatTable;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { fluxosData, scriptData } = useContext(FluxoDataContext);
  const { sendMessage } = React.useContext(WSConnectionContext);
  const [msgSelected, setMsgSelected] = React.useState<number | null>(null);
  const fluxoId = scriptData.find((s) => s.id === chat.script_id)?.fluxo_id;
  const fluxo = fluxosData.find((f) => f.id === Number(fluxoId));

  return (
    <Box>
      <Typography level="title-lg" component="h2">
        Selecione o bloco
      </Typography>

      { fluxo && <Stack height={500} spacing={4} my={4} overflow={"auto"} width={400}>
        <List>
          {[
            ...fluxo?.script_blocks,
            ...fluxo?.script_end_blocks,
          ]
            .filter((block) => block.type === "message")
            .map((message, idx) => {
              return (
                <ListItemButton
                  variant={msgSelected === message.id ? "solid" : "plain"}
                  sx={{
                    p: 3,
                    borderRadius: "lg",
                    border:
                      msgSelected === message.id
                        ? "2px solid"
                        : "2px dashed transparent",

                    ":hover": {
                      border: "2px dashed",
                    },
                  }}
                  key={idx}
                  onClick={() => {
                    setMsgSelected(message.id);
                  }}
                >
                  <MessageCard block={message} fixed />
                </ListItemButton>
              );
            })}
        </List>
      </Stack>}

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "end",
          gap: 2,
        }}
      >
        <Button
          variant="outlined"
          color="neutral"
          fullWidth
          onClick={() => {
            console.log("cancel");
            setMsgSelected(null);
            setOpen(false);
          }}
        >
          Cancelar
        </Button>
        <Button disabled={!msgSelected} onClick={() => {
          const blockSelected = fluxo?.script_blocks.find(
            (block) => block.id === msgSelected
          ) as MessageBlock;
          if (blockSelected)
          sendMessage({
            block: blockSelected,
            chat: chat,
          });
          setOpen(false);
        }}>
          Enviar
        </Button>
      </Box>
    </Box>
  );
};
