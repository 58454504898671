import { ChatTable, MessageTable } from "../utils/databaseTypes";

export const getChatMessages = (
  setMessages: React.Dispatch<React.SetStateAction<MessageTable[]>>,
  setChatsData: React.Dispatch<React.SetStateAction<ChatTable[]>>
) => {
  fetch(process.env.REACT_APP_SERVER_URL + "/chatMessages", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      const { messages, chats } = data;
      setMessages(messages ? messages : []);
      setChatsData(chats ? chats : []);
    })
    .catch((error) => console.error("error on fetch chat messages", error));
};

export const updateChat = (
  updateParams: Partial<ChatTable>,
  chatIdList: number[],
  handleUpdateChats: (updatedChats: ChatTable[], remove?: boolean) => void
) => {
  console.log("update chat", updateParams, chatIdList);
  fetch(process.env.REACT_APP_SERVER_URL + "/chat", {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify({ updateParams, chatIdList }),
  })
    .then((response) => response.json())
    .then((data) => {
      handleUpdateChats(data.data);
      return data.data;
    })
    .catch((error) => console.error("error on update chat", error));
};

export const deleteChat = async (
  chatIdList: number[]
): Promise<ChatTable[] | undefined> => {
  try {
    const response = await fetch(process.env.REACT_APP_SERVER_URL + "/chat", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({ chatIdList }),
    });
    const data = await response.json();
    return data.data as ChatTable[] | undefined;
  } catch (error) {
    console.error("error on delete chat", error);
    return undefined;
  }
};
