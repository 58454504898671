import * as React from "react";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import {
  Box,
  Chip,
  IconButton,
  Input,
  ListDivider,
  ListItem,
  ListItemButton,
} from "@mui/joy";
import List from "@mui/joy/List";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { toggleMessagesPane } from "../../utils/utils";
import AccountTreeRoundedIcon from "@mui/icons-material/AccountTreeRounded";
import { Button } from "@mui/joy";
import { useEffect } from "react";
import CategoryRoundedIcon from "@mui/icons-material/CategoryRounded";
import { FluxoDataContext, UserDataContext } from "../../contexts";
import { FluxoData } from "../../utils/fluxoDataTypes";
import { fluxoEmpty } from "../../utils/demos/fluxoEmpty";
import FlipToBackIcon from "@mui/icons-material/FlipToBack";
import WidgetsRoundedIcon from '@mui/icons-material/WidgetsRounded';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import DashboardCustomizeRoundedIcon from '@mui/icons-material/DashboardCustomizeRounded';

type ChatsPaneProps = {
  fluxo: FluxoData[];
  setSelectedFluxo: React.Dispatch<React.SetStateAction<FluxoData | undefined>>;
  selectedScriptId: number | undefined;
};

export default function ScriptsListPane(props: ChatsPaneProps) {
  const { fluxo, setSelectedFluxo, selectedScriptId } = props;
  const { setFluxosData } = React.useContext(FluxoDataContext);
  const { userData } = React.useContext(UserDataContext);
  const [fluxoFiltered, setFluxoFiltered] = React.useState<FluxoData[]>([]);
  const [query, setQuery] = React.useState<string>("");

  useEffect(() => {
    setFluxoFiltered(
      fluxo.filter((script) =>
        script.fluxo_name.toLowerCase().includes(query.toLowerCase())
      )
    );
  }, [fluxo, query]);

  return (
    <Box
      sx={{
        borderRight: "1px solid",
        borderColor: "divider",
        height: { sm: "calc(100dvh - var(--Header-height))", md: "100dvh" },
        overflowY: "auto",
      }}
    >
      <Box
        sx={{
          px: { xs: 2, md: 6 },
          mt: 1,
          display: "flex",
          flexWrap: "nowrap",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography
          level="h2"
          component="h1"
          sx={{ mt: 1, mb: 0 }}
          endDecorator={<AccountTreeRoundedIcon />}
        >
          Fluxos
        </Typography>
        <IconButton
          variant="plain"
          aria-label="edit"
          color="neutral"
          size="sm"
          onClick={() => {
            toggleMessagesPane();
          }}
          sx={{ display: { sm: "none" } }}
        >
          <CloseRoundedIcon />
        </IconButton>
      </Box>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="space-between"
        p={2}
        pb={1.5}
      ></Stack>
      <Box sx={{ px: 2, pb: 1.5, display: "flex", gap: 1 }}>
        <Input
          size="sm"
          startDecorator={<SearchRoundedIcon />}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="nome do fluxo"
          aria-label="Search"
        />
        <Button
          size="sm"
          sx={{ width: "50%" }}
          onClick={() => {
            const newfluxo = fluxoEmpty;
            newfluxo.id = Date.now();
            newfluxo.user_id = userData?.id ?? 0;
            newfluxo.fluxo_name = "Novo Fluxo - " + newfluxo.id;
            setFluxosData((prev) => [
              newfluxo,
              ...prev,
            ]);
            setSelectedFluxo(newfluxo);
          }}
        >
          criar
        </Button>
      </Box>
      <List
        sx={{
          py: 0,
          "--ListItem-paddingY": "0.75rem",
          "--ListItem-paddingX": "1rem",
        }}
      >
        {fluxoFiltered.map((script) => (
          <ScriptListItem
            key={script.id}
            fluxo={script}
            setSelectedChatById={(id) =>
              setSelectedFluxo(fluxo.find((script) => script.id === id))
            }
            selectedChatId={selectedScriptId}
          />
        ))}
      </List>
    </Box>
  );
}

const ScriptListItem = (props: {
  fluxo: FluxoData;
  setSelectedChatById: (id: number) => void;
  selectedChatId: number | undefined;
}) => {
  const { fluxo, setSelectedChatById, selectedChatId } = props;

  return (
    <React.Fragment>
      <ListItem>
        <ListItemButton
          onClick={() => {
            toggleMessagesPane();
            setSelectedChatById(fluxo.id);
          }}
          selected={fluxo.id === selectedChatId}
          color={fluxo.is_saved ? "neutral" : "warning"}
          sx={{
            flexDirection: "row",
            alignItems: "center",
            gap: 0,
          }}
        >
          {fluxo.is_saved ? (
            <DashboardRoundedIcon fontSize="large" />
          ) : (
            <WidgetsRoundedIcon fontSize="large" />
          )}

          <Stack direction="column" px={2}>
            <Stack direction="row" spacing={1}>
              <Typography sx={{ wordBreak: "break-word" }} level="title-lg">
                {fluxo.fluxo_name}
              </Typography>
            </Stack>
            <Typography level="body-sm">{fluxo.fluxo_type}</Typography>
          </Stack>
        </ListItemButton>
      </ListItem>
      <ListDivider sx={{ margin: 0 }} />
    </React.Fragment>
  );
};
