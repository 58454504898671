/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import Button from "@mui/joy/Button";
import { Link } from "react-router-dom";
import Typography from "@mui/joy/Typography";
import ArrowForward from "@mui/icons-material/ArrowForward";
import TwoSidedLayout from "./TwoSidedLayout";
import { ModeToggle } from "../../components/ColorSchemeToggle";

export default function HeroLeft01() {
  return (
    <TwoSidedLayout>
      <ModeToggle />
      <Typography color="primary" fontSize="lg" fontWeight="lg">
        Chatbot integrado com IA
      </Typography>
      <Typography
        level="h1"
        fontWeight="xl"
        fontSize="clamp(1.875rem, 1.3636rem + 2.1818vw, 3rem)"
      >
        Leve seu negócio para o próximo nível com nosso IA chatbot
      </Typography>
      <Typography fontSize="lg" textColor="text.secondary" lineHeight="lg">
        <Typography sx={{ color: "text.primary" , fontWeight: 600 }}>
          Rapido
        </Typography>{" "}
        e <Typography sx={{ color: "text.primary" , fontWeight: 600 }}>fácil</Typography> de usar,
        com uma variedade de recursos para impulsionar seu negócio
      </Typography>
      <Link to={"/signup"}>
        <Button size="lg" endDecorator={<ArrowForward />}>
          Começar
        </Button>
      </Link>
      <Typography>
        Já é um membro?{" "}
        <Link to="/entrar">
        <Typography color="primary" fontWeight="lg">
          Entrar
        </Typography>
        </Link>
      </Typography>
      <Typography
        level="body-xs"
        sx={{
          position: "absolute",
          top: "2rem",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        pagina inicial
      </Typography>
    </TwoSidedLayout>
  );
}
