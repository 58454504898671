import * as React from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import Tabs from "@mui/joy/Tabs";
import TabList from "@mui/joy/TabList";
import Tab, { tabClasses } from "@mui/joy/Tab";
import {
  DialogTitle,
  Modal,
  ModalDialog,
  TabPanel,
} from "@mui/joy";
import { WhatsAppQRCode } from "./QRCode";
import SearchIcon from "@mui/icons-material/Search";
import { useContext } from "react";
import { ConnectionsContext} from "../../contexts";
import {
  createWhatsappClient,
} from "../../hooks/connections";
import { WhatsappCard } from "./WhatsappCard";

export function ConnectionsPage() {
  return (
    <Box sx={{ flex: 1, width: "100%" }}>
      <Box sx={{ px: { xs: 2, md: 6 }, mt: 2 }}>
        <Typography level="h2" component="h1" sx={{ mt: 1, mb: 2 }}>
          Conexões
        </Typography>
      </Box>
      <Tabs
        defaultValue={0}
        sx={{
          bgcolor: "transparent",
        }}
      >
        <TabList
          tabFlex={1}
          size="sm"
          sx={{
            pl: { xs: 0, md: 4 },
            justifyContent: "left",
            [`&& .${tabClasses.root}`]: {
              fontWeight: "600",
              flex: "initial",
              color: "text.tertiary",
              [`&.${tabClasses.selected}`]: {
                bgcolor: "transparent",
                color: "text.primary",
                "&::after": {
                  height: "2px",
                  bgcolor: "primary.500",
                },
              },
            },
          }}
        >
          <Tab sx={{ borderRadius: "6px 6px 0 0" }} indicatorInset value={0}>
            Numeros
          </Tab>
          <Tab sx={{ borderRadius: "6px 6px 0 0" }} indicatorInset value={1}>
            Integrações
          </Tab>
        </TabList>
        <TabPanel value={0}>
          <ContactsList></ContactsList>
        </TabPanel>
      </Tabs>
    </Box>
  );
}

const ContactsList = () => {
  const [open, setOpen] = React.useState(false);
  const { whatsappPhones, handleWhatsappConnectionUpdated, QRcodeStatus } =
    useContext(ConnectionsContext);

  React.useEffect(() => {
    if (QRcodeStatus === "CONNECTED") {
      setOpen(false);
    }
    if (QRcodeStatus === "TIMEOUT") {
      setOpen(false);
    }
  }, [QRcodeStatus]);

  const handleCreateWhatsappClient = () => {
    setOpen(true);
    console.log(QRcodeStatus);
    if (QRcodeStatus !== "CONNECTING") createWhatsappClient();
  };

  return (
    <Box
      component="main"
      className="MainContent"
      sx={{
        // mt: 2,
        // p: 2,
        px: { xs: 2, md: 6 },
        flex: 1,
        display: "flex",
        flexDirection: "column",
        minWidth: 0,
        gap: 1,
      }}
    >
      <Box
        className="SearchAndFilters-mobile"
        sx={{
          display: { xs: "block", sm: "none" },
          my: 1,
          gap: 1,
        }}
      >
        <FormControl sx={{ flex: 1 }} size="sm">
          <FormLabel>Procurar Whatsapp</FormLabel>
          <Input
            size="sm"
            placeholder="Search"
            startDecorator={<SearchIcon />}
          />
        </FormControl>
        <FormControl size="sm">
          <Button
            onClick={handleCreateWhatsappClient}
            sx={{
              mt: 2,
            }}
          >
            adicionar whatsapp
          </Button>
        </FormControl>
      </Box>
      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: "sm",
          py: 2,
          display: { xs: "none", sm: "flex" },
          flexWrap: "wrap",
          gap: 2,
          "& > *": {
            minWidth: { xs: "120px", md: "160px" },
          },
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <FormControl sx={{ flex: 1 }} size="sm">
          <FormLabel>Procurar Whatsapp</FormLabel>
          <Input
            size="sm"
            placeholder="Search"
            startDecorator={<SearchIcon />}
          />
        </FormControl>
        <FormControl size="sm">
          <FormLabel>criar</FormLabel>
          <Button onClick={handleCreateWhatsappClient}>
            adicionar whatsapp
          </Button>
        </FormControl>
      </Box>

      <Stack gap={2}>
        {whatsappPhones?.map((phone) => (
          <WhatsappCard
            data={phone}
            setUpdate={handleWhatsappConnectionUpdated}
            setOpenQRcodeModal={setOpen}
            key={phone.id}
          />
        ))}
      </Stack>

      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog sx={{ maxWidth: 400 }}>
          <DialogTitle>Conecte o whatsapp pelo QR code:</DialogTitle>
          <WhatsAppQRCode />
        </ModalDialog>
      </Modal>
    </Box>
  );
};

