import * as React from "react";
import Box from "@mui/joy/Box";
import Sheet from "@mui/joy/Sheet";
import ScriptPaneHeader from "./ScriptPaneHeader";
import {  TabPanel, Tabs, Typography } from "@mui/joy";
import AccountTreeRoundedIcon from "@mui/icons-material/AccountTreeRounded";
import { ScriptSection } from "./SectionScriptLine";
import { ProductInfoSection } from "./SectionProductInfo";
import { RemarketingSection } from "./SectionRemarketing";
import { InstructionsSection } from "./SectionInstructions";
import { FluxoData } from "../../utils/fluxoDataTypes";

export default function ScriptPane({
  fluxo,
}: {
  fluxo: FluxoData | undefined;
}) {

  
  return (
    <Tabs defaultValue={0}>
      <Sheet
        sx={{
          height: { xs: "calc(100dvh - var(--Header-height))", md: "100dvh" },
          display: "flex",
          flexDirection: "column",
          backgroundColor: "background.level1",
          maxWidth: "100vw",
        }}
      >
        <ScriptPaneHeader fluxoData={fluxo} />

        <Box
          sx={{
            display: "flex",
            flex: 1,
            minHeight: 0,
            px: 2,
            py: 3,
            overflowY: "scroll",
          }}
        >
          {fluxo ? (
            <>
              <TabPanel value={0}>
                <InstructionsSection script={fluxo} />
              </TabPanel>
              <TabPanel value={1}>
                <ProductInfoSection script={fluxo} />
              </TabPanel>
              <TabPanel value={2}>
                <ScriptSection script={fluxo} />
              </TabPanel>
              <TabPanel value={3}>
                <RemarketingSection script={fluxo} />
              </TabPanel>

            </>
          ) : (
            <Box sx={{ margin: "auto" }}>
              <Typography sx={{ textAlign: "center", opacity: 0.5 }}>
                Selecione um fluxo
              </Typography>
              <AccountTreeRoundedIcon
                sx={{ fontSize: 300, opacity: 0.3 }}
              ></AccountTreeRoundedIcon>
            </Box>
          )}
        </Box>
      </Sheet>
    </Tabs>
  );
}




