
import { ScriptTable } from "../utils/databaseTypes";
import { FluxoData, JsonFluxoData } from "../utils/fluxoDataTypes";

export const getFluxos = async (): Promise<ScriptTable[] | undefined> => {
  try {
    const response = await fetch(process.env.REACT_APP_SERVER_URL + "/script", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data.data ? data.data : [];
  } catch (error) {
    console.error("Error:", error);
    return undefined;
  }
};


export const getFluxoData = async (uri : string): Promise<FluxoData | undefined> => {
  
  try {
    const response = await fetch(process.env.REACT_APP_SERVER_URL + "/fluxos" + uri, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
    return undefined;
  }
}

export const createFluxo = async (
    fluxo: FluxoData,
    jsonFluxo: JsonFluxoData
): Promise<ScriptTable | undefined> => {
  console.log("fluxo e json",fluxo, jsonFluxo);
  try {
    const response = await fetch(process.env.REACT_APP_SERVER_URL + "/script", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({ fluxo, jsonFluxo }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data.data as ScriptTable;
  } catch (error) {
    console.error("Error:", error);
    return undefined;
  }
};