import { FluxoData, JsonFluxoData } from "./fluxoDataTypes";
import { convertScriptBlocksToFluxoBlocks } from "./convertScriptBlocksToFluxoBlocks";
import {
  deleteFluxoMedia,
  uploadMessageBlocksMedia,
} from "../hooks/fluxoMedia";
import { StringRow } from "../pages/fluxosPage/RowTableForm";

export async function fluxoDataToJson(
  fluxoData: FluxoData
): Promise<JsonFluxoData> {
  const { message_blocks, steps_blocks } = convertScriptBlocksToFluxoBlocks(
    fluxoData.script_blocks,
    fluxoData.has_confirmation,
    fluxoData.script_end_blocks
  );
  fluxoData.message_blocks = message_blocks;
  fluxoData.steps_blocks = steps_blocks;

  for (const block in fluxoData.message_blocks) {
    console.log(block);
    console.log(fluxoData.message_blocks[block]);
    fluxoData.message_blocks[block] = await uploadMessageBlocksMedia(
      fluxoData.message_blocks[block],
      fluxoData.id + block,
      fluxoData.id
    );
  }

  fluxoData.remarketing_messages = await Promise.all(
    fluxoData.remarketing_messages.map(async (remarketing_blocks, idx) => {
      remarketing_blocks.messages = await uploadMessageBlocksMedia(
        remarketing_blocks.messages,
        fluxoData.id + "_REMARKETING_" + idx,
        fluxoData.id
      );
      return remarketing_blocks;
    })
  );

  console.log(fluxoData);

  const json: JsonFluxoData = {
    id: fluxoData.id,
    user_id: fluxoData.user_id,
    nome_fluxo: fluxoData.fluxo_name.trim().replaceAll(" ", "_").toLowerCase(),
    tipo_fluxo: fluxoData.fluxo_type,

    product: {
      name_br: fluxoData.product.name.trim().replaceAll(" ", "_").toLowerCase(),
      name_en: fluxoData.product.name.trim().replaceAll(" ", "_").toLowerCase(),
      short_name: fluxoData.product.name
        .trim()
        .replaceAll(" ", "_")
        .toLowerCase(),
      attributes: fluxoData.product.variations.map((attr) => attr.keyRow),
      attributes_with_description: fluxoData.product.variations.reduce(
        (acc, attr) => {
          acc[attr.keyRow] = attr.description;
          return acc;
        },
        {} as { [key: string]: string }
      ),
      info_necessaria_cliente: fluxoData.script_blocks
        .filter((block) => block.type === "variable")
        .filter((info) => info.required)
        .map((info) => info.varKey),
      info_interesse_cliente: fluxoData.script_blocks
        .filter((block) => block.type === "variable")
        .map((info) => info.varKey),
      limite_remarketing: fluxoData.remarketing_messages.length,
      limite_desconto: fluxoData.remarketing_offers.length,
    },

    ofertas: fluxoData.offers.map(
      (oferta) => oferta.announcement + oferta.price + oferta.installments
    ),

    ofertas_remarketing: fluxoData.remarketing_offers.map(
      (oferta) => oferta.announcement + oferta.price + oferta.installments
    ),

    remarketing_messages: fluxoData.remarketing_messages.reduce(
      (acc, blocks, idx) => {
        acc[`$REMARKETING_MESSAGES_${idx}`] = blocks.messages
          .map((msg) => msg.content)
          .join("\n");
        return acc;
      },
      {} as { [key: string]: string }
    ),

    default_messages: { Error: fluxoData.default_messages.error.content },

    dict_texts: {
      descricao: fluxoData.product.description,
      instrucoes: fluxoData.dict_texts.instructions
        .map((instrucao) => instrucao.content)
        .join("\n"),
      restricoes: fluxoData.dict_texts.restrictions
        .map((restricao) => restricao.content)
        .join("\n"),
      perguntas_frequentes: fluxoData.dict_texts.faqs
        .map((faq) => faq.content)
        .join("\n"),
      cidades: fluxoData.dict_texts.cities
        .map((city) => city.keyRow + " - " + city.valueRow)
        .join("\n"),
      pagamento: fluxoData.dict_texts.payment_methods
        .map((pagamento) => pagamento.content)
        .join("\n"),
      entregas: fluxoData.dict_texts.deliveries_instructions
        .map((entrega) => entrega.content)
        .join("\n"),
      suporte: fluxoData.dict_texts.support_instructions
        .map((suporte) => suporte.content)
        .join("\n"),
      relatos: fluxoData.dict_texts.reports
        .map((relato) => relato.content)
        .join("\n"),
      agendamento: fluxoData.dict_texts.scheduling_instructions
        .map((agendamento) => agendamento.content)
        .join("\n"),
    },

    embeddings_texts: [
      fluxoData.product.description,
      fluxoData.dict_texts.instructions
        .map((instrucao) => instrucao.content)
        .join("\n"),
      fluxoData.dict_texts.restrictions
        .map((restricao) => restricao.content)
        .join("\n"),
      fluxoData.dict_texts.faqs.map((faq) => faq.content).join("\n"),
      fluxoData.dict_texts.support_instructions
        .map((suporte) => suporte.content)
        .join("\n"),
      fluxoData.dict_texts.reports.map((relato) => relato.content).join("\n"),
      fluxoData.dict_texts.scheduling_instructions
        .map((agendamento) => agendamento.content)
        .join("\n"),
      fluxoData.dict_texts.faqs.map((faq: StringRow) => faq.content).join("\n"),
      fluxoData.dict_texts.cities.map((city) => city.keyRow).join("\n"),
      fluxoData.dict_texts.payment_methods
        .map((pagamento) => pagamento.content)
        .join("\n"),
      fluxoData.dict_texts.deliveries_instructions
        .map((entrega) => entrega.content)
        .join("\n"),
    ],

    blocos: fluxoData.steps_blocks.map((bloco) => ({
      type: bloco.type,
      automatic_return: bloco.automatic_return,
      info_interest: bloco.info_interest,
    })),

    arquivos_blocos: Object.entries(fluxoData.message_blocks).reduce(
      (acc, [key, value]) => {
        acc[key] = value.map((msg) => msg.content).join("\n");
        return acc;
      },
      {} as { [key: string]: string }
    ),

    arquivos_midias: {},

    has_confirmation: fluxoData.has_confirmation,
    has_delivery_holiday: fluxoData.has_delivery_holiday,
    accepted_delivery_days: fluxoData.accepted_delivery_days,
  };

  return json;
}
