import {
  CardOverflow,
  Typography,
  Card,
  Box,
  Tooltip,
  Select,
  Option,
} from "@mui/joy";
import { MessageBlock, VariableBlock } from "../../utils/types";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DoneIcon from "@mui/icons-material/Done";

const variableOptions = [
  { value: "city", label: "cidade" },
  { value: "full_name", label: "nome completo" },
  { value: "street_name", label: "nome da rua" },
  { value: "house_number", label: "numero da casa" },
  { value: "neighborhood", label: "bairro" },
  { value: "CEP", label: "CEP" },
  { value: "delivery_date", label: "data de entrega" },
  { value: "landmark", label: "ponto de referencia" },
  { value: "CPF", label: "CPF" },
  { value: "birthday", label: "dia de nascimento" },
  { value: "age", label: "idade" },
];

const VariableCard = ({
  block,
  updateItem,
}: {
  block: VariableBlock;
  updateItem: (block: VariableBlock, remove?: boolean ) => void;
}) => {
  return (
    <Tooltip
      arrow
      placement="right"
      variant="outlined"
      title={
        !block.editMode ? (
          <Box>
            <EditRoundedIcon
              sx={{
                cursor: "pointer",
                opacity: 0.2,
                "&:hover": {
                  opacity: 1,
                },
              }}
              onClick={() => {
                updateItem({
                  ...block,
                  editMode: true,
                });
              }}
            />

            <CloseRoundedIcon
              sx={{
                cursor: "pointer",
                opacity: 0.2,
                "&:hover": {
                  opacity: 1,
                },
              }}
              onClick={() => {
                updateItem(block, true);
              }}
            />
          </Box>
        ) : (
          <Box>
            <DoneIcon
              sx={{
                cursor: "pointer",
                opacity: 0.2,
                "&:hover": {
                  opacity: 1,
                },
              }}
              onClick={() => {
                updateItem({
                  ...block,
                  editMode: false,
                });
              }}
            />
          </Box>
        )
      }
    >
      <Card
        variant={"outlined"}
        sx={{ zIndex: 1, margin: "auto", overflow: "hidden" }}
      >
        {block.editMode ? (
          <CardOverflow
            sx={{
              bgcolor: block.required
                ? "background.level2"
                : "background.level1",
              p: 1,
              textAlign: "center",
            }}
          >
            <Select
              defaultValue={block.varKey}
              onChange={(_e, value) => {
                console.log(value);
                updateItem({
                  ...block,
                  varKey: value as string,
                  editMode: false,
                });
              }}
            >
              {variableOptions.map((option) => (
                <Option key={option.value} value={option.value}>{option.label}</Option>
              ))}
            </Select>
          </CardOverflow>
        ) : (
          <CardOverflow
            sx={{
              bgcolor: block.required
                ? "background.level2"
                : "background.level1",
              p: 1,
              textAlign: "center",
            }}
          >
            <Typography level="title-md" sx={{}}>
              {block.varKey}
            </Typography>
          </CardOverflow>
        )}
      </Card>
    </Tooltip>
  );
};

export default VariableCard;
