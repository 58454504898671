import * as React from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Divider from "@mui/joy/Divider";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import IconButton from "@mui/joy/IconButton";
import BlockIcon from "@mui/icons-material/Block";
import Select from "@mui/joy/Select";
import MoreVertRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import Option from "@mui/joy/Option";
import Typography from "@mui/joy/Typography";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import {
  Card,
  CardOverflow,
  Chip,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dropdown,
  Menu,
  MenuButton,
  MenuItem,
  Modal,
  ModalDialog,
} from "@mui/joy";
import { useContext } from "react";
import { ConnectionsContext, FluxoDataContext } from "../../contexts";
import { WhatsappTable } from "../../utils/databaseTypes";
import {
  deleteWhatsappConnection,
  updateWhatsappConnection,
} from "../../hooks/connections";

export const WhatsappCard = ({
    data,
    setUpdate,
    setOpenQRcodeModal,
  }: {
    data: WhatsappTable;
    setUpdate: (data: { conectionUpdated: WhatsappTable }) => void;
    setOpenQRcodeModal: React.Dispatch<React.SetStateAction<boolean>>;
  }) => {
    const [openDeleteConfirmation, setOpenDeleteConfirmation] =
      React.useState<boolean>(false);
  
    const handleOpenCloseConnection = (comand: string) => {
      updateWhatsappConnection(data.id, comand, {}, setUpdate);
      if (comand === "OPEN") {
        setOpenQRcodeModal(true);
      }
    };
  
    const handleDeleteConnection = () => {
      setOpenDeleteConfirmation(true);
    };
  
    const { scriptData } = useContext(FluxoDataContext);
    const { handleWhatsappConnectionUpdated } = useContext(ConnectionsContext);
  
    return (
      <Card
        sx={{
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "space-between",
          alignItems: "center",
  
          m: 0,
        }}
      >
        <Box>
          <Typography level="h4">{data.name}</Typography>
          <Typography level="body-md" color="neutral">
            {data.phone}
          </Typography>
        </Box>
  
  <Box sx={{display: "flex", gap: 1}}>
        <Dropdown>
          <MenuButton
            slots={{ root: IconButton }}
            slotProps={{
              root: { variant: "outlined", color: "neutral", size: "sm" },
            }}
          >
            <MoreVertRoundedIcon />
          </MenuButton>
          <Menu sx={{ minWidth: 140 }}>
  
            <MenuItem
              disabled={data.status === "LOADING"}
              onClick={() =>
                handleOpenCloseConnection(
                  data.status === "CONNECTED" ? "CLOSE" : "OPEN"
                )
              }
              color={data.status === "CONNECTED" ? "warning" : "success"}
            >
              {data.status === "CONNECTED" ? "Desconectar" : "Conectar"}
            </MenuItem>
            <Divider />
            {/* <MenuItem onClick={() => handleDeleteConnection()} color="danger">
              Deletar
            </MenuItem> */}
          </Menu>
        </Dropdown>
  
        <Select
          defaultValue={data.script_default_id}
          onChange={(e, value) => {
            console.log(value);
            updateWhatsappConnection(
              data.id,
              "EDIT",
              { ...data, script_default_id: value ?? -1 },
              handleWhatsappConnectionUpdated
            );
          }}
        >
          {scriptData.map((script) => (
            <Option key={script.id} value={String(script.id)}>
              {script.name}
            </Option>
          ))}

        </Select>
        </Box>  
  
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box
            sx={{
              width: { xs: "auto", sm: 200 },
              // px: 1,
              // mx: 1,
              alignItems: "center",
            }}
          >
            <Chip
              variant="soft"
              size="lg"
              sx={{ height: 50 }}
              startDecorator={
                data.status === "CONNECTED" ? <CheckRoundedIcon /> : <BlockIcon />
              }
              color={data.status === "CONNECTED" ? "success" : "danger"}
            >
              {data.status}
            </Chip>
          </Box>
  
          <CardOverflow
            // variant="soft"
            sx={{
              // bgcolor:"primary.500",
              display: { xs: "none", sm: "flex" },
              px: 0.2,
              writingMode: "vertical-rl",
              justifyContent: "center",
              alignItems: "center",
              p: 1,
              fontSize: "xs",
              fontWeight: "xl",
              letterSpacing: "1px",
              textTransform: "uppercase",
              borderLeft: "1px solid",
              borderColor: "divider",
            }}
          >
            whatsapp
          </CardOverflow>
        </Box>
  
        <Modal
          open={openDeleteConfirmation}
          onClose={() => setOpenDeleteConfirmation(false)}
        >
          <ModalDialog variant="outlined" role="alertdialog">
            <DialogTitle color="danger">
              <WarningRoundedIcon />
              Confirmação de exclusão
            </DialogTitle>
            <Divider />
            <DialogContent>
              <Typography>
                Você tem certeza que deseja excluir o whatsapp{" "}
                <strong>{data.name}</strong>{" "}
                <Typography color="danger" fontWeight={800}>
                  permanente
                </Typography>
                ?
              </Typography>
              <Typography>
                todo o histórico de{" "}
                <Typography color="warning">mensagens</Typography> e{" "}
                <Typography color="warning">contatos</Typography> será perdido.
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                variant="solid"
                color="danger"
                onClick={() => {
                  setOpenDeleteConfirmation(false);
                  deleteWhatsappConnection(data.id, setUpdate);
                }}
              >
                Excluir para sempre
              </Button>
              <Button
                variant="outlined"
                color="neutral"
                onClick={() => setOpenDeleteConfirmation(false)}
              >
                cancelar
              </Button>
            </DialogActions>
          </ModalDialog>
        </Modal>
      </Card>
    );
  };
  