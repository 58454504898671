import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";

 interface DraggableListProps {
  items: any[];
  handleUpdatedItems: (items: any[]) => void;
  renderItem: (item: any) => JSX.Element;
}

export const DraggableList = ({items, handleUpdatedItems, renderItem} : DraggableListProps) => {

    const Item = ({content, index} : {content: any, index: number}) => {
      return (
        <Draggable draggableId={String(content.id)} index={index}>
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              {renderItem(content)}
            </div>
          )}
        </Draggable>
      )
    }

    const reorder = <T,>(list: T[], startIndex: number, endIndex: number) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      return result;
    }
  

    const ondragEnd = (result: any) => {
      if (!result.destination) {
        return;
      }

      const itemsOrd = reorder(
        items,
        result.source.index,
        result.destination.index
      );
      
      handleUpdatedItems(itemsOrd);
  
    }
  
    return (
          <DragDropContext onDragEnd={ondragEnd}>
            <Droppable droppableId="draggableArea" type="list" direction="vertical">
            {(provided) => (
          <article ref={provided.innerRef} {...provided.droppableProps}>
    
              {items.map((content, index) => (
                <Item key={content.id} content={content} index={index} />
              ))}
              {provided.placeholder}
          </article>
            )}
            </Droppable>
          </DragDropContext>
    )
  }


