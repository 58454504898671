import * as React from "react";
import Box from "@mui/joy/Box";
import Sheet from "@mui/joy/Sheet";
import Stack from "@mui/joy/Stack";
import AvatarWithStatus from "./AvatarWithStatus";
import ChatBubble from "./ChatBubble";
import MessageInput from "./MessageInput";
import MessagesPaneHeader from "./MessagesPaneHeader";
import { WSConnectionContext } from "../../contexts/WSConection";
import { ChatTable, MessageTable } from "../../utils/databaseTypes";
import ForumRoundedIcon from '@mui/icons-material/ForumRounded';
import Typografy from "@mui/joy/Typography";
import { useContext, useEffect } from "react";
import { ChatsDataContext } from "../../contexts";
import { updateChat } from "../../hooks/chats";
import { MessagePrototype } from "../../utils/types";

type MessagesPaneProps = {
  chat: ChatTable | undefined;
  messages: MessageTable[] | undefined;
  openProfilePane: boolean;
  setOpenProfilePane: (show: boolean) => void;
};

export default function MessagesPane(props: MessagesPaneProps) {
  const { chat, messages, openProfilePane, setOpenProfilePane } = props;
  const { sendMessage } = React.useContext(WSConnectionContext);
  const { handleUpdateChats } = useContext(ChatsDataContext);

  useEffect(()=>{
    if ( chat ) updateChat({user_seen: true}, [chat.id], handleUpdateChats);
  }, [messages])

  return (
    <Sheet
      sx={{
        height: { xs: 'calc(100dvh - var(--Header-height))', md: '100dvh' },
        display: "flex",
        flexDirection: "column",
        backgroundColor: "background.level1",
        maxWidth: '100vw',
      }}
    >
      <MessagesPaneHeader sender={chat} openPane={openProfilePane} setOpenPane={setOpenProfilePane} />
      <Box
        sx={{
          display: "flex",
          flex: 1,
          minHeight: 0,
          px: 2,
          py: 3,
          overflowY: "scroll",
          flexDirection: "column-reverse",
        }}
      >
        <Stack spacing={2} justifyContent="flex-end">
          {chat && messages?.sort((a, b) => a.timestamp - b.timestamp)
          .map((message: MessageTable, index: number) => {
            const isYou = message.is_from_me;
            return (
              <Stack
                key={index}
                direction="row"
                overflow={"hidden"}
                spacing={2}
                flexDirection={isYou ? "row-reverse" : "row"}
              >
                <ChatBubble
                  message={message}
                  chat={chat}
                />
              </Stack>
            );
          })}
        </Stack>
        { !chat &&
        <Box sx={{margin: 'auto',}}>
          <Typografy sx={{textAlign: 'center', opacity: 0.5 }}>
            Selecione um chat
          </Typografy>
        <ForumRoundedIcon sx={{ fontSize: 300, opacity: 0.3 }} >
        </ForumRoundedIcon>
          </Box>
        }
      </Box>
      { chat && <MessageInput
        onSubmit={(text:string) => {

          sendMessage({
            message: {
              is_from_me: true,
              type: "chat",
              content: text,
              timestamp: Date.now(),
              is_transcribed: false,
              media_type: undefined,
              media_url: undefined,
              media_filename: undefined,
              has_media: false,
              contact_name: chat.contact_name,
              contact_phone: chat.contact_phone,
            },
            chat: chat,
          });
        }}
        />
      }
    </Sheet>
  );
}
