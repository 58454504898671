import { Box } from "@mui/material";
import GridChartPane from "./GridChartPane";
import { OverviewCard } from "./overviewCard";

import { useContext, useEffect, useState } from "react";
import BasicBars from "./BarsChartPane";
import { Typography } from "@mui/joy";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import { ChatsDataContext } from "../../contexts/ChatsContext";
import { ChatTable, MessageTable } from "../../utils/databaseTypes";
export const periods = new Map<string, number>();
periods.set("DIARIA", 86400);
periods.set("SEMANAL", 604800);
periods.set("MENSAL", 2592000);
periods.set("ANUAL", 31536000);

export const DashboardPage: React.FC = () => {
  const [timePeriod, setTimePeriod] = useState<string>("DIARIA");
  const { chatsData, messagesData } = useContext(ChatsDataContext);
  const [chatsOnPeriod, setChatsOnPeriod] = useState<ChatTable[]>([]);
  const [messagesOnPeriod, setMessagesOnPeriod] = useState<MessageTable[]>([]);

  useEffect(() => {
    const now = new Date().getTime() / 1000;
    const period = periods.get(timePeriod) ?? 0;

    const filteredChats = chatsData.filter(
      (chat: ChatTable) => chat.initial_date > now - period
    );
    const filteredMessages = messagesData.filter(
      (message: MessageTable) => message.timestamp > now - period
    );

    setChatsOnPeriod(filteredChats);
    setMessagesOnPeriod(filteredMessages);
  }, [timePeriod, chatsData, messagesData]);

  // console.log(chatsOnPeriod, messagesOnPeriod);
  // console.log(chatsData, messagesData);

  return (
    <Box sx={{ flex: 1, width: "100%" }}>
      <Box sx={{ px: { xs: 2, md: 6 }, mt: 2 }}>
        <Typography
          level="h2"
          component="h1"
          sx={{ mt: 1, mb: 2 }}
          endDecorator={<QueryStatsIcon />}
        >
          Dashboard
        </Typography>
      </Box>
      <Box
        sx={{
          flex: 1,
          minWidth: 0,
          gap: 1,
          mx: "auto",
          maxWidth: "lg",
          p: 2,
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "grid",
            gridTemplateColumns: { sm: "repeat(5, 1fr)", xs: "repeat(2, 1fr)" },
            gridTemplateRows: { sm: "repeat(2, 1fr)", xs: "repeat(4, 1fr)" },
            gridColumnGap: "2rem",
            gridRowGap: "2rem",
          }}
        >
          <Box
            flex={1}
            sx={{
              gridArea: { sm: "1 / 1 / 3 / 4", xs: "3 / 1 / 5 / 3" },
            }}
          >
            <GridChartPane
              period={timePeriod}
              setPeriod={setTimePeriod}
              chatsData={chatsOnPeriod}
              messagesData={messagesOnPeriod}
            />
          </Box>

          <Box
            sx={{
              gridArea: { sm: "1 / 4 / 3 / 6", xs: "1 / 1 / 3 / 3" },
            }}
          >
            <OverviewCard
              setPeriod={setTimePeriod}
              period={timePeriod}
              totalChatsData={chatsData}
              totalMessagesData={messagesData}
            />
          </Box>
        </Box>
        <BasicBars
          period={timePeriod}
          setPeriod={setTimePeriod}
          chatsData={chatsOnPeriod}
          messagesData={messagesOnPeriod}
        />
      </Box>
    </Box>
  );
};
