import { createContext, useEffect, useState } from "react";
import {UserData} from "../utils/types";
import { getUserData } from "../hooks/user";
import { useNavigate } from "react-router-dom";

interface UserContextData {
  userData: UserData | undefined;
  setUserData: React.Dispatch<React.SetStateAction<UserData | undefined>>;
}

export const UserDataContext = createContext<UserContextData>(
  {} as UserContextData
);

interface UserProviderProps {
  children: React.ReactNode;
}

export const UserDataProvider: React.FC<UserProviderProps> = ({ children }) => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState<UserData>();

  useEffect(() => {
    async function checkUser() {
      const userData = await getUserData();
    if ( userData ) setUserData( userData );
    else navigate("/entrar");
    }
    checkUser();
  }, []);

  return (
    <UserDataContext.Provider
      value={{ userData, setUserData }}
    >
      {children}
    </UserDataContext.Provider>
  );
};
