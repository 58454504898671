import { useContext, useEffect } from "react";
import {
  Autocomplete,
  AutocompleteOption,
  Avatar,
  Box,
  Dropdown,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  Modal,
  ModalDialog,
  Stack,
  Typography,
} from "@mui/joy";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import EditNoteRoundedIcon from "@mui/icons-material/EditNoteRounded";
import LocalPhoneRoundedIcon from "@mui/icons-material/LocalPhoneRounded";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import * as React from "react";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import { Divider, Input } from "@mui/material";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import AddReactionRoundedIcon from "@mui/icons-material/AddReactionRounded";
import LocalGroceryStoreRoundedIcon from "@mui/icons-material/LocalGroceryStoreRounded";
import SmartToyRoundedIcon from "@mui/icons-material/SmartToyRounded";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import BlockRoundedIcon from "@mui/icons-material/BlockRounded";
import { ChatTable, TagTable } from "../../utils/databaseTypes";
import { ChatsDataContext, FluxoDataContext } from "../../contexts";
import { updateChat } from "../../hooks/chats";
import { EditContactModal } from "../savedPage/ContactsTable";
import { ChatStatus, statusLabels } from "../../utils/types";

export const ProfilePane = ({
  user,
  setOpenPane,
  openPane,
}: {
  user: ChatTable;
  setOpenPane: React.Dispatch<React.SetStateAction<boolean>>;
  openPane: boolean;
}) => {
  const { scriptData } = useContext(FluxoDataContext);
  const { handleUpdateChats, chatsData } = useContext(ChatsDataContext);
  const contact = chatsData.find((chat) => chat.id === user?.id) ?? user;
  const [editMode, setEditMode] = React.useState(false);
  const [name, setName] = React.useState(contact.contact_name);


  const handleBlockIA = (id: number, block: boolean) => {
    updateChat({ is_bot_blocked: block }, [id], handleUpdateChats);
  };

  const [openEditModal, setOpenEditModal] = React.useState(false);

  useEffect(() => {
    setName(contact.contact_name);
  }, [contact]);

  return (
    <Box
      sx={{
        borderLeft: "1px solid",
        borderColor: "divider",
        zIndex: 100,
        right: 0,
        maxWidth: 400,
        minWidth: 300,
        height: "100%",
      }}
    >
      <Stack direction="row" spacing={1} alignItems="center" p={2} pb={1.5}>
        <IconButton
          variant="plain"
          aria-label="edit"
          color="neutral"
          size="sm"
          onClick={() => setOpenPane(!openPane)}
        >
          <CloseRoundedIcon />
        </IconButton>
        {editMode ? (
          <Input value={name} onChange={(e) => setName(e.target.value)} />
        ) : (
          <Typography
            fontSize={25}
            fontWeight="lg"
            maxWidth={500}
            overflow={"hidden"}
          >
            {name}
          </Typography>
        )}

        {editMode ? (
          <IconButton onClick={() => setEditMode(false)}>
            <CheckRoundedIcon />
          </IconButton>
        ) : (
          <IconButton
            variant="plain"
            aria-label="edit"
            color="neutral"
            size="sm"
            sx={{ display: { xs: "none", sm: "unset" } }}
            onClick={() => setEditMode(!editMode)}
          >
            <EditNoteRoundedIcon />
          </IconButton>
        )}
      </Stack>
      <Box
        sx={{
          p: 2,
          m: 1,
          mx: "auto",
          width: 300,
          borderRadius: 10,
          backgroundColor: "background.level2",
          display: "flex",
        }}
      >
        <Avatar
          sx={{ m: 0, width: 100, height: 100 }}
          src={contact.avatar_url}
        />
        <Box
          sx={{
            display: "flex",
            // alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: 1,
            p: 1,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <LocalPhoneRoundedIcon />
            <Typography sx={{ fontSize: 18 }}>
              {contact.contact_phone.split("@")[0]}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <AccessTimeRoundedIcon />
            <Typography>{new Date().toLocaleDateString()}</Typography>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          mt: 2,
          width: 300,
          mx: "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 1,
          borderRadius: 10,
          mb: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <LocalGroceryStoreRoundedIcon />
          <Typography level="title-md" component="h2">
            Fluxo
          </Typography>
          <Typography level="body-md" component="p">
            {scriptData.find((s) => s.id === contact.script_id)?.name}
          </Typography>
        </Box>
        <IconButton
          onClick={() => {
            setOpenEditModal(true);
          }}
          size="md"
          variant="soft"
        >
          <ArrowDropDownRoundedIcon />
        </IconButton>
      </Box>

      <Box
        sx={{
          mt: 2,
          width: 300,
          mx: "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 1,
          borderRadius: 10,
          mb: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <LocalGroceryStoreRoundedIcon />
          <Typography level="title-md" component="h2">
            Bloco do fluxo
          </Typography>
          <Typography level="body-md" component="p">
            {contact.script_block}
          </Typography>
        </Box>
        <IconButton
          onClick={() => {
            setOpenEditModal(true);
          }}
          size="md"
          variant="soft"
        >
          <ArrowDropDownRoundedIcon />
        </IconButton>
      </Box>

      <Box
        sx={{
          mx: "auto",
          width: 300,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 1,
          borderRadius: 10,
          mb: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <AddReactionRoundedIcon />
          <Typography level="title-md" component="h2">
            Status
          </Typography>
          <Typography level="body-md" component="p">
            {statusLabels[contact.status as ChatStatus] || " "}
          </Typography>
        </Box>
        <IconButton
          onClick={() => {
            setOpenEditModal(true);
          }}
          size="md"
          variant="soft"
        >
          <ArrowDropDownRoundedIcon />
        </IconButton>
      </Box>

      <Box
        sx={{
          mx: "auto",
          width: 300,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 1,
          borderRadius: 10,
          mb: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <SmartToyRoundedIcon />
          <Typography level="title-md" component="h2">
            Chat IA
          </Typography>
          <Typography
            level="body-md"
            color={contact.is_bot_blocked ? "danger" : "success"}
            component="p"
          >
            {contact.is_bot_blocked ? "Bloqueado" : "Ativo"}
          </Typography>
        </Box>
        <IconButton
          onClick={() => {
            setOpenEditModal(true);
          }}
          size="md"
          variant="soft"
        >
          <ArrowDropDownRoundedIcon />
        </IconButton>
      </Box>
      <EditContactModal
        open={openEditModal}
        onClose={() => setOpenEditModal(false)}
        chat={contact}
      />
      
      <TagsPane chat={contact} />
    </Box>
  );
};

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

export default function TagsPane({ chat }: { chat: ChatTable }) {
  const { tagsData, handleUpdateChats } = useContext(ChatsDataContext);
  const [chipData, setChipData] = React.useState<TagTable[]>(tagsData);
  useEffect(() => {
    setChipData(tagsData.filter((tag) => chat.tags_id?.includes(tag.id)));
  }, [tagsData, chat]);

  const handleDelete = (chipToDelete: TagTable) => () => {
    // setChipData((chips) =>
    //   chips.filter((chip, idx) => idx !== chips.indexOf(chipToDelete))
    // );
    const newTags = chipData.filter((tag) => tag.id !== chipToDelete.id);
    updateChat(
      { tags_id: newTags.map((t) => t.id) },
      [chat.id],
      handleUpdateChats
    );
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          p: 2,
        }}
      >
        <Typography sx={{ width: "100%" }} level="title-lg" component="h2">
          Etiquetas
        </Typography>

        <SearchRoundedIcon sx={{ width: 30, height: 30, mx: 1 }} />
        <Autocomplete
          sx={{}}
          options={tagsData}
          getOptionLabel={(option) => option.name}
          onChange={(e, value) => {
            if (value) {
              // setChipData((chips) => [...chips, value]);
              const newTags = [...chipData, value];
              updateChat(
                { tags_id: newTags.map((t) => t.id) },
                [chat.id],
                handleUpdateChats
              );
            }
          }}
          renderOption={(props, option) => (
            <AutocompleteOption {...props} key={props.id}>
              <Chip
                disabled={chipData.some((chip) => chip.id === option.id)}
                label={option.icon + " " + option.name}
              />
            </AutocompleteOption>
          )}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          listStyle: "none",
          p: 0.5,
          m: 0,
          // height:  300,
          overflow: "auto",
          pb: 20,
        }}
        component="ul"
      >
        <Divider />
        {chipData.map((data, idx) => {
          return (
            <ListItem key={idx}>
              <Chip
                label={data.icon + " " + data.name}
                onDelete={handleDelete(data)}
              />
            </ListItem>
          );
        })}
      </Box>
    </Box>
  );
}

