import * as React from "react";
import Badge from "@mui/joy/Badge";
import Avatar, { AvatarProps } from "@mui/joy/Avatar";
import { ChatTable } from "../../utils/databaseTypes";

type AvatarWithStatusProps = AvatarProps & {
  chat: ChatTable;
};

export default function AvatarWithStatus({ chat }: AvatarWithStatusProps) {

  const signColor = !chat.user_seen ? "primary" : "neutral";

  return (
    <div>
      <Badge
        color={signColor}
        variant={"solid"}
        size="sm"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        badgeInset="4px 4px"
      >
        <Avatar size="sm" src={chat.avatar_url} />
      </Badge>
    </div>
  );
}
