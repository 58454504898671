import React, { useContext } from "react";
// import { useConfigData } from "../../hooks/useConfigData";
import QRCode from "qrcode.react";
import { ConnectionsContext } from "../../contexts";
import { Box, CircularProgress } from "@mui/joy";

interface QRCodeProps {
  // Define the props for your component here
}

export const WhatsAppQRCode: React.FC<QRCodeProps> = () => {
  // const { whatsappStatus } = useConfigData();
  const { qrCodeConection } = useContext(ConnectionsContext);
  const qrCodeSize = 256;

  return (
    <div>
      {qrCodeConection === "" ? (
       <Box sx={{margin: 'auto'}}> <CircularProgress  /> </Box>
      ) : (
        <div
          style={{
            margin: "auto",
            marginTop: 20,
            padding: "20px",
            width: `${qrCodeSize + 40}px`,
            backgroundColor: "white",
          }}
        >
          <QRCode value={qrCodeConection} size={qrCodeSize} />
        </div>
      )}
    </div>
  );
};
