import { TagTable } from "../utils/databaseTypes";

export const getTags = async (
  setTagData: React.Dispatch<React.SetStateAction<TagTable[]>>
): Promise<TagTable[] | undefined> => {
  try {
    const response = await fetch(process.env.REACT_APP_SERVER_URL + "/tag", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    setTagData(data.data ? data.data : []);
  } catch (error) {
    console.error("Error:", error);
    return undefined;
  }
};

export const createTag = async (
  name: string,
  icon: string,
  description: string
): Promise<TagTable | undefined> => {
  try {
    const response = await fetch(process.env.REACT_APP_SERVER_URL + "/tag", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({ name, icon, description }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data.data as TagTable;
  } catch (error) {
    console.error("Error:", error);
    return undefined;
  }
};

export const updateTag = async (
  updateParams: Partial<TagTable>,
  id: number
): Promise<TagTable | undefined> => {
  try {
    const response = await fetch(process.env.REACT_APP_SERVER_URL + `/tag`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({ updateParams, id }),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data.data as TagTable;
  } catch (error) {
    console.error("Error:", error);
    return undefined;
  }
};

export const deleteTag = async (id: number): Promise<TagTable | undefined> => {
  try {
    const response = await fetch(process.env.REACT_APP_SERVER_URL + `/tag`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({ id }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data.data as TagTable;
  } catch (error) {
    console.error("Error:", error);
    return undefined;
  }
};
