/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import Avatar from "@mui/joy/Avatar";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Chip from "@mui/joy/Chip";
import Divider from "@mui/joy/Divider";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import Table from "@mui/joy/Table";
import Sheet from "@mui/joy/Sheet";
import Checkbox from "@mui/joy/Checkbox";
import Typography from "@mui/joy/Typography";
import Menu from "@mui/joy/Menu";
import MenuButton from "@mui/joy/MenuButton";
import MenuItem from "@mui/joy/MenuItem";
import EmojiPicker from "emoji-picker-react";
import Dropdown from "@mui/joy/Dropdown";
import SearchIcon from "@mui/icons-material/Search";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import { DialogContent, DialogTitle, IconButton, Stack } from "@mui/joy";
import { ChatsDataContext } from "../../contexts/ChatsContext";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemContent from "@mui/joy/ListItemContent";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import ListDivider from "@mui/joy/ListDivider";
import { useContext } from "react";
import { createTag, deleteTag, updateTag } from "../../hooks/tags";
import { TagTable } from "../../utils/databaseTypes";


export default function TagsTable() {
  const { tagsData, handleUpdateTags } =
    useContext(ChatsDataContext);
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [search, setSearch] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const rows = tagsData.filter((tab) => tab.name.includes(search));
  const [newTagName, setNewTagName] = React.useState("");
  const [newTagDescription, setNewTagDescription] = React.useState("");
  const [newTagIcon, setNewTagIcon] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [editModalOpen, setEditModalOpen] = React.useState(false);
  const [tagEditing, setTagEditing] = React.useState<TagTable | undefined>(tagsData[0]);
  const [tagEditingIcon, setTagEditingIcon] = React.useState(tagEditing?.icon);
  const [tagEditingName, setTagEditingName] = React.useState(tagEditing?.name);
  const [tagEditingDescription, setTagEditingDescription] = React.useState(tagEditing?.description);

  function RowMenu({
    row,
  }: {
    row: TagTable;
  }) {
    return (
      <Dropdown>
        <MenuButton
          slots={{ root: IconButton }}
          slotProps={{ root: { variant: "outlined", color: "neutral", size: "sm" } }}
        >
          <MoreHorizRoundedIcon />
        </MenuButton>
        <Menu size="sm" sx={{ minWidth: 140 }}>
          <MenuItem
            onClick={() => {
              setEditModalOpen(true);
              setTagEditing(row);
            }}
          >
            Edit
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={async () => {
              const deleted = await deleteTag(row.id);
              console.log(deleted);
              if (deleted) handleUpdateTags([deleted], true);
            }}
            color="danger"
          >
            Delete
          </MenuItem>
        </Menu>
      </Dropdown>
    );
  }

  return (
    <React.Fragment>
      <Sheet
        className="SearchAndFilters-mobile"
        sx={{
          display: { xs: "block", sm: "none" },
          my: 1,
          gap: 1,
        }}
      >
        <Button sx={{ width: "100%", mb: 1 }}>Adicionar etiqueta</Button>
        <Input
          size="sm"
          placeholder="Search"
          startDecorator={<SearchIcon />}
          sx={{ flexGrow: 1 }}
        />
      </Sheet>
      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: "sm",
          py: 2,
          display: { xs: "none", sm: "flex" },
          flexWrap: "wrap",
          gap: 2,
          "& > *": {
            minWidth: { xs: "120px", md: "160px" },
          },
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <FormControl sx={{ flex: 1 }} size="sm">
          <FormLabel>Procurar etiqueta</FormLabel>
          <Input
            size="sm"
            placeholder="Search"
            startDecorator={<SearchIcon />}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </FormControl>
        <FormControl size="sm">
          <FormLabel>criar</FormLabel>
          <Button
            // startDecorator={<Add />}
            onClick={() => setOpen(true)}
          >
            adicionar etiqueta
          </Button>
        </FormControl>
      </Box>

      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog>
          <DialogTitle>Crie uma nova Etiqueta</DialogTitle>
          <DialogContent
            sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
          >
            <EmojiPicker
              onEmojiClick={(e) => {
                setNewTagIcon(e.emoji);
              }}
              height={350}
            />
            <form
              onSubmit={async (event: React.FormEvent<HTMLFormElement>) => {
                event.preventDefault();
                setLoading(true);
                const result = await createTag(
                  newTagName,
                  newTagIcon,
                  newTagDescription
                );
                if (result) {
                  handleUpdateTags([result]);
                  setOpen(false);
                } else alert("Erro ao criar etiqueta");
                setLoading(false);
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Stack spacing={2}>
                  <FormControl>
                    <FormLabel>Name</FormLabel>
                    <Input
                      autoFocus
                      required
                      value={newTagName}
                      onChange={(e) => {
                        if (e.target.value.length <= 20)
                          setNewTagName(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Description</FormLabel>
                    <Input
                      required
                      value={newTagDescription}
                      onChange={(e) => {
                        if (e.target.value.length <= 300)
                          setNewTagDescription(e.target.value);
                      }}
                    />
                  </FormControl>
                </Stack>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                  <Chip
                    sx={{ m: "auto", p: 1, gap: 1 }}
                    size="lg"
                    variant="outlined"
                    startDecorator={newTagIcon}
                  >
                    {newTagName}
                  </Chip>
                  <Button loading={loading} type="submit">
                    Criar
                  </Button>
                </Box>
              </Box>
            </form>
          </DialogContent>
        </ModalDialog>
      </Modal>

      <Modal open={editModalOpen} onClose={() => setEditModalOpen(false)}>
        <ModalDialog>
          <DialogTitle>Editar a Etiqueta</DialogTitle>
          <DialogContent
            sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
          >
            <EmojiPicker
              onEmojiClick={(e) => {
                setTagEditingIcon(e.emoji);
              }}
              height={350}
            />
            <form
              onSubmit={async (event: React.FormEvent<HTMLFormElement>) => {
                event.preventDefault();
                setLoading(true);
                if (!tagEditing) return;
                const result = await updateTag(
                  {
                    name: tagEditingName,
                    icon: tagEditingIcon,
                    description: tagEditingDescription,
                  }, tagEditing?.id
                )
                if (result) {
                  handleUpdateTags([result]);
                  setEditModalOpen(false);
                } else alert("Erro ao criar etiqueta");
                setLoading(false);
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Stack spacing={2}>
                  <FormControl>
                    <FormLabel>{}</FormLabel>
                    <Input
                      autoFocus
                      required
                      value={tagEditingName}
                      onChange={(e) => {
                        if (e.target.value.length <= 20)
                          setTagEditingName(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Description</FormLabel>
                    <Input
                      required
                      value={tagEditingDescription}
                      onChange={(e) => {
                        if (e.target.value.length <= 300)
                          setTagEditingDescription(e.target.value);
                      }}
                    />
                  </FormControl>
                </Stack>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                  <Chip
                    sx={{ m: "auto", p: 1, gap: 1 }}
                    size="lg"
                    variant="outlined"
                    startDecorator={tagEditingIcon}
                  >
                    {tagEditingName}
                  </Chip>
                  <Button loading={loading} type="submit">
                    Salvar{" "}
                  </Button>
                </Box>
              </Box>
            </form>
          </DialogContent>
        </ModalDialog>
      </Modal>

      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        // sx={{
        //   display: { xs: "none", sm: "initial" },
        //   width: "100%",
        //   borderRadius: "sm",
        //   flexShrink: 1,
        //   overflow: "auto",
        //   minHeight: 0,
        // }}

        sx={(theme) => ({
          display: { xs: "none", sm: "initial" },
          flexShrink: 1,
          borderRadius: "sm",
          minHeight: 0,
          "--TableCell-height": "40px",
          // the number is the amount of the header rows.
          "--TableHeader-height": "calc(1 * var(--TableCell-height))",
          height: "70vh",
          overflow: "auto",
          background: `linear-gradient(${theme.vars.palette.background.surface} 30%, rgba(255, 255, 255, 0)),
            linear-gradient(rgba(255, 255, 255, 0), ${theme.vars.palette.background.surface} 70%) 0 100%,
            radial-gradient(
              farthest-side at 50% 0,
              rgba(0, 0, 0, 0.12),
              rgba(0, 0, 0, 0)
            ),
            radial-gradient(
                farthest-side at 50% 100%,
                rgba(0, 0, 0, 0.12),
                rgba(0, 0, 0, 0)
              )
              0 100%`,
          backgroundSize: "100% 40px, 100% 40px, 100% 14px, 100% 14px",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "local, local, scroll, scroll",
          backgroundPosition:
            "0 var(--TableHeader-height), 0 100%, 0 var(--TableHeader-height), 0 100%",
          backgroundColor: "background.surface",
        })}
      >
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
          hoverRow
          sx={{
            "--TableCell-headBackground":
              "var(--joy-palette-background-level1)",
            "--Table-headerUnderlineThickness": "1px",
            "--TableRow-hoverBackground":
              "var(--joy-palette-background-level1)",
            "--TableCell-paddingY": "4px",
            "--TableCell-paddingX": "8px",
          }}
        >
          <thead>
            <tr>
              <th
                style={{ width: 48, textAlign: "center", padding: "12px 6px" }}
              >
                <Checkbox
                  size="sm"
                  indeterminate={
                    selected.length > 0 && selected.length !== rows.length
                  }
                  checked={selected.length === rows.length}
                  onChange={(event) => {
                    setSelected(
                      event.target.checked
                        ? rows.map((row) => String(row.id))
                        : []
                    );
                  }}
                  color={
                    selected.length > 0 || selected.length === rows.length
                      ? "primary"
                      : undefined
                  }
                  sx={{ verticalAlign: "text-bottom" }}
                />
              </th>
              <th style={{ width: 50, padding: "12px 6px" }}> </th>
              <th style={{ width: 60, padding: "12px 6px" }}>Contatos</th>
              <th style={{ width: 100, padding: "12px 6px" }}>Nome</th>
              <th style={{ width: 30, padding: "12px 6px" }}>Icone</th>
              <th style={{ width: 200, padding: "12px 6px" }}>Descrição</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row) => (
              <tr key={row.id}>
                <td style={{ textAlign: "center" }}>
                  <Checkbox
                    size="sm"
                    checked={selected.includes(String(row.id))}
                    color={
                      selected.includes(String(row.id)) ? "primary" : undefined
                    }
                    onChange={(event) => {
                      setSelected((ids) =>
                        event.target.checked
                          ? ids.concat(String(row.id))
                          : ids.filter((itemId) => itemId !== String(row.id))
                      );
                    }}
                    slotProps={{ checkbox: { sx: { textAlign: "left" } } }}
                    sx={{ verticalAlign: "text-bottom" }}
                  />
                </td>
                <td>
                  <RowMenu
                    row={row}
                  />
                </td>
                <td>
                  <Typography level="body-md">NULL</Typography>
                </td>
                <td>
                  <Chip>
                    <Typography level="title-md">{row.name}</Typography>
                  </Chip>
                </td>
                <td>
                  <Typography level="title-lg">{row.icon}</Typography>
                </td>
                <td>
                  <Typography level="body-md">{row.description}</Typography>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Sheet>
    </React.Fragment>
  );
}

function TagsList({ rows, RowMenuComponet }: { rows: TagTable[], RowMenuComponet: React.FC<{ row: TagTable }> }) {
  const listItems = rows;
  const { handleUpdateTags } = useContext(ChatsDataContext);
  return (
    <Box sx={{ display: { xs: "block", sm: "none" } }}>
      {listItems.map((listItem) => (
        <List
          key={listItem.id}
          size="sm"
          sx={{
            "--ListItem-paddingX": 0,
          }}
        >
          <ListItem
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "start",
            }}
          >
            <ListItemContent
              sx={{ display: "flex", gap: 2, alignItems: "start" }}
            >
              <ListItemDecorator>
                <Avatar size="sm">{listItem.icon}</Avatar>
              </ListItemDecorator>
              <div>
                <Typography fontWeight={600} gutterBottom>
                  {listItem.name}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: 0.5,
                    mb: 1,
                  }}
                >
                  <Typography level="body-xs">
                    {listItem.description}
                  </Typography>
                </Box>
              </div>
            </ListItemContent>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 1 }}>
              <RowMenuComponet
                row={listItem}
              />
            </Box>
          </ListItem>
          <ListDivider />
        </List>
      ))}
    </Box>
  );
}
