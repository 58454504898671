import { FluxoData } from "../fluxoDataTypes";

export const fluxoEmpty: FluxoData = {
  id: 0,
  script_id: 0,
  fluxo_name: "novo fluxo",
  fluxo_type: "PRODUCT",
  user_id: 0,
  is_saved: false,

  product: {
    name: "",
    description: "",
    variations: [
      {
        id: 0,
        description: "quantidade do produto",
        keyRow: "quantidade",
        valueRow: "1 kit",
        editing: false,
        fixed: true,
      }
    ],
  },
  offers: [],

  remarketing_offers: [],
  remarketing_messages: [
    {
      messages: [
        {
          id: 0,
          type: "message",
          content: "mensagem de remarketing",
          hasMidia: false,
        },
      ],
      ativation_hours: 0,
      activate_next_offer: false,
    },
  ],

  dict_texts: {
    instructions: [],
    restrictions: [],
    faqs: [],
    cities: [],
    payment_methods: [],
    deliveries_instructions: [],
    support_instructions: [],
    reports: [],
    scheduling_instructions: [],
  },

  steps_blocks: [],
  default_messages: {
    error: {
      id: 0,
      type: "message",
      content: "Desculpe, não entendi.",
      hasMidia: false,
    },
  },
  message_blocks: {},

  has_confirmation: false,
  script_blocks: [],
  script_end_blocks: [
    {
      id: 0,
      type: "message",
      content: "mensagem de final",
      hasMidia: false,
    },
  ],

  has_delivery_holiday: false,
  accepted_delivery_days: [],
};
