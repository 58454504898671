import * as React from "react";
import ChatIcon from "@material-ui/icons/Chat";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Checkbox from "@mui/joy/Checkbox";
import Divider from "@mui/joy/Divider";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import IconButton from "@mui/joy/IconButton";
import Input from "@mui/joy/Input";
import Typography from "@mui/joy/Typography";
import Stack from "@mui/joy/Stack";
import GoogleIcon from "./GoogleIcon";
import { ModeToggle } from "../../components/ColorSchemeToggle";
import { login, register } from "../../hooks/login";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import { UserDataContext } from "../../contexts/UserContext";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { useNavigate } from "react-router-dom";
import KeyRoundedIcon from "@mui/icons-material/KeyRounded";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import { FormHelperText, LinearProgress } from "@mui/joy";
import { Help } from "@mui/icons-material";

interface FormElements extends HTMLFormControlsCollection {
  email: HTMLInputElement;
  password: HTMLInputElement;
  persistent: HTMLInputElement;
}
interface SignInFormElement extends HTMLFormElement {
  readonly elements: FormElements;
}

export function SignUpSide() {
  const navigate = useNavigate();
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [pw, setPw] = React.useState("");
  const [confirm, setConfirm] = React.useState("");
  const [verifySubmit, setVerifySubmit] = React.useState(false);
  const [sendSubimit, setSendSubimit] = React.useState(false);

  const handleSubmit = async (e: React.FormEvent<SignInFormElement>) => {
    e.preventDefault();
    setSendSubimit(true);
    setVerifySubmit(true);
    if (pw !== confirm) {
      setSendSubimit(false);
      return;
    }

    const result = await register(name, email, pw);
    setSendSubimit(false);
    if (result) {
      navigate("/entrar");
    }
  };

  return (
    <Box>
      <Box
        sx={{
          width: { xs: "100%", md: "50vw" },
          transition: "width 0.4s",
          transitionDelay: "calc(0.4s + 0.1s)",
          position: "relative",
          zIndex: 1,
          display: "flex",
          justifyContent: "flex-end",
          backdropFilter: "blur(12px)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100dvh",
            width: "100%",
            px: 2,
          }}
        >
          <Box
            component="header"
            sx={{
              py: 3,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
              <IconButton variant="soft" color="primary" size="sm" onClick={() => navigate("/home")}>
                <ChatIcon />
              </IconButton>
              <Typography level="title-lg">IA Chats</Typography>
              {/* <ModeToggle /> */}
            </Box>
            <ModeToggle />
          </Box>
          <Box
            component="main"
            sx={{
              my: "auto",
              py: 2,
              pb: 5,
              display: "flex",
              flexDirection: "column",
              gap: 2,
              width: 400,
              maxWidth: "100%",
              mx: "auto",
              borderRadius: "sm",
              "& form": {
                display: "flex",
                flexDirection: "column",
                gap: 2,
              },
              [`& .MuiFormLabel-asterisk`]: {
                visibility: "hidden",
              },
            }}
          >
            <Stack gap={4} sx={{ mb: 2 }}>
              <Stack gap={1}>
                <Typography component="h1" level="h3">
                  Sign up
                </Typography>
                <Typography level="body-sm"></Typography>
              </Stack>
              <Button
                variant="soft"
                color="neutral"
                fullWidth
                startDecorator={<GoogleIcon />}
              >
                entrar com o Google
              </Button>
            </Stack>
            <Divider
              sx={(theme) => ({
                [theme.getColorSchemeSelector("light")]: {
                  color: { xs: "#FFF", md: "text.tertiary" },
                },
              })}
            >
              ou
            </Divider>

            <form onSubmit={handleSubmit} id="demo">
              <Stack gap={2} sx={{ mt: 2 }}>
                <Typography level="h4" textAlign="center">
                  Cadastre-se
                </Typography>
                <FormControl required>
                  <FormLabel>Name</FormLabel>
                  <Input
                    startDecorator={<AccountCircleRoundedIcon />}
                    type="text"
                    name="name"
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                  />
                </FormControl>
                <FormControl required>
                  <FormLabel>Email</FormLabel>
                  <Input
                    startDecorator={<EmailRoundedIcon />}
                    type="email"
                    name="email"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </FormControl>

                <Stack
                  spacing={0}
                  sx={{
                    "--hue": Math.min(pw.length * 10, 120),
                  }}
                >
                  <FormControl required>
                    <FormLabel>Senha</FormLabel>
                    <Input
                      type="password"
                      placeholder="Digite a senha..."
                      startDecorator={<KeyRoundedIcon />}
                      value={pw}
                      onChange={(event) => setPw(event.target.value)}
                    />
                    <LinearProgress
                      determinate
                      size="sm"
                      value={Math.min(pw.length * 20, 200)}
                      sx={{
                        bgcolor: "background.level3",
                        color: "hsl(var(--hue) 80% 40%)",
                        width: 200,
                      }}
                    />
                    <Typography
                      level="body-xs"
                      sx={{
                        alignSelf: "flex-end",
                        color: "hsl(var(--hue) 80% 30%)",
                      }}
                    >
                      {pw.length === 0 && "."}
                      {pw.length < 3 && pw.length > 0  && "Muito fraca"}
                      {pw.length >= 3 && pw.length < 6 && "Fraca"}
                      {pw.length >= 6 && pw.length < 10 && "Forte"}
                      {pw.length >= 10 && "Fortíssima!"}
                    </Typography>
                  </FormControl>
                  <FormControl required error={verifySubmit && pw !== confirm}>
                    <FormLabel>Confirme a senha</FormLabel>
                    <Input
                      type="password"
                      placeholder="Digite a senha novamente..."
                      startDecorator={<KeyRoundedIcon />}
                      value={confirm}
                      onChange={(event) => {
                        setVerifySubmit(false);
                        setConfirm(event.target.value)}}
                    />
                    {verifySubmit && pw !== confirm && (
                      <FormHelperText>
                        <InfoOutlined />
                        as senha devem ser iguais.
                      </FormHelperText>
                    )}
                  </FormControl>
                </Stack>

                <Stack gap={4} sx={{ mt: 2 }}>
                  <Button type="submit" fullWidth loading={sendSubimit}>
                    Criar
                  </Button>
                </Stack>
              </Stack>
            </form>
          </Box>
          <Box component="footer" sx={{ py: 3 }}>
            <Typography level="body-xs" textAlign="center">
              © TRIL {new Date().getFullYear()}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          height: "100%",
          position: "fixed",
          right: 0,
          top: 0,
          bottom: 0,
          left: { xs: 0, md: "50vw" },
          transition: "background-image 0.4s, left 0.4s !important",
          transitionDelay: "calc(0.4s + 0.1s)",
          backgroundColor: "background.level1",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundImage:
            "url(https://images.unsplash.com/photo-1527181152855-fc03fc7949c8?auto=format&w=1000&dpr=2)",
          [theme.getColorSchemeSelector("dark")]: {
            backgroundImage:
              "url(https://images.unsplash.com/photo-1572072393749-3ca9c8ea0831?auto=format&w=1000&dpr=2)",
          },
        })}
      />
    </Box>
  );
}
