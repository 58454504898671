
import { FluxoData, JsonFluxoData } from "./fluxoDataTypes";
import { MessageBlock } from "./types";


export function jsonToFluxoData(jsonData: JsonFluxoData): FluxoData {
  const fluxo: FluxoData = {
    id: jsonData.id,
    script_id: 0, // preencher com os dados corretos se disponíveis
    fluxo_name: jsonData.nome_fluxo,
    fluxo_type: jsonData.tipo_fluxo,
    user_id: jsonData.user_id,
    is_saved: true,

    product: {
      name: jsonData.product.name_br,
      variations: Object.entries(
        jsonData.product.attributes_with_description
      ).map(([key, value]) => ({
        id: Math.random(), // ou outra forma de gerar IDs únicos
        description: value,
        keyRow: key,
        valueRow: value,
      })),
      description: jsonData.dict_texts.descricao,
    },

    offers: jsonData.ofertas.map((oferta, index) => ({
      id: index,
      announcement: oferta,
      price: "", // preencher com os dados corretos se disponíveis
      installments: [],
    })),

    remarketing_offers: jsonData.ofertas_remarketing.map((oferta, index) => ({
      id: index,
      announcement: oferta,
      price: "", // preencher com os dados corretos se disponíveis
      installments: [],
    })),

    remarketing_messages: [], // preencher com os dados corretos se disponíveis

    dict_texts: {
      instructions: jsonData.dict_texts.instrucoes
        .split("\n")
        .map((str, index) => ({
          id: index,
          content: str,
        })),
      restrictions: jsonData.dict_texts.restricoes
        .split("\n")
        .map((str, index) => ({
          id: index,
          content: str,
        })),
      faqs: jsonData.dict_texts.perguntas_frequentes.split("\n").map((str, index) => ({
        id: index,
        content: str,
      })),

      cities: jsonData.dict_texts.cidades.split("\n").map((str, index) => ({
        id: index,
        keyRow: str.split(" - ")[0],
        valueRow: str.split(" - ")[1],
        description: "cidade",
      })),
      payment_methods: jsonData.dict_texts.pagamento.split("\n").map((str, index) => ({
        id: index,
        content: str,
      })),
      deliveries_instructions: jsonData.dict_texts.entregas
        .split("\n")
        .map((str, index) => ({
          id: index,
          content: str,
        })),
      support_instructions: jsonData.dict_texts.suporte
        .split("\n")
        .map((str, index) => ({
          id: index,
          content: str,
        })),
      reports: jsonData.dict_texts.relatos.split("\n").map((str, index) => ({
        id: index,
        type: "message",
        content: str,
        hasMidia: false,
      })),
      scheduling_instructions: jsonData.dict_texts.agendamento
        .split("\n")
        .map((str, index) => ({
          id: index,
          content: str,
        })),
      
    },

    steps_blocks: jsonData.blocos.map((bloco) => ({
      type: bloco.type,
      automatic_return: bloco.automatic_return,
      info_interest: bloco.info_interest,
    })),

    script_blocks: [], // preencher com os dados corretos se disponíveis

    script_end_blocks: [{
      id: 0,
      type: "message",
      content: "mensagem final",
      hasMidia: false,
    }], // preencher com os dados corretos se disponíveis

    default_messages: {
      error: {
        id: 0,
        type: "message",
        content: jsonData.default_messages.Error,
        hasMidia: false,
      },
    },

    message_blocks: Object.entries(jsonData.remarketing_messages).reduce(
      (acc, [key, value]) => {
        acc[key] = value.split("\n").map((str) => ({
          id: 0,
          type: "message",
          content: str,
          hasMidia: false,
        }));
        return acc;
      },
      {} as { [key: string]: MessageBlock[] }
    ),

    // files_media: jsonData.arquivos_midias,

    has_confirmation: jsonData.has_confirmation,
    has_delivery_holiday: jsonData.has_delivery_holiday,
    accepted_delivery_days: jsonData.accepted_delivery_days,
  };

  return fluxo;
}

