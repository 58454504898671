import { useState } from "react";
import { MessageBlock, VariableBlock } from "../../utils/types";
import { Badge, Box, Button, IconButton, Modal, ModalClose, ModalDialog, Stack, Tooltip, Typography } from "@mui/joy";
import PhishingRoundedIcon from "@mui/icons-material/PhishingRounded";
import SellRoundedIcon from "@mui/icons-material/SellRounded";

export const CardBlockActions = ({
    children,
    block,
    updateItem,
  }: {
    children: React.ReactElement;
    block: MessageBlock | VariableBlock;
    updateItem: (block: (MessageBlock | VariableBlock), remove?: boolean ) => void;
  }) => {
    const [actionTag, setActionTag] = useState(false);
    const [actionRecap, setActionRecap] = useState(false);
    const [openModal, setOpenModal] = useState(false);
  
    return (
      <>
        <Tooltip
          arrow={false}
          color="neutral"
          placement="left"
          open={openModal ? false : undefined}
          variant="plain"
          title={
            <Stack>
              <IconButton onClick={() => setOpenModal(true)}>
                <SellRoundedIcon />
              </IconButton>
              <IconButton onClick={() => setOpenModal(true)}>
                <PhishingRoundedIcon />
              </IconButton>
            </Stack>
          }
        >
          <Badge
            size="sm"
            invisible={!actionTag && !actionRecap}
            badgeContent={
              <Stack direction="row" p={0.5} spacing={0.5}>
                {actionTag && <SellRoundedIcon sx={{ color: "white" }} />}
                {actionRecap && <PhishingRoundedIcon sx={{ color: "white" }} />}
              </Stack>
            }
          >
            {children}
          </Badge>
        </Tooltip>
        <Modal open={openModal} onClose={() => setOpenModal(false)}>
          <ModalDialog>
            <ModalClose />
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Typography>Adicionar/Remover Etiqueta</Typography>
                <IconButton
                  color={actionTag ? "primary" : "neutral"}
                  onClick={() => setActionTag(!actionTag)}
                >
                  <SellRoundedIcon />
                </IconButton>
                <Typography>Inserir mensagem de repescagem</Typography>
                <IconButton
                  color={actionRecap ? "primary" : "neutral"}
                  onClick={() => setActionRecap(!actionRecap)}
                >
                  <PhishingRoundedIcon />
                </IconButton>
              </Box>
              <Box
                sx={{ display: "flex", justifyContent: "center", mt: 2 }}
                onClick={() => setOpenModal(false)}
              >
                <Button>Salvar</Button>
              </Box>
            </Box>
          </ModalDialog>
        </Modal>
      </>
    );
  };