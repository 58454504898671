import { Box, Button, Modal, ModalDialog, Typography } from "@mui/joy";
import { useContext, useState } from "react";
import { FluxoData } from "../../utils/fluxoDataTypes";
import { createFluxo, getFluxoData } from "../../hooks/fluxos";
import { FluxoDataContext } from "../../contexts";
import { fluxoDataToJson } from "../../utils/convertFluxoDataToJson";

export const PostFluxoBottom = ({ fluxo }: { fluxo: FluxoData }) => {
  const { fluxosData, setScriptData } = useContext(FluxoDataContext);
  const [posting, setPosting] = useState(false);
  const is_saved = fluxosData.find((f) => f.id === fluxo.id)?.is_saved;

  const [openModal, setOpenModal] = useState(false);

  const handlePost = async () => {
    setPosting(true);
    const newScriptData = await createFluxo(fluxo, await fluxoDataToJson(fluxo));
    if (newScriptData && setScriptData)
      setScriptData((prev) => [newScriptData, ...prev.filter((s) => s.id !== newScriptData.id)]);

    setPosting(false);
  };

  return (
    <Box sx={{ display: is_saved ? "none" : "block" }}>
      <Button
        fullWidth
        color="warning"
        onClick={() => setOpenModal(true)}
        loading={posting}
      >
        postar novo fluxo
      </Button>
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <ModalDialog>
          <Box>
            {/* <Typography level="title-md"> */}
            Postar as configurações do fluxo?
            {/* </Typography> */}
            <Typography level="body-sm">
              certifique que todas as{" "}
              <Typography color="warning">seções</Typography> estão preenchidas
              corretamente
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              gap: 2,
            }}
          >
            <Button
              variant="plain"
              color="neutral"
              onClick={() => setOpenModal(false)}
            >
              Cancelar
            </Button>
            <Button
              variant="solid"
              onClick={() => {
                setOpenModal(false);
                handlePost();
              }}
            >
              Postar
            </Button>
          </Box>
        </ModalDialog>
      </Modal>
    </Box>
  );
};
