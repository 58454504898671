import * as React from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Card,
  Dropdown,
  Menu,
  MenuButton,
  MenuItem,
  Typography,
} from "@mui/joy";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { ChatTable, MessageTable } from "../../utils/databaseTypes";

export default function BasicBars({
  setPeriod,
  period,
  chatsData,
  messagesData,
}: {
  period: string;
  setPeriod: React.Dispatch<React.SetStateAction<string>>;
  chatsData: ChatTable[];
  messagesData: MessageTable[];
}) {
  const [barsDataType, setBarsDataType] = React.useState("STATUS");
  const [grupsData, setGroupsData] = React.useState<string[]>([]);
  const [seriesData, setSeriesData] = React.useState<
    {
      data: number[];
    }[]
  >([]);

  const series = [
    { data: [4, 5, 2] },
    { data: [1, 6, 3] },
    { data: [2, 5, 6] },
    { data: [3, 4, 7] },
  ];

  const data = ["groupo A", "grupo 2", "grupo 7"];

  React.useEffect(() => {
    if (barsDataType === "FUNIO") {

    }
    if (barsDataType === "STATUS") {

    }
    if (barsDataType === "ETIQUETA") {

    }

    if (barsDataType === "DDD") {
    
    }

    setGroupsData(['grup1', 'grup2', 'grup3']);
    setSeriesData([
      { data: [4, 5, 2] },
      { data: [1, 6, 3] },
      { data: [2, 5, 6] },
    ]);

  }, [barsDataType, chatsData]);

  return (
    <Card
      sx={{
        backgroundColor: "transparent",
        borderRadius: 10,
        height: "100%",
        width: "100%",
        border: "1px solid",
        borderColor: "divider",
        padding: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        mt: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          gap: 2,
        }}
      >
        <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
          <Typography level="h2">Visualizar</Typography>

          <ButtonGroup
            buttonFlex={1}
            aria-label="flex button group"
            sx={{
              width: { xs: 250, sm: 400, md: 400, lg: 400 },
              overflow: "auto",
            }}
          >
            <Button
              variant={barsDataType === "FUNIO" ? "solid" : "outlined"}
              onClick={() => setBarsDataType("FUNIO")}
            >
              FUNIL
            </Button>
            <Button
              variant={barsDataType === "STATUS" ? "solid" : "outlined"}
              onClick={() => setBarsDataType("STATUS")}
            >
              STATUS
            </Button>
            <Button
              variant={barsDataType === "ETIQUETA" ? "solid" : "outlined"}
              onClick={() => setBarsDataType("ETIQUETA")}
            >
              ETIQUETA
            </Button>
            <Button
              variant={barsDataType === "DDD" ? "solid" : "outlined"}
              onClick={() => setBarsDataType("DDD")}
            >
              DDD
            </Button>
          </ButtonGroup>
        </Box>
        <Box sx={{ display: "flex", gap: 2 }}>
          <Autocomplete
            startDecorator={<SearchRoundedIcon />}
            sx={{ height: 40, width: { xs: 120, sm: 200, md: 200, lg: 300 } }}
            placeholder={`adicionar ${barsDataType}`}
            options={["Option 1", "Option 2"]}
          />

          <Dropdown>
            <MenuButton>
              {period} <ArrowDropDownIcon />
            </MenuButton>
            <Menu>
              <MenuItem onClick={() => setPeriod("DIARIA")}>DIARIO</MenuItem>
              <MenuItem onClick={() => setPeriod("SEMANAL")}>SEMANAL</MenuItem>
              <MenuItem onClick={() => setPeriod("MENSAL")}>MENSAL</MenuItem>
              <MenuItem onClick={() => setPeriod("ANUAL")}>ANUAL</MenuItem>
            </Menu>
          </Dropdown>
        </Box>
      </Box>
      <BarChart
        colors={[
          "rgba(2,178,175, 0.8)",
          "rgba(2,178,175, 0.6)",
          "rgba(2,178,175, 0.4)",
          "rgba(2,178,175, 0.2)",
        ]}
        xAxis={[{ scaleType: "band", data: grupsData }]}
        series={seriesData}
        margin={{ left: 30, right: 10, top: 20, bottom: 40 }}
        borderRadius={5}
        height={300}
        grid={{ vertical: true, horizontal: true }}
      />
    </Card>
  );
}
