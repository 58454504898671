import * as React from "react";
import IconButton from "@mui/joy/IconButton";
import Stack from "@mui/joy/Stack";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import { toggleMessagesPane } from "../../utils/utils";

import { Step, StepIndicator, Stepper, Tab, TabList, Typography } from "@mui/joy";
// import IconStepper from "./stepper";

import ViewInArRoundedIcon from "@mui/icons-material/ViewInArRounded";
import DeviceHubRoundedIcon from "@mui/icons-material/DeviceHubRounded";
import RestoreRoundedIcon from "@mui/icons-material/RestoreRounded";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { FluxoData } from "../../utils/fluxoDataTypes";

type MessagesPaneHeaderProps = {
  fluxoData: FluxoData | undefined;
};

export const tabs = [
  { label: "Instruções", icon: <FormatListBulletedIcon /> },
  { label: "Produto", icon: <ViewInArRoundedIcon /> },
  { label: "Roteiro", icon: <DeviceHubRoundedIcon /> },
  { label: "Remarketing", icon: <RestoreRoundedIcon /> },
];

export default function ScriptPaneHeader({
  fluxoData,
}: MessagesPaneHeaderProps) {

  const [activeStep, setActiveStep] = React.useState(0);
  
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{}}
      pt={{ xs: 2, md: fluxoData ? 2 : 0 }}
      px={{ xs: 1, md: 2 }}
    >
      <Stack direction="row" spacing={{ xs: 1, md: 2 }} alignItems="center">
        <IconButton
          variant="plain"
          color="neutral"
          size="sm"
          sx={{
            display: { xs: "flex", sm: "none" },
          }}
          onClick={() => toggleMessagesPane()}
        >
          <ArrowBackIosNewRoundedIcon />
        </IconButton>
      </Stack>

      <TabList
        // disableUnderline
        size="lg"
        sx={{
          mt: { xs: 0, md: 2 },
          bgcolor: "transparent",
          borderRadius: "xl",
          m: "auto",
          maxWidth: "90%",
          p: 0.5,
          gap: { xs: 0.5, md: 3, lg: 7 },
          // borderRadius: "xl",
        }}
      >
        { fluxoData && tabs.map((tab, index) => (
          <Tab
            key={index}
            value={index}
            sx={{
              display: { xs: "none", md: "none", lg: "flex" },
            }}
          >
            <Typography startDecorator={tab.icon} level="title-lg">
              {tab.label}
            </Typography>
          </Tab>
        ))}

        { fluxoData && tabs.map((tab, index) => (
          <Tab
            key={index}
            value={index}
            sx={{
              display: { xs: "flex", md: "flex", lg: "none" },
            }}
            
          >
            {tab.icon}
          </Tab>
        ))}

      </TabList>

    </Stack>
  );
}
