import {
  AspectRatio,
  CardContent,
  CardOverflow,
  Typography,
  Card,
  Input,
  Box,
  Textarea,
  Button,
  Tooltip,
} from "@mui/joy";
import { MessageBlock } from "../../utils/types";
import { useEffect, useState } from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import WhatsAppMediaViewer from "../messagesPage/MediaViewer";

const MessageCard = ({
  block,
  endLabel,
  updateItem,
  fixed,
}: {
  block: MessageBlock;
  endLabel?: string;
  updateItem?: (block: (MessageBlock), remove?: boolean ) => void;
  fixed?: boolean;
}) => {
  const [editMode, setEditMode] = useState(block.editMode ?? true);
  const [delaytime, setDelaytime] = useState(block.delayTime ?? 0);
  const [content, setContent] = useState(block.content);
  const [media, setMedia] = useState(block.media);
  const [hasMidia, setHasMidia] = useState(block.hasMidia);

  useEffect(() => {
    setEditMode(block.editMode ?? true);
    setDelaytime(block.delayTime ?? 0);
    setContent(block.content);
    setMedia(block.media);
    setHasMidia(block.hasMidia);
  }, [block]);

  
  const handleFileUpload = async () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "*"; // Change the accepted file types if needed
    fileInput.onchange = async (e) => {
      if (e.target instanceof HTMLInputElement) {
        const file = e.target.files?.[0];
        if (file) {
          const fileUrl = URL.createObjectURL(file);
          const fileType = file.type;

          setHasMidia(true);
          setMedia({
            media_url: fileUrl,
            media_type: fileType,
            media_filename: file.name,
          });
        }
      }
    };
    fileInput.click();
  };

  return (
    <Tooltip
      arrow={true}
      placement="right"
      variant="outlined"
      title={
        !editMode && updateItem && (
          <Box>
            <EditRoundedIcon
              sx={{
                cursor: "pointer",
                opacity: 0.2,
                "&:hover": {
                  opacity: 1,
                },
              }}
              onClick={() => setEditMode(true)}
            />

            {!fixed && (
              <CloseRoundedIcon
                sx={{
                  cursor: "pointer",
                  opacity: 0.2,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
                onClick={() => {
                  updateItem(block, true);
                }}
              />
            )}
          </Box>
        )
      }
    >
      <Card
        variant="outlined"
        sx={{ width: "100%", zIndex: 1, p: 0, gap: 0, overflow: "hidden" }}
      >
        {hasMidia && (
          <CardOverflow
            variant="plain"
            sx={{
              bgcolor: "background.level1",
              p: 0,
              m: 0,
            }}
          >
             <WhatsAppMediaViewer isSent={true} attachment={{has_media: true, is_from_me: false, media_filename: media?.media_filename, media_type: media?.media_type, media_url: media?.media_url?? '', id:0, is_transcribed: false, timestamp: 0, type: 'message', chat_id: -1, content: "" }} />
          </CardOverflow>
        )}
        {
          hasMidia && editMode && (
            <CardOverflow             
            >
              <Button
                variant="solid"
                color="primary"
                fullWidth
                onClick={() => {
                  setHasMidia(false);
                  setMedia(undefined);
                }}
              >
                Remover Mídia
              </Button>

             
            </CardOverflow>
          )
        }
        {editMode && (
          <CardOverflow
            variant="solid"
            sx={{
              p: 0,
              m: 0,
            }}
          >
            <Button
              sx={{ borderRadius: 0 }}
              variant="solid"
              color="neutral"
              fullWidth
              onClick={handleFileUpload}
            >
              Adicionar Mídia
            </Button>
          </CardOverflow>
        )}

        <CardContent
          sx={{
            display: "flex",
            flexDirection: "row",
            p: 1,
            alignItems: "center",
          }}
        >
          {editMode ? (
            <Textarea
              variant="plain"
              // startDecorator={<BorderColorRoundedIcon />}
              sx={{ flex: 1, p: 1, m: 0 }}
              value={content}
              onChange={(e) => {
                setContent(e.target.value);
              }}
            />
          ) : (
            <Box sx={{ flex: 1 }}>
              <Typography level="body-md">{block.content}</Typography>
            </Box>
          )}
        </CardContent>

        {editMode ? (
          <CardOverflow
            variant="plain"
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              bgcolor: "background.level1",
              p: 0,
              textAlign: "center",
              overflow: "auto",
            }}
          >
            <Input
              variant="plain"
              startDecorator={<AccessTimeRoundedIcon />}
              endDecorator={<Typography>Segundos</Typography>}
              sx={{ bgcolor: "background.level1" }}
              type="number"
              placeholder="tempo"
              value={delaytime}
              onChange={(e: any) => {
                const delay = Number(e.target.value);
                if (delay >= 0) setDelaytime(delay);
              }}
            />
            <Typography sx={{ p: 1 }}>delay</Typography>
          </CardOverflow>
        ) : (
          delaytime > 0 && (
            <CardOverflow
              variant="soft"
              // color="primary"
              sx={{
                p: 1,
                justifyContent: "space-between",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Typography
                startDecorator={<AccessTimeRoundedIcon />}
                endDecorator={<Typography>Segundos</Typography>}
              >
                {block.delayTime}
              </Typography>
              <Typography>Delay</Typography>
            </CardOverflow>
          )
        )}

        {endLabel && (
          <CardOverflow
            variant="solid"
            color="primary"
            sx={{ p: 1, textAlign: "center" }}
          >
            {endLabel}
          </CardOverflow>
        )}

        {editMode && (
          <CardOverflow
            variant="plain"
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              bgcolor: "background.level1",
              p: 0,
              textAlign: "center",
            }}
          >
            <Button
              startDecorator={<DoneRoundedIcon />}
              variant="solid"
              color="primary"
              sx={{ borderRadius: 0, flex: 1 }}
              onClick={() => {
                if (updateItem)
                updateItem({
                  ...block,
                  content,
                  delayTime: delaytime,
                  hasMidia,
                  media,
                  editMode: false,
                });
                setEditMode(false);
              }}

            >
              salvar
            </Button>
          </CardOverflow>
        )}
      </Card>
    </Tooltip>
  );
};

export default MessageCard;
