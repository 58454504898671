
import { StringRow } from "../pages/fluxosPage/RowTableForm";

export type MessagePrototype = {
  is_from_me: boolean;
  type: string;
  content: string | undefined;
  timestamp: number;
  is_transcribed: boolean;
  media_type: string | undefined;
  media_url: string | undefined;
  media_filename: string | undefined;
  has_media: boolean;
  contact_name: string;
  contact_phone: string;
};

export type ChatStatus = "STARTED" | "LEAD" | "FINISHED";

export const statusLabels = {
  "STARTED": "Iniciado",
  "LEAD": "Iniciado",
  "FINISHED": "Finalizado",
}

export type UserData = {
  id: number;
  name: string;
  email: string;
};

export type VariableBlock = {
  id: number;
  type: "variable";
  required: boolean;
  varKey: string;
  varDescription?: string;
  editMode?: boolean;
};

export type MessageBlock = {
  id: number;
  type: "message";
  content: string;
  hasMidia: boolean;
  delayTime?: number;
  media?: Media;
  editMode?: boolean;
  putTags?: string[];
  removeTags?: string[];
  recapBlock?: boolean;
};

 type Media = {
  media_type: string | undefined;
  media_url: string | undefined;
  media_filename: string | undefined;
};

export type ProductInfo = {
  name: string;
  description: string;
  price: number;
  attributes: string[];
};

export type RemarketingScript = {
  message: MessageBlock;
  secondsTimeTrigger: number;
  updatedProductInfo: ProductInfo | undefined;
};

export type FAQBlock = {
  id: number;
  question: string;
  answer: MessageBlock;
};


export type ScriptVariable = { id: number, name: string, description: string };

export type ProductOffer = {
  id: number;
  announcement: string;
  price: string;
  installments: StringRow[];
};

export const keysUF = [
  { id: 0, title: "AC", description: "Acre" },
  { id: 1, title: "AL", description: "Alagoas" },
  { id: 2, title: "AP", description: "Amapá" },
  { id: 3, title: "AM", description: "Amazonas" },
  { id: 4, title: "BA", description: "Bahia" },
  { id: 5, title: "CE", description: "Ceará" },
  { id: 6, title: "DF", description: "Distrito Federal" },
  { id: 7, title: "ES", description: "Espírito Santo" },
  { id: 8, title: "GO", description: "Goiás" },
  { id: 9, title: "MA", description: "Maranhão" },
  { id: 10, title: "MT", description: "Mato Grosso" },
  { id: 11, title: "MS", description: "Mato Grosso do Sul" },
  { id: 12, title: "MG", description: "Minas Gerais" },
  { id: 13, title: "PA", description: "Pará" },
  { id: 14, title: "PB", description: "Paraíba" },
  { id: 15, title: "PR", description: "Paraná" },
  { id: 16, title: "PE", description: "Pernambuco" },
  { id: 17, title: "PI", description: "Piauí" },
  { id: 18, title: "RJ", description: "Rio de Janeiro" },
  { id: 19, title: "RN", description: "Rio Grande do Norte" },
  { id: 20, title: "RS", description: "Rio Grande do Sul" },
  { id: 21, title: "RO", description: "Rondônia" },
  { id: 22, title: "RR", description: "Roraima" },
  { id: 23, title: "SC", description: "Santa Catarina" },
  { id: 24, title: "SP", description: "São Paulo" },
  { id: 25, title: "SE", description: "Sergipe" },
  { id: 26, title: "TO", description: "Tocantins" },
];

