import React, { createContext, useContext, useEffect, useState } from "react";
import { ChatsDataContext } from "./ChatsContext";
import { ConnectionsContext } from "./ConnectionsContext";
import { ChatTable } from "../utils/databaseTypes";
import { MessageBlock, MessagePrototype } from "../utils/types";

interface WSConnectionContextProps {
  sendMessage: (data: { chat: ChatTable, message?: MessagePrototype, block?: MessageBlock }) => void;
}

export const WSConnectionContext = createContext<WSConnectionContextProps>(
  {} as WSConnectionContextProps
);

interface WSConnectionProviderProps {
  children: React.ReactNode;
}

// Singleton WebSocket connection and variable to track initialization
let socket: WebSocket | null = null;

export const WSConnectionProvider: React.FC<WSConnectionProviderProps> = ({
  children,
}) => {
  const { handleNewMessage } = useContext(ChatsDataContext);
  const { handleWhatsappConnectionUpdated, handleQrCodeConection } =
    useContext(ConnectionsContext);

  useEffect(() => {
    // Create the WebSocket connection if it doesn't exist
    if (!socket || socket.readyState === WebSocket.CLOSED) {
      socket = new WebSocket(
        process.env.REACT_APP_SERVER_URL?.replace("http", "ws") || ""
      );

      const handleOpen = (event: Event) => {
        socket?.send(
          JSON.stringify({
            type: "CONNECTION",
            token: localStorage.getItem("token"),
          })
        );
        socket?.send(JSON.stringify({ statusMsg: "Olá, servidor!" }));
      };

      const handleMessage = (event: MessageEvent) => {
        console.log("WS message from server:", event.data);
        const data = JSON.parse(event.data);
        const { type } = data;

        if (type === "QR_CODE") handleQrCodeConection(data);
        if (type === "NEW_MESSAGE") handleNewMessage(data);
        if (type === "WHATSAPP_CONECTION_UPDATED")
          handleWhatsappConnectionUpdated(data);
      };

      const handleClose = (event: CloseEvent) => {
        console.log("Desconectado do servidor WebSocket");
      };

      const handleError = (event: Event) => {
        console.error("Erro no WebSocket:", event);
      };

      socket.addEventListener("open", handleOpen);
      socket.addEventListener("message", handleMessage);
      socket.addEventListener("close", handleClose);
      socket.addEventListener("error", handleError);
    }
  }, [
    handleNewMessage,
    handleWhatsappConnectionUpdated,
    handleQrCodeConection,
  ]);

  const sendMessage = (data: { chat: ChatTable, message?: MessagePrototype, block?: MessageBlock }) => {
    console.log("Sending message:", data);
    if (socket?.readyState === WebSocket.OPEN) {
      socket.send(
        JSON.stringify({
          type: "SEND_MESSAGE",
          token: localStorage.getItem("token"),
          ...data,
        })
      );
    } else {
      console.error("WebSocket is not open. Cannot send message.");
    }
  };

  return (
    <WSConnectionContext.Provider value={{ sendMessage }}>
      {children}
    </WSConnectionContext.Provider>
  );
};
