import {
  Box,
  Button,
  Card,
  Divider,
  Input,
  Sheet,
  Textarea,
  Tooltip,
  Typography,
} from "@mui/joy";
import IconButton from "@mui/joy/IconButton";
import HttpsRoundedIcon from "@mui/icons-material/HttpsRounded";
import BorderColorRoundedIcon from "@mui/icons-material/BorderColorRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import CheckIcon from "@mui/icons-material/Check";
import React from "react";

export const RowTableForm = ({
  title,
  rows,
  insertButtonTitle,
  insert,
}: {
  title: string;
  rows: JSX.Element[];
  insertButtonTitle: string;
  insert: () => void;
}) => {
  return (
    <Card
      sx={{
        overflow: "hidden",
        p: 0,
        gap: 0,
      }}
    >
      <Box
        sx={{
          p: 1,
          bgcolor: "background.level2",
        }}
      >
        <Typography level="title-lg">{title}</Typography>
      </Box>

      <Box>
        {rows.map((row, index) => (
          <Box key={index}>
            {row}
            <Divider />
          </Box>
        ))}
      </Box>

      <Button
        sx={{
          borderRadius: 0,
          width: "100%",
        }}
        onClick={() => {
          insert();
        }}
      >
        {insertButtonTitle}
      </Button>
    </Card>
  );
};

export type StringRow = {
  id: number;
  content: string;
  fixed?: boolean;
  editing?: boolean;
};

export const StringRowRender = ({
  row,
  updateRow,
}: {
  row: StringRow;
  updateRow: (updatedRow: StringRow, remove?: boolean) => void;
}) => {
  return (
    <Tooltip variant="plain" arrow placement="right" title={!row.editing ? (
      <IconButton
        sx={{ p: 0, m: 0, opacity: 0.5, ":hover": { opacity: 1 } }}
        size="sm"
        disabled={row.fixed}
        onClick={
          row.fixed ? undefined : () => updateRow({ ...row, editing: true })
        }
      >
        {row.fixed ? <HttpsRoundedIcon /> : <EditRoundedIcon />}
      </IconButton>
    ) : 'editar'}>
    <Box
   
      key={row.id}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        px: row.editing ? 0 : 1,
        py: row.editing ? 0 : 0.5,
        alignItems: "center",
      }}
    >
      {row.editing ? (
        <Textarea
          variant="soft"
          sx={{ p: 1, borderRadius: 0, width: "100%" }}
          onChange={(e) => updateRow({ ...row, content: e.target.value })}
          value={row.content}
        ></Textarea>
      ) : (
        <Typography  onClick={() => {  
          if(!row.fixed) {
            updateRow({ ...row, editing: true })
          }
        }} >{row.content}</Typography>
      )}

      <Box sx={{ display: "flex", flexDirection:"column", height: "100%" }} >
        

        {row.editing && (
          <IconButton
            sx={{ p: 0, m: 0, opacity: 0.3, ":hover": { opacity: 1 } }}
            size="sm"
            onClick={
              row.fixed ? undefined : () => updateRow({ ...row, editing: false })
            }
          >
            <CheckIcon />
          </IconButton>
        )}

        {!row.fixed && row.editing && (
          <IconButton
            sx={{ p: 0, m: 0, opacity: 0.3, ":hover": { opacity: 1 } }}
            size="sm"
            disabled={row.fixed}
            onClick={row.fixed ? undefined : () => updateRow(row, true)}
          >
            <ClearRoundedIcon />
          </IconButton>
        )}
      </Box>
    </Box>
    </Tooltip>
  );
};

export type KeyValueRow = {
  id: number;
  description: string;
  keyRow: string;
  valueRow: string;
  editing?: boolean;
  fixed?: boolean;
};

export const KeyValueRowRender = ({
  row,
  updateRow,
}: {
  row: KeyValueRow;
  updateRow: (updatedRow: KeyValueRow, remove?: boolean) => void;
}) => {
  return (
    <Tooltip variant="plain" arrow placement="right" title={!row.editing ? (
      <IconButton
        sx={{ p: 0, m: 0, opacity: 0.5, ":hover": { opacity: 1 } }}
        size="sm"
        disabled={row.fixed}
        onClick={
          row.fixed ? undefined : () => updateRow({ ...row, editing: true })
        }
      >
        {row.fixed ? <HttpsRoundedIcon /> : <EditRoundedIcon />}
      </IconButton>
    ) : 'editar'}> 

    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        {row.editing ? (
          <Input
            fullWidth
            sx={{ p: 1, borderRadius: 0 }}
            startDecorator={<BorderColorRoundedIcon />}
            placeholder="Atributo"
            onChange={(e) =>
              updateRow({ ...row, keyRow: e.target.value })
            }
            value={row.keyRow}
          ></Input>
        ) : (
          <Box sx={{ p: 1, flex: 1 }}>
            <Typography>{row.keyRow}</Typography>
          </Box>
        )}

        {row.editing ? (
          <Input
            fullWidth
            sx={{ p: 1, borderRadius: 0 }}
            placeholder="Valor"
            startDecorator={<BorderColorRoundedIcon />}
            onChange={(e) =>
              updateRow({ ...row, valueRow: e.target.value })
            }
            value={row.valueRow}
          ></Input>
        ) : (
          <Typography>{row.valueRow}</Typography>
        )}

        <Box sx={{ display: "flex", opacity: 0.5 }}>
   

          {!row.fixed && row.editing && (
            <IconButton
              sx={{ p: 0, m: 0 }}
              size="sm"
              disabled={row.fixed}
              onClick={row.fixed ? undefined : () => updateRow(row, true)}
            >
              <ClearRoundedIcon />
            </IconButton>
          )}
        </Box>
      </Box>

      {row.editing && (
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          {" "}
          <Input
            startDecorator={<BorderColorRoundedIcon />}
            value={row.description}
            placeholder="Descrição"
            onChange={(e) => updateRow({ ...row, description: e.target.value })}
            fullWidth
          />
          <IconButton
            sx={{ p: 0, m: 0 }}
            size="sm"
            onClick={() => updateRow({ ...row, editing: false })}
          >
            <CheckIcon />
          </IconButton>
        </Box>
      )}
    </Box>
    </Tooltip>
  );
};
