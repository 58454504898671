import * as React from "react";
import { useEffect, useState } from "react";
import Box from "@mui/joy/Box";
import AspectRatio from "@mui/joy/AspectRatio";
import ImageIcon from "@mui/icons-material/Image";
import AudioFileIcon from "@mui/icons-material/Audiotrack";
import VideoFileIcon from "@mui/icons-material/Movie";
import InsertDriveFileRoundedIcon from "@mui/icons-material/InsertDriveFileRounded";
import ArticleIcon from "@mui/icons-material/Article";
import DownloadIcon from "@mui/icons-material/Download";
import { MessageTable } from "../../utils/databaseTypes";

const WhatsAppMediaViewer = (props: {
  attachment: MessageTable;
  isSent: boolean;
}) => {
  const { attachment } = props;
  const [mediaType, setMediaType] = useState<string | undefined>();
  const [mediaUrl, setMediaUrl] = useState<string | undefined>();
  const mimetype = attachment.media_type;

  // Determine the media type based on the mimetype
  useEffect(() => {
    if (attachment.has_media && mimetype === undefined) return;

    let type;
    if (
      mimetype?.startsWith("image/") ||
      mimetype?.includes("jpeg") ||
      mimetype?.includes("png") ||
      mimetype?.includes("gif") ||
      mimetype?.includes("bmp") ||
      mimetype?.includes("webp")
    ) {
      type = "image";
    } else if (
      mimetype?.startsWith("audio/") ||
      mimetype?.includes("ogg") ||
      mimetype?.includes("wav") ||
      mimetype?.includes("mp3") ||
      mimetype?.includes("m4a") ||
      mimetype?.includes("aac") ||
      mimetype?.includes("flac") ||
      mimetype?.includes("opus")
    ) {
      type = "audio";
    } else if (
      mimetype?.startsWith("video/") ||
      mimetype?.includes("mp4") ||
      mimetype?.includes("webm") ||
      mimetype?.includes("mkv") ||
      mimetype?.includes("avi") ||
      mimetype?.includes("flv") ||
      mimetype?.includes("mov") ||
      mimetype?.includes("wmv") ||
      mimetype?.includes("mpg") ||
      mimetype?.includes("mpeg") ||
      mimetype?.includes("3gp") ||
      mimetype?.includes("m4v") 
    ) {
      type = "video";
    } else if (mimetype === "application/pdf") {
      type = "pdf";
    } else if (
      mimetype?.startsWith("text/") ||
      mimetype === "application/json" ||
      mimetype === "application/xml"
    ) {
      type = "text";
    } else {
      console.log("Unsupported media type", mimetype);
      type = "file"; // For other types, we treat them as downloadable files
    }

    setMediaType(type);
    setMediaUrl(
      attachment.media_url?.replace(
        "$SERVER_URL",
        String(process.env.REACT_APP_SERVER_URL)
      )
    );
  }, [attachment, mimetype]);

  return (
    <Box sx={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}>
      {mediaType === "image" && (
        <img
          src={mediaUrl}
          alt={attachment.media_filename || "Image"}
          style={{ maxWidth: 300, objectFit: "cover", borderRadius: 8 }}
        />
      )}
      {mediaType === "audio" && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <audio controls>
          <source src={mediaUrl} type={"audio/ogg"} />
            Your browser does not support the audio tag.
          </audio>
        </Box>
      )}
      {mediaType === "video" && (
        <video
          controls
          style={{ maxWidth: 300, objectFit: "cover", borderRadius: 8 }}
        >
          <source src={mediaUrl} type={"video/mp4"} />
          Your browser does not support the video tag.
        </video>
      )}
      {mediaType === "pdf" && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <a href={mediaUrl} target="_blank" rel="noopener noreferrer">
            <Box sx={{ textAlign: "center" }}>
              <InsertDriveFileRoundedIcon
                sx={{ fontSize: "3rem", color: "#f40" }}
              />
              <InsertDriveFileRoundedIcon
                sx={{ fontSize: "3rem", color: "#f40" }}
              />
              <p>{attachment.media_filename || "PDF Document"}</p>
            </Box>
          </a>
        </Box>
      )}
      {mediaType === "text" && (
        <Box sx={{ width: "100%" }}>
          <iframe
            src={mediaUrl}
            title="Text Document"
            style={{ width: "100%", height: "200px", border: "none" }}
          ></iframe>
        </Box>
      )}
      {mediaType === "file" && (
        <Box sx={{ textAlign: "center" }}>
          <a href={mediaUrl} download={attachment.media_filename}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 1,
              }}
            >
              <DownloadIcon sx={{ fontSize: "3rem" }} />
              <p>{attachment.media_filename || "Download File"}</p>
            </Box>
          </a>
        </Box>
      )}
      {!mediaType && (
        <Box sx={{ textAlign: "center" }}>
          <ImageIcon sx={{ fontSize: "3rem", opacity: 0.2 }} />
          <p>Unsupported media type</p>
        </Box>
      )}
    </Box>
  );
};

export default WhatsAppMediaViewer;
