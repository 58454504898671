import { UserTable } from "../utils/databaseTypes";
import { UserData } from "../utils/types";


export const getUserData = async (): Promise<UserData | undefined> => {
  try {
    const response = await fetch( process.env.REACT_APP_SERVER_URL + "/user", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data.data as UserData;
  } catch (error) {
    console.error("Error:", error);
    return undefined;
  }
};

export const updateUser = async (updateParams: Partial<UserTable>): Promise<UserData | undefined> => {
  try {
    const response = await fetch( process.env.REACT_APP_SERVER_URL + '/user', {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({updateParams}),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    console.log(data);
    return data.data as UserData;
  } catch (error) {
    console.error("Error:", error);
    return undefined;
  }
}