import { Box, FormControl, FormLabel, Input, Select, Option, Button } from "@mui/joy";
import { KeyValueRowRender, RowTableForm, StringRow, StringRowRender } from "./RowTableForm";

import { useContext, useEffect } from "react";
import { FluxoData } from "../../utils/fluxoDataTypes";
import { FluxoDataContext } from "../../contexts";
import { PostFluxoBottom } from "./PostFluxoBottom";

export const InstructionsSection = ({ script }: { script: FluxoData }) => {
  const { setFluxosData } = useContext(FluxoDataContext);

  const updateName = (name: string) => {
    if (name.length < 50) script.fluxo_name = name;
    script.is_saved = false; setFluxosData((prev) => [
      script,
      ...prev.filter((f) => f.id !== script.id),
    ]);
  };

  const updateInstructions = (updated: StringRow, remove?: boolean) => {
    const instructions = script.dict_texts.instructions;
    const index = instructions.findIndex((row) => row.id === updated.id);
    if (remove) {
      instructions.splice(index, 1);
    } else {
      instructions[index] = updated;
    }
    script.is_saved = false; setFluxosData((prev) => [
      script,
      ...prev.filter((f) => f.id !== script.id),
    ]);
  };

  const insertInstructions = () => {
    const instructions = script.dict_texts.instructions;
    const newInstruction: StringRow = {
      id : Date.now(),
      content: "",
      editing: true,
    };
    instructions.push(newInstruction);
    script.dict_texts.instructions = instructions;

    script.is_saved = false; setFluxosData((prev) => [
      script,
      ...prev.filter((f) => f.id !== script.id),
    ]);
  };

  const updateRestrictions = (updated: StringRow, remove?: boolean) => {
    const restrictions = script.dict_texts.restrictions;
    const index = restrictions.findIndex((row) => row.id === updated.id);
    if (remove) {
      restrictions.splice(index, 1);
    } else {
      restrictions[index] = updated;
    }
    script.is_saved = false; setFluxosData((prev) => [
      script,
      ...prev.filter((f) => f.id !== script.id),
    ]);
  };

  const insertRestrictions = () => {
    const restrictions = script.dict_texts.restrictions;
    const newRestriction: StringRow = {
      id :Date.now(),
      content: "",
      editing: true,
    };
    restrictions.push(newRestriction);
    script.is_saved = false; setFluxosData((prev) => [
      script,
      ...prev.filter((f) => f.id !== script.id),
    ]);
  };

  return (
    <Box
      sx={{
        margin: "auto",
        maxWidth: 500,
        display: "flex",
        flexDirection: "column",
        pb: 10,
        gap: 3,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 2,
          width: "100%",
        }}
      >
        <FormControl sx={{ flex: 1 }}>
          <FormLabel>Nome do fluxo</FormLabel>
          <Input
            placeholder="Placeholder"
            value={script.fluxo_name}
            onChange={(e) => {
              updateName(e.target.value);
            }}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Tipo</FormLabel>
          <Select
            value={"PRODUCT"}
            onChange={(e, newValue) => {
              // setFluxoType(newValue || ""); // Atualiza o estado do tipo do fluxo
            }}
          >
            <Option value="PRODUCT">Produto</Option>
            <Option value="SERVICE">Serviço</Option>
          </Select>
        </FormControl>
      </Box>

      <RowTableForm
        title="Instruções de Comportamento"
        rows={(script.dict_texts.instructions as StringRow[]).map(
          (row, index, array) => (
            <StringRowRender
              row={row}
              updateRow={updateInstructions}
              key={row.id}
            />
          )
        )}
        insertButtonTitle="Adicionar instrução"
        insert={insertInstructions}
      />

      <RowTableForm
        title="Restrições"
        rows={(script.dict_texts.restrictions as StringRow[]).map(
          (value, index, array) => (
            <StringRowRender
              row={value}
              updateRow={updateRestrictions}
              key={value.id}
            />
          )
        )}
        insertButtonTitle="Adicionar restrição"
        insert={insertRestrictions}
      />

      <PostFluxoBottom fluxo={script}  />
    </Box>
  );
};
