import { createContext, useEffect, useState } from "react";
import { ChatTable, MessageTable, TagTable } from "../utils/databaseTypes";
import { getChatMessages } from "../hooks/chats";
import { getTags } from "../hooks/tags";
import { MessagePrototype } from "../utils/types";

interface ChatsContextData {
  chatsData: ChatTable[];
  messagesData: MessageTable[];
  tagsData: TagTable[];
  setChatsData: React.Dispatch<React.SetStateAction<ChatTable[]>>;
  setMessagesData: React.Dispatch<React.SetStateAction<MessageTable[]>>;
  setTagsData: React.Dispatch<React.SetStateAction<TagTable[]>>;
  handleNewMessage: (data: {
    chat: ChatTable;
    message: MessagePrototype;
  }) => void;
  handleUpdateChats: (updatedChats: ChatTable[], remove?: boolean) => void;
  handleUpdateMessages: (updatedMessages: MessageTable[]) => void;
  handleUpdateTags: (updatedTags: TagTable[], remove?: boolean) => void;
}

export const ChatsDataContext = createContext<ChatsContextData>(
  {} as ChatsContextData
);
interface ChatsProviderProps {
  children: React.ReactNode;
}

export const ChatsDataProvider: React.FC<ChatsProviderProps> = ({
  children,
}) => {
  const [chatsData, setChatsData] = useState<ChatTable[]>([]);
  const [messagesData, setMessagesData] = useState<MessageTable[]>([]);
  const [tagsData, setTagsData] = useState<TagTable[]>([]);

  

  const handleNewMessage = (data: {
    chat: ChatTable;
    message: MessagePrototype;
  }) => {
    const { chat, message } = data;

    setChatsData((prevChatsData) => {
      return [
        chat,
        ...prevChatsData.filter((prevChat) => prevChat.id !== chat.id),
      ];
    });

    const newMessage: MessageTable = {
      id: messagesData.length + 1,
      content: message.content,
      timestamp: message.timestamp,
      chat_id: chat.id,
      is_from_me: message.is_from_me,
      type: message.type,
      is_transcribed: message.is_transcribed,
      media_type: message.media_type,
      media_url: message.media_url,
      media_filename: message.media_filename,
      has_media: message.has_media,
    };
    setMessagesData((prevState) => [...prevState, newMessage]);
  };

  const handleUpdateChats = (
    updatedChats: ChatTable[],
    remove: boolean = false
  ) => {
    if (!updatedChats.length) return;

    if (remove) {
      setChatsData((prevState) => {
        return prevState.filter(
          (chat) =>
            !updatedChats.find((updatedChat) => updatedChat.id === chat.id)
        );
      });
      return;
    }
    setChatsData((prevState) => {
      const newChats = prevState.map((chat) => {
        const updatedChat = updatedChats.find(
          (updatedChat) => updatedChat.id === chat.id
        );
        return updatedChat ? updatedChat : chat;
      });
      return newChats;
    });
  };

  const handleUpdateMessages = (updatedMessages: MessageTable[]) => {
    setMessagesData((prevState) => {
      const newMessages = prevState.map((message) => {
        const updatedMessage = updatedMessages.find(
          (updatedMessage) => updatedMessage.id === message.id
        );
        return updatedMessage ? updatedMessage : message;
      });
      return newMessages;
    });
  };

  const handleUpdateTags = (
    updatedTags: TagTable[],
    remove: boolean = false
  ) => {
    if (remove) {
      setTagsData((prevState) => {
        return prevState.filter(
          (tag) => !updatedTags.find((updatedTag) => updatedTag.id === tag.id)
        );
      });
      return;
    }
    if (!tagsData.find((tag: TagTable) => tag.id === updatedTags[0].id))
      setTagsData((prevState) => [...prevState, updatedTags[0]]);

    setTagsData((prevState) => {
      const newTags = prevState.map((tag) => {
        const updatedTag = updatedTags.find(
          (updatedTag) => updatedTag.id === tag.id
        );
        return updatedTag ? updatedTag : tag;
      });
      return newTags;
    });
  };

  useEffect(() => {
    getChatMessages(setMessagesData, setChatsData);
    getTags(setTagsData);
  }, []);

  return (
    <ChatsDataContext.Provider
      value={{
        chatsData,
        setChatsData,
        messagesData,
        setMessagesData,
        tagsData,
        setTagsData,
        handleNewMessage,
        handleUpdateChats,
        handleUpdateMessages,
        handleUpdateTags,
      }}
    >
      {children}
    </ChatsDataContext.Provider>
  );
};
