import Box from "@mui/joy/Box";
import { Sidebar } from "./components/Sidebar";
import { Header } from "./components/Header";
import {
  FluxoDataProvider,
  ChatsDataProvider,
  WSConnectionProvider,
  UserDataProvider,
  ConnectionsProvider,
} from "./contexts";
import {
  experimental_extendTheme as materialExtendTheme,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  THEME_ID as MATERIAL_THEME_ID,
} from "@mui/material/styles";
import { CssVarsProvider as JoyCssVarsProvider } from "@mui/joy/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import {
  AutomationPage,
  DashboardPage,
  ConnectionsPage,
  MessagesPage,
  SettingsPage,
  StreamingPage,
  SavedPage,
  SignInSide,
  SignUpSide,
  ScriptPage,
} from "./components/index";
import { HomePage } from "./pages/homePage/homePage";


const materialTheme = materialExtendTheme();

export const App = () => {
  return (
    <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
      <JoyCssVarsProvider>
        <CssBaseline enableColorScheme />

        <BrowserRouter>
          <RoutesComponent />
        </BrowserRouter>
      </JoyCssVarsProvider>
    </MaterialCssVarsProvider>
  );
};

const ContextsCommon = ({ children }: { children: React.ReactNode }) => {
  return (
    <UserDataProvider>
      <ChatsDataProvider>
        <ConnectionsProvider>
          <FluxoDataProvider>
            <WSConnectionProvider>
              <Box sx={{ display: "flex", minHeight: "100dvh" }}>
                <Sidebar />
                <Header />
                <Box
                  component="main"
                  className="MainContent"
                  sx={{
                    flex: 1,
                    pt: { xs: "var(--Header-height)", md: 0 },
                  }}
                >
                  {children}
                </Box>
              </Box>
            </WSConnectionProvider>
          </FluxoDataProvider>
        </ConnectionsProvider>
      </ChatsDataProvider>
    </UserDataProvider>
  );
};

const RoutesComponent = () => {
  return (
    <Routes>
      <Route path="/entrar" element={<SignInSide />} />
      <Route path="/signup" element={<SignUpSide />} />
      <Route path="/home" element={<HomePage />} />
      <Route path="*" element={<HomePage />} />
      <Route
        path="/salvos"
        element={
          <ContextsCommon>
            <SavedPage />
          </ContextsCommon>
        }
      />
      <Route
        path="/automacao"
        element={
          <ContextsCommon>
            <AutomationPage />
          </ContextsCommon>
        }
      />
      <Route
        path="/dashboard"
        element={
          <ContextsCommon>
            <DashboardPage />
          </ContextsCommon>
        }
      />
      <Route
        path="/transmissao"
        element={
          <ContextsCommon>
            <StreamingPage />
          </ContextsCommon>
        }
      />
      <Route
        path="/mensagens"
        element={
          <ContextsCommon>
            <MessagesPage />
          </ContextsCommon>
        }
      />
      <Route
        path="/fluxos"
        element={
          <ContextsCommon>
            <ScriptPage />
          </ContextsCommon>
        }
      />
      <Route
        path="/conexao"
        element={
          <ContextsCommon>
            <ConnectionsPage />
          </ContextsCommon>
        }
      />
      <Route
        path="/configuracoes"
        element={
          <ContextsCommon>
            <SettingsPage />
          </ContextsCommon>
        }
      />
    </Routes>
  );
};
