import { UserData } from "../utils/types";


export const login = async (
  email: string,
  password: string
): Promise<UserData | undefined> => {
  try {
    const response = await fetch( process.env.REACT_APP_SERVER_URL + "/signin", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
    });
    
    // if (!response.ok) {
    //   throw new Error(`HTTP error! Status: ${response.status}`);
    // }

    const data = await response.json();
    if (response.status !== 200) {
      alert(data.msg);
      return undefined;
    }
    localStorage.setItem("token", data.token);
    return data.user as UserData;
  } catch (error) {
    console.error("Error:", error);
    return undefined;
  }
};


export const register = async (
  name: string,
  email: string,
  password: string
): Promise<boolean> => {
  try {
    const response = await fetch( process.env.REACT_APP_SERVER_URL + "/signup", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name, email, password }),
    });
    
    // if (!response.ok) {
    //   throw new Error(`HTTP error! Status: ${response.status}`);
    // }

    const data = await response.json();
    alert(data.msg);
    return response.status === 201
  } catch (error) {
    console.error("Error:", error);
    return false;
  }
}