import * as React from "react";
import Stack from "@mui/joy/Stack";
import Sheet from "@mui/joy/Sheet";
import Typography from "@mui/joy/Typography";
import { Box, Chip, IconButton, Input, Select, Option } from "@mui/joy";
import List from "@mui/joy/List";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ChatListItem from "./ChatListItem";
import { toggleMessagesPane } from "../../utils/utils";
import { useContext, useEffect, useState } from "react";
import { ChatTable } from "../../utils/databaseTypes";
import { ConnectionsContext } from "../../contexts";
import { FixedSizeList } from "react-window";

type ChatsPaneProps = {
  chats: ChatTable[];
  setSelectedChat: (chat: ChatTable) => void;
  selectedChatId: number | undefined;
};

export default function ChatsPane(props: ChatsPaneProps) {
  const { chats, setSelectedChat, selectedChatId } = props;
  const [query, setQuery] = useState("");
  const [chatsFiltered, setChatsFiltered] = useState<ChatTable[]>([]);
  const { whatsappPhones } = useContext(ConnectionsContext);
  const [selectedWhatsappId, setSelectedWhatsappId] = useState<number | null>(
    null
  );
  const [listHeight, setListHeight] = useState(500);
  useEffect(() => {
    const handleResize = () => {
      const availableHeight = window.innerHeight - 150 - 50;
      setListHeight(availableHeight);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (query.length > 0)
      setChatsFiltered(
        chats.filter(
          (chat) =>
            chat.contact_phone.includes(query.toLowerCase()) ||
            chat.contact_name.toLowerCase().includes(query.toLowerCase())
        )
      );
    else if (selectedWhatsappId)
      setChatsFiltered(
        chats
          .filter((chat) => chat.whatsapp_id === selectedWhatsappId)
          .sort((a, b) => b.last_date - a.last_date)
      );
    else setChatsFiltered(chats.sort((a, b) => b.last_date - a.last_date));
  }, [chats, query, selectedWhatsappId]);

  return (
    <Sheet
      sx={{
        borderRight: "1px solid",
        borderColor: "divider",
        height: { sm: "calc(100dvh - var(--Header-height))", md: "100dvh" },
        display: "flex",
        flexDirection: "column",
        flex: 1,
      }}
    >
      <Box
        sx={{
          px: { xs: 2, md: 6 },
          mt: 1,
          display: "flex",
          flexWrap: "nowrap",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography
          level="h2"
          component="h1"
          sx={{ mt: 1, mb: 0 }}
          endDecorator={
            <Chip
              variant="soft"
              color="primary"
              size="md"
              slotProps={{ root: { component: "span" } }}
            >
              {chats.length}
            </Chip>
          }
        >
          Mensagens
        </Typography>
        <IconButton
          variant="plain"
          aria-label="edit"
          color="neutral"
          size="sm"
          onClick={() => {
            toggleMessagesPane();
          }}
          sx={{ display: { sm: "none" } }}
        >
          <CloseRoundedIcon />
        </IconButton>
      </Box>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="space-between"
        p={2}
        pb={1.5}
      ></Stack>
      <Box sx={{ px: 2, pb: 1.5 }}>
        <Input
          size="sm"
          startDecorator={<SearchRoundedIcon />}
          placeholder="numero ou nome"
          value={query}
          aria-label="Search"
          onChange={(e) => setQuery(e.target.value)}
        />
      </Box>
      <Select
        sx={{ m: 2, mt: 1 }}
        placeholder="Selecione o whatsapp"
        value={selectedWhatsappId}
        onChange={(e, value) => setSelectedWhatsappId(value)}
      >
        {whatsappPhones.map((phone) => (
          <Option key={phone.id} value={phone.id}>
            {phone.name}
          </Option>
        ))}
      </Select>
      {/* <List
        sx={{
          py: 0,
          "--ListItem-paddingY": "0.75rem",
          "--ListItem-paddingX": "1rem",
          overflow: "auto",
          pb: 10,
        }}
      >
        {chatsFiltered.sort((a, b)=> b.last_date - a.last_date ).map((chat) => (
          <ChatListItem
            key={chat.id}
            chat={chat}
            setSelectedChat={setSelectedChat}
            selectedChatId={selectedChatId}
          />
        ))}
      </List> */}

      <List
        sx={{py: 0, "--ListItem-paddingY": "0.75rem", "--ListItem-paddingX": "1rem" }}
      >
        <FixedSizeList
          height={listHeight} // Height of the visible container
          itemCount={chatsFiltered.length} // Number of filtered chats
          itemSize={70} // Height of each list item
          width="100%" // Width of the container
        >
          {({ index, style }) => {
            const chat = chatsFiltered[index]; // Access each chat item
            return (
              <div style={style} key={chat.id}>
                <ChatListItem
                  chat={chat}
                  setSelectedChat={setSelectedChat}
                  selectedChatId={selectedChatId}
                />
              </div>
            );
          }}
        </FixedSizeList>
      </List>
    </Sheet>
  );
}
