import React from "react";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import PendingActionsRoundedIcon from "@mui/icons-material/PendingActionsRounded";
import { Button } from "@mui/joy";

interface AutomationPageProps {
  // Define the props for your component here
}

export const AutomationPage: React.FC<AutomationPageProps> = (props) => {
  // Implement your component logic here

  return (
    <Box sx={{ flex: 1, width: "100%" }}>
      <Box>
        <Box sx={{ px: { xs: 2, md: 6 }, mt: 2 }}>
          <Typography
            level="h2"
            component="h1"
            sx={{ mt: 1, mb: 2 }}
            endDecorator={<PendingActionsRoundedIcon />}
          >
            Automação
          </Typography>
          <Button variant="soft" color="warning">
            EM BREVE
          </Button>
        </Box>
        {/* <Tabs
          defaultValue={0}
          sx={{
            bgcolor: "transparent",
          }}
        >
          <TabList
            tabFlex={1}
            size="sm"
            sx={{
              pl: { xs: 0, md: 4 },
              justifyContent: "left",
              [`&& .${tabClasses.root}`]: {
                fontWeight: "600",
                flex: "initial",
                color: "text.tertiary",
                [`&.${tabClasses.selected}`]: {
                  bgcolor: "transparent",
                  color: "text.primary",
                  "&::after": {
                    height: "2px",
                    bgcolor: "primary.500",
                  },
                },
              },
            }}
          >
            <Tab sx={{ borderRadius: "6px 6px 0 0" }} indicatorInset value={0}>
              Transmissão
            </Tab>
            <Tab sx={{ borderRadius: "6px 6px 0 0" }} indicatorInset value={1}>
              Etiquetas
            </Tab>
            <Tab sx={{ borderRadius: "6px 6px 0 0" }} indicatorInset value={2}>
              Respostas rapidas
            </Tab>
          </TabList>
        </Tabs> */}
      </Box>

      <Box
        sx={{
          gap: 2,
          mt: 2,
          p: 2,
          px: { xs: 2, md: 6 },
        }}
      ></Box>
    </Box>
  );
};
