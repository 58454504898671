import {
  Box,
  Card,
  Dropdown,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
} from "@mui/joy";
import Typography from "@mui/joy/Typography";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import PermPhoneMsgRoundedIcon from "@mui/icons-material/PermPhoneMsgRounded";
import MonetizationOnRoundedIcon from "@mui/icons-material/MonetizationOnRounded";
import { ChatTable, MessageTable } from "../../utils/databaseTypes";
import { periods } from "./DashboardPage";

export const OverviewCard = ({
  setPeriod,
  period,
  totalChatsData,
  totalMessagesData,
}: {
  period: string;
  setPeriod: React.Dispatch<React.SetStateAction<string>>;
  totalChatsData: ChatTable[];
  totalMessagesData: MessageTable[];
}) => {
  const chatsOnPeriod: ChatTable[] = totalChatsData.filter(
    (chat: ChatTable) =>
      chat.initial_date >
      new Date().getTime() / 1000 - (periods.get(period) ?? 0)
  );
  const chatOnLastPeriod: ChatTable[] = totalChatsData.filter(
    (chat: ChatTable) =>
      chat.initial_date >
      new Date().getTime() / 1000 - ( 2 * ( periods.get(period) ?? 0))
      && 
      chat.initial_date < 
      new Date().getTime() / 1000 - ( periods.get(period) ?? 0)
  );
  const growthRate = (chatsOnPeriod.length - chatOnLastPeriod.length) / chatOnLastPeriod.length >= 1 ? chatOnLastPeriod.length : 1  ;

  return (
    <Card
      variant="soft"
      sx={{
        borderRadius: 10,
        height: "100%",
        width: "100%",
        border: "1px solid",
        borderColor: "divider",
        padding: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        overflow: "hidden",
      }}
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            pb: 2,
            flexWrap: "wrap",
          }}
        >
          <Typography level="h2">Visão Geral</Typography>
          <Dropdown>
            <MenuButton>
              {period} <ArrowDropDownIcon />
            </MenuButton>
            <Menu>
              <MenuItem onClick={() => setPeriod("DIARIA")}>DIARIO</MenuItem>
              <MenuItem onClick={() => setPeriod("SEMANAL")}>SEMANAL</MenuItem>
              <MenuItem onClick={() => setPeriod("MENSAL")}>MENSAL</MenuItem>
              <MenuItem onClick={() => setPeriod("ANUAL")}>ANUAL</MenuItem>
            </Menu>
          </Dropdown>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "nowrap" }}>
          <Typography
            sx={{
              fontSize: { xs: 40, sm: 22, md: 30, lg: 40 },
              textWrap: "nowrap",
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
            }}
            fontWeight={600}
            color="primary"
          >
            {`${
              totalChatsData.filter(
                (chat: ChatTable) => chat.status === "FINISHED"
              ).length
            } finalizados`}
            { growthRate > 1 && <TrendingUpIcon color="primary" fontSize="large" />}
          </Typography>
          {growthRate > 1 && <Typography
            sx={{
              fontSize: { xs: 18, sm: 12, md: 12, lg: 18 },
              textWrap: "nowrap",
            }}
            color="primary"
          >
            {`+ ${growthRate}%`}
          </Typography>}
        </Box>
        <Typography
          sx={{ fontSize: { xs: 18, sm: 10, md: 12, lg: 18 }, mb: 2 }}
          color="neutral"
        >
          conversas concluidas {period.toLowerCase()}
        </Typography>
      </Box>

      <Box>
        <Box
          sx={{
            px: 2,
            pb: 2,
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            gap: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
            }}
          >
            <PermPhoneMsgRoundedIcon
              color="action"
              sx={{
                backgroundColor: "",
                fontSize: "3.8rem",
                borderRadius: 2,
                borderWidth: 2,
                borderStyle: "solid",
                borderColor: "inherit",
              }}
            />
            <Box sx={{ display: "flex", flexDirection: "column", ml: 2 }}>
              <Typography level="h4" fontWeight={600} pb={0}>
                {totalChatsData.length}
              </Typography>
              <Typography level="h4" color="neutral">
                contatos
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
            }}
          >
            <MonetizationOnRoundedIcon
              color="action"
              
              sx={{
                backgroundColor: "",
                fontSize: "3.8rem",
                borderRadius: 2,
                borderWidth: 2,
                borderStyle: "solid",
                borderColor: "inherit",
              }}
            />
            <Box sx={{ display: "flex", flexDirection: "column", ml: 2 }}>
              <Typography level="h4" fontWeight={600} pb={0}>
                {totalMessagesData.length}
              </Typography>
              <Typography level="h4" color="neutral">
                Mensagens
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};
