import { Box } from '@mui/joy';
import React from 'react';
import HeroLeft01 from './HeroLeft01';

export const HomePage: React.FC = () => {
    return (
        <Box
        sx={{
          height: '100vh',
          overflowY: 'scroll',
        }}
      >
        <HeroLeft01 />
      </Box>
    );
};
