import { createContext, useEffect, useState } from "react";
import { getWhatsappConnections } from "../hooks/connections";
import { WhatsappTable } from "../utils/databaseTypes";

interface ConnectionsContextData {
  whatsappPhones: WhatsappTable[];
  setWhatsappPhones: React.Dispatch<React.SetStateAction<WhatsappTable[]>>;
  handleWhatsappConnectionUpdated: (data: {
    conectionUpdated: WhatsappTable;
  }) => void;
  qrCodeConection: string;
  setQrCodeConection: React.Dispatch<React.SetStateAction<string>>;
  handleQrCodeConection: (data: {
    qrcode: string;
    status: "CONNECTING" | "TIMEOUT" | "CONNECTED";
  }) => void;
  QRcodeStatus: "CONNECTING" | "TIMEOUT" | "CONNECTED" | undefined;
}

export const ConnectionsContext = createContext<ConnectionsContextData>(
  {} as ConnectionsContextData
);

interface ConnectionsProviderProps {
  children: React.ReactNode;
}

export const ConnectionsProvider: React.FC<ConnectionsProviderProps> = ({
  children,
}) => {
  const [whatsappPhones, setWhatsappPhones] = useState<WhatsappTable[]>([]);
  const [qrCodeConection, setQrCodeConection] = useState<string>("");
  const [QRcodeStatus, setQRcodeStatus] = useState<
    "CONNECTING" | "TIMEOUT" | "CONNECTED" | undefined
  >();

  useEffect(() => {
    getWhatsappConnections(setWhatsappPhones);
  }, []);

  const handleWhatsappConnectionUpdated = (
    data: { conectionUpdated: WhatsappTable },
    remove: boolean = false
  ) => {
    const { conectionUpdated } = data;
    if (!conectionUpdated) return;

    setWhatsappPhones((prevState) => {
      if (remove) {
        console.log("remove", conectionUpdated);
        return prevState.filter((phone) => phone.id !== conectionUpdated.id);
      } else {
        const index = prevState.findIndex(
          (phone) => phone.id === conectionUpdated.id
        );
        if (index === -1) return [...prevState, conectionUpdated];
        return prevState.map((phone) =>
          phone.id === conectionUpdated.id ? conectionUpdated : phone
        );
      }
    });
  };

  const handleQrCodeConection = (data: {
    qrcode: string;
    status: "CONNECTING" | "TIMEOUT" | "CONNECTED";
  }) => {
    setQrCodeConection(data.qrcode);
    setQRcodeStatus(data.status);
  };

  return (
    <ConnectionsContext.Provider
      value={{
        whatsappPhones,
        setWhatsappPhones,
        handleWhatsappConnectionUpdated,
        qrCodeConection,
        setQrCodeConection,
        handleQrCodeConection,
        QRcodeStatus,
      }}
    >
      {children}
    </ConnectionsContext.Provider>
  );
};
