import * as React from "react";
import GlobalStyles from "@mui/joy/GlobalStyles";
import Avatar from "@mui/joy/Avatar";
import Box from "@mui/joy/Box";
import Chip from "@mui/joy/Chip";
import Divider from "@mui/joy/Divider";
import IconButton from "@mui/joy/IconButton";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemButton, { listItemButtonClasses } from "@mui/joy/ListItemButton";
import ListItemContent from "@mui/joy/ListItemContent";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import QuestionAnswerRoundedIcon from "@mui/icons-material/QuestionAnswerRounded";
import ChatIcon from "@material-ui/icons/Chat";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import BarChartRoundedIcon from "@mui/icons-material/BarChartRounded";
import SmartToyRoundedIcon from "@mui/icons-material/SmartToyRounded";
import HubIcon from "@mui/icons-material/Hub";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import PodcastsIcon from "@mui/icons-material/Podcasts";

import { ModeToggle } from "./ColorSchemeToggle";
import { closeSidebar } from "../utils/utils";
import { Link, useNavigate } from "react-router-dom";
import { ChatsDataContext, ConnectionsContext, UserDataContext } from "../contexts";
import { useContext } from "react";

function Toggler(props: {
  defaultExpanded?: boolean;
  children: React.ReactNode;
  renderToggle: (params: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  }) => React.ReactNode;
}) {
  const { defaultExpanded = false, renderToggle, children } = props;
  const [open, setOpen] = React.useState(defaultExpanded);

  return (
    <React.Fragment>
      {renderToggle({ open, setOpen })}
      <Box
        sx={{
          display: "grid",
          gridTemplateRows: open ? "1fr" : "0fr",
          transition: "0.2s ease",
          "& > *": {
            overflow: "hidden",
          },
        }}
      >
        {children}
      </Box>
    </React.Fragment>
  );
}

export function Sidebar() {
  const { whatsappPhones } = useContext(ConnectionsContext);
  
  const navigate = useNavigate();
  const { chatsData } = useContext(ChatsDataContext);
  const { userData }  = useContext(UserDataContext);
  const [selectedRoute, setSelectedRoute] = React.useState(
    window.location.pathname
  );

  const newsCount = chatsData.filter((chat) => !chat.user_seen).length;

  return (
    <Sheet
      className="Sidebar"
      sx={{
        position: { xs: "fixed", md: "sticky" },
        transform: {
          xs: "translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))",
          md: "none",
        },
        transition: "transform 0.4s, width 0.4s",
        zIndex: 10000,
        height: "100dvh",
        width: "var(--Sidebar-width)",
        top: 0,
        p: 2,
        flexShrink: 0,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        borderRight: "1px solid",
        borderColor: "divider",
      }}
    >
      <GlobalStyles
        styles={(theme) => ({
          ":root": {
            "--Sidebar-width": "220px",
            [theme.breakpoints.up("lg")]: {
              "--Sidebar-width": "240px",
            },
          },
        })}
      />
      <Box
        className="Sidebar-overlay"
        sx={{
          position: "fixed",
          zIndex: 9998,
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          opacity: "var(--SideNavigation-slideIn)",
          backgroundColor: "var(--joy-palette-background-backdrop)",
          transition: "opacity 0.4s",
          transform: {
            xs: "translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))",
            lg: "translateX(-100%)",
          },
        }}
        onClick={() => closeSidebar()}
      />
      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <IconButton variant="soft" color="primary" size="sm" onClick={() => navigate("/home")}>
          <ChatIcon />
        </IconButton>
        <Typography level="title-lg">IA Chats</Typography>
        <ModeToggle />
      </Box>
      <Box
        sx={{
          minHeight: 0,
          overflow: "hidden auto",
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          [`& .${listItemButtonClasses.root}`]: {
            gap: 1.5,
          },
        }}
      >
        <List
          size="sm"
          sx={{
            gap: 1,
            "--List-nestedInsetStart": "30px",
            "--ListItem-radius": (theme) => theme.vars.radius.sm,
          }}
        >
          {[
            {
              route: "/dashboard",
              icon: <BarChartRoundedIcon />,
              title: "Dashboard",
            },
            {
              route: "/mensagens",
              icon: <QuestionAnswerRoundedIcon />,
              title: "Mensagens",
              chipColor: newsCount > 0 ? "primary" : undefined,
              chipContent: newsCount > 0 ? newsCount : undefined,
            },
            {
              route: "/fluxos",
              icon: <DashboardRoundedIcon />,
              title: "Fluxos",
            },
            { route: "/salvos", icon: <BookmarksIcon />, title: "Salvos" },
            {
              route: "/automacao",
              icon: <SmartToyRoundedIcon />,
              title: "Automação",
            },
            {
              route: "/transmissao",
              icon: <PodcastsIcon />,
              title: "Transmissão",
            },
          ].map(({ route, icon, title, chipColor, chipContent } : {
            route: string;
            icon: React.ReactNode;
            title: string;
            chipColor?: string;
            chipContent?: number;
          }) => (
            <Link to={route} key={route}>
              <ListItem >
                <ListItemButton
                  onClick={() => setSelectedRoute(route)}
                  selected={selectedRoute === route}
                >
                  {icon}
                  <ListItemContent>
                    <Typography level="title-sm">{title}</Typography>
                  </ListItemContent>
                  {chipColor && (
                    <Chip size="sm" color="primary" variant="solid">
                      {chipContent}
                    </Chip>
                  )}
                </ListItemButton>
              </ListItem>
            </Link>
          ))}
        </List>
        <List
          size="sm"
          sx={{
            mt: "auto",
            flexGrow: 0,
            "--ListItem-radius": (theme) => theme.vars.radius.sm,
            "--List-gap": "8px",
            mb: 2,
          }}
        >
          {[
            {
              route: "/conexao",
              icon: <HubIcon />,
              title: "Conexões",
              chipColor: whatsappPhones.length > 0 && 'primary',
              chipContent: whatsappPhones.length > 0 && whatsappPhones.length,
            },
            {
              route: "/configuracoes",
              icon: <SettingsRoundedIcon />,
              title: "Configurações",
            },
          ].map(({ route, icon, title, chipColor, chipContent }) => (
            <Link to={route}  key={route}>
              <ListItem>
                <ListItemButton
                  onClick={() => setSelectedRoute(route)}
                  selected={selectedRoute === route}
                >
                  {icon}
                  <ListItemContent>{title}</ListItemContent>
                  {chipColor && (
                    <Chip
                      size="sm"
                      color={chipColor === "error" ? "danger" : "neutral"}
                      variant="solid"
                    >
                      {chipContent}
                    </Chip>
                  )}
                </ListItemButton>
              </ListItem>
            </Link>
          ))}
        </List>
      </Box>
      <Divider />
      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <Avatar
          variant="outlined"
          size="sm"
          src=""
        />
        <Box sx={{ minWidth: 0, flex: 1 }}>
          <Typography level="title-sm">{userData && userData.name.length > 16 ? userData.name.slice(0,16) + '...' : userData?.name}</Typography>
          <Typography level="body-xs">{userData && userData.email.length > 16 ? userData.email.slice(0,16) + '...' : userData?.email}</Typography>
        </Box>
        <Link to="/entrar">
        <IconButton size="sm" variant="plain" color="neutral">
          <LogoutRoundedIcon />
        </IconButton>
        </Link>
      </Box>
    </Sheet>
  );
}
