import * as React from "react";
import Sheet from "@mui/joy/Sheet";
import ScriptPane from "./ScriptPane";
import ScriptsListPane from "./ScriptsListPane";
import { FluxoDataContext } from "../../contexts";
import { useContext } from "react";
import { FluxoData } from "../../utils/fluxoDataTypes";

export function ScriptPage() {
  const [selectedScript, setSelectedScript] = React.useState<FluxoData | undefined>();
  const { fluxosData } = useContext(FluxoDataContext);


  return (
    <Sheet
      sx={{
        flex: 1,
        width: "100%",
        mx: "auto",
        height: "100%",
        display: "grid",
        gridTemplateColumns: {
          xs: "1fr",
          sm: "minmax(min-content, min(30%, 400px)) 1fr auto",
          lg: "minmax(min-content, min(30%, 400px)) 1fr auto",
        },
      }}
    >
      <Sheet
        sx={{
          position: { xs: "fixed", sm: "sticky" },
          transform: {
            xs: "translateX(calc(100% * (var(--MessagesPane-slideIn, 0) - 1)))",
            sm: "none",
          },
          transition: "transform 0.4s, width 0.4s",
          zIndex: 100,
          width: "100%",
          maxWidth: 400,
          height: "100%",
        }}
      >
        <ScriptsListPane
          fluxo={fluxosData}
          selectedScriptId={selectedScript?.id}
          setSelectedFluxo={setSelectedScript}
        />
      </Sheet>
      <ScriptPane fluxo={selectedScript} />
     
    </Sheet>
  );
}
