import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './app/App';
import './index.css'; // Import the CSS file

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <div>
  {/* // <React.StrictMode> */}
      <App />
  {/* // </React.StrictMode> */}
  </div>
);

// reportWebVitals();
