/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import Avatar from "@mui/joy/Avatar";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Chip from "@mui/joy/Chip";
import Divider from "@mui/joy/Divider";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import ModalClose from "@mui/joy/ModalClose";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import Table from "@mui/joy/Table";
import Sheet from "@mui/joy/Sheet";
import Checkbox from "@mui/joy/Checkbox";
import IconButton from "@mui/joy/IconButton";
import Typography from "@mui/joy/Typography";
import Menu from "@mui/joy/Menu";
import MenuButton from "@mui/joy/MenuButton";
import MenuItem from "@mui/joy/MenuItem";
import Dropdown from "@mui/joy/Dropdown";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SearchIcon from "@mui/icons-material/Search";
import BlockIcon from "@mui/icons-material/Block";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItemDecorator,
} from "@mui/joy";
import { ChatsDataContext, FluxoDataContext } from "../../contexts";
import { useContext, useEffect } from "react";
import { formatTimestampFromSeconds } from "../../utils/formatTimestamp";
import { ChatTable, TagTable } from "../../utils/databaseTypes";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import { Stack } from "@mui/material";
import { deleteChat, updateChat } from "../../hooks/chats";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import { FixedSizeList } from "react-window";

export default function ContactsTable() {
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [open, setOpen] = React.useState(false);
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const { chatsData, tagsData } = useContext(ChatsDataContext);
  const [statusFilter, setStatusFilter] = React.useState("ALL");
  const [tagFilter, setTagFilter] = React.useState("ALL");
  const [fluxoFilter, setFluxoFilter] = React.useState("ALL");
  const [search, setSearch] = React.useState("");
  const [openDeleteConfirmation, setOpenDeleteConfirmation] =
    React.useState(false);
  const [selectedChat, setSelectedChat] = React.useState<ChatTable>(
    chatsData[0]
  );

  const filteredChats = chatsData.filter((chat) => {
    if (
      search.length > 0 &&
      !chat.contact_phone.toLocaleLowerCase().includes(search.toLocaleLowerCase()) &&
      !chat.contact_name.toLocaleLowerCase().includes(search.toLocaleLowerCase())
    )
      return false;

    if (statusFilter !== "ALL" && chat.status !== statusFilter) return false;
    if (
      tagFilter !== "ALL" &&
      !chat.tags_id?.find((e) => String(e) === tagFilter)
    )
      return false;
    if (fluxoFilter !== "ALL" && String(chat.script_id) !== fluxoFilter)
      return false;
    return true;
  });
  const rows = filteredChats;
  console.log(rows);

  function RowMenu({ row }: { row: ChatTable }) {
    return (
      <Dropdown>
        <MenuButton
          slots={{ root: IconButton }}
          slotProps={{
            root: { variant: "outlined", color: "neutral", size: "sm" },
          }}
        >
          <MoreHorizRoundedIcon />
        </MenuButton>
        <Menu size="sm" sx={{ minWidth: 140 }}>
          <MenuItem
            onClick={() => {
              setSelectedChat(row);
              setOpenEditModal(true);
            }}
          >
            Editar
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() => {
              setSelectedChat(row);
              setOpenDeleteConfirmation(true);
            }}
            color="danger"
          >
            Deletar
          </MenuItem>
        </Menu>
      </Dropdown>
    );
  }

  const renderFilters = () => (
    <React.Fragment>
      <FormControl size="sm">
        <FormLabel>Tag</FormLabel>
        <Select
          size="sm"
          placeholder="Filter by status"
          value={tagFilter}
          onChange={(
            event: React.SyntheticEvent | null,
            newValue: string | null
          ) => {
            if (newValue) setTagFilter(newValue);
          }}
        >
          <Option value="ALL">TODOS</Option>
          {tagsData.map((tag: TagTable) => (
            <Option key={tag.id} value={tag.id}>
              {tag.name}
            </Option>
          ))}
        </Select>
      </FormControl>
      <FormControl size="sm">
        <FormLabel>Status</FormLabel>
        <Select
          size="sm"
          placeholder="All"
          value={statusFilter}
          onChange={(
            event: React.SyntheticEvent | null,
            newValue: string | null
          ) => {
            if (newValue) setStatusFilter(newValue);
          }}
        >
          <Option value="ALL">TODOS</Option>
          <Option value="LEAD">LEAD</Option>
          <Option value="FINISHED">FINISHED</Option>
        </Select>
      </FormControl>
      <FormControl size="sm">
        <FormLabel>Fluxo</FormLabel>
        <Select
          size="sm"
          placeholder="All"
          value={fluxoFilter}
          onChange={(
            event: React.SyntheticEvent | null,
            newValue: string | null
          ) => {
            if (newValue) setFluxoFilter(newValue);
          }}
        >
          <Option value="ALL">TODOS</Option>
          <Option value="NULL">SEM FLUXO</Option>
        </Select>
      </FormControl>
    </React.Fragment>
  );

  // Defina o componente Row para renderizar cada linha
  const Row = ({ index, style, data } : {
    index: number,
    style: React.CSSProperties,
    data: ChatTable[]
  }) => {
    const row = data[index];
    return (
      <tr style={style} key={index}>
        <td style={{ textAlign: "center", width: 120 }}>
          <Checkbox
            size="sm"
            checked={selected.includes(row.contact_phone)}
            color={
              selected.includes(row.contact_phone) ? "primary" : undefined
            }
            onChange={(event) => {
              setSelected((ids) =>
                event.target.checked
                  ? ids.concat(row.contact_phone)
                  : ids.filter((itemId) => itemId !== row.contact_phone)
              );
            }}
            slotProps={{ checkbox: { sx: { textAlign: "left" } } }}
            sx={{ verticalAlign: "text-bottom" }}
          />
        </td>
        <td>
          <RowMenu row={row} />
        </td>
        <td>
          <Typography level="body-xs">{row.contact_phone}</Typography>
        </td>
        <td>
          <Typography level="body-xs">
            {formatTimestampFromSeconds(row.initial_date)}
          </Typography>
        </td>
        <td>
          <Box
            sx={{ display: "flex", alignItems: "center", gap: 1, p: 1 }}
          >
            <ListItemDecorator>
              <Avatar size="sm">{row.contact_name[0]}</Avatar>
            </ListItemDecorator>
            <Typography level="title-sm">{row.contact_name}</Typography>
          </Box>
        </td>
        <td>
          <Chip variant="soft" size="sm">
            {row.status}
          </Chip>
        </td>
        <td>
          <Chip>{row.script_id}</Chip>
        </td>
      </tr>
    );
  };

  // Componentes personalizados para o FixedSizeList
  const OuterElementType = React.forwardRef<HTMLTableSectionElement, React.HTMLAttributes<HTMLTableSectionElement>>((props, ref) => (
      <tbody ref={ref as React.LegacyRef<HTMLTableSectionElement>} {...props} />
    ));

  const InnerElementType = React.forwardRef((props, ref) => (
    <div {...props} />
  ));

  return (
    <React.Fragment>
      <Sheet
        className="SearchAndFilters-mobile"
        sx={{
          display: { xs: "flex", sm: "none" },
          my: 1,
          gap: 1,
        }}
      >
        <Input
          size="sm"
          placeholder="Search"
          startDecorator={<SearchIcon />}
          sx={{ flexGrow: 1 }}
        />
        <IconButton
          size="sm"
          variant="outlined"
          color="neutral"
          onClick={() => setOpen(true)}
        >
          <FilterAltIcon />
        </IconButton>
        <Modal open={open} onClose={() => setOpen(false)}>
          <ModalDialog aria-labelledby="filter-modal" layout="fullscreen">
            <ModalClose />
            <Typography id="filter-modal" level="h2">
              Filters
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Sheet sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              {renderFilters()}
              <Button color="primary" onClick={() => setOpen(false)}>
                Submit
              </Button>
            </Sheet>
          </ModalDialog>
        </Modal>
      </Sheet>

      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: "sm",
          py: 2,
          display: { xs: "none", sm: "flex" },
          flexWrap: "wrap",
          gap: 1.5,
          "& > *": {
            minWidth: { xs: "120px", md: "160px" },
          },
        }}
      >
        <FormControl sx={{ flex: 1 }} size="sm">
          <FormLabel>Procurar numero ou nome</FormLabel>
          <Input
            size="sm"
            placeholder="Search"
            startDecorator={<SearchIcon />}
            onChange={(event) => setSearch(event.target.value)}
          />
        </FormControl>
        {renderFilters()}
      </Box>

      {/* <ContactsList rows={rows} RowMenu={RowMenu} /> */}

      {selectedChat && openEditModal && (
        <EditContactModal
          chat={selectedChat}
          open={openEditModal}
          onClose={() => setOpenEditModal(false)}
        />
      )}

      {selectedChat && openDeleteConfirmation && (
        <DeleteConfirmationModal
          row={selectedChat}
          open={openDeleteConfirmation}
          onClose={() => setOpenDeleteConfirmation(false)}
        />
      )}


      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={(theme) => ({
          display: { xs: "none", sm: "initial" },
          flexShrink: 1,
          borderRadius: "sm",
          minHeight: 0,
          "--TableCell-height": "40px",
          "--TableHeader-height": "calc(1 * var(--TableCell-height))",
          height: "70vh",
          overflow: "auto",
          background: `linear-gradient(${theme.vars.palette.background.surface} 30%, rgba(255, 255, 255, 0)),
            linear-gradient(rgba(255, 255, 255, 0), ${theme.vars.palette.background.surface} 70%) 0 100%,
            radial-gradient(
              farthest-side at 50% 0,
              rgba(0, 0, 0, 0.12),
              rgba(0, 0, 0, 0)
            ),
            radial-gradient(
                farthest-side at 50% 100%,
                rgba(0, 0, 0, 0.12),
                rgba(0, 0, 0, 0)
              )
              0 100%`,
          backgroundSize: "100% 40px, 100% 40px, 100% 14px, 100% 14px",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "local, local, scroll, scroll",
          backgroundPosition:
            "0 var(--TableHeader-height), 0 100%, 0 var(--TableHeader-height), 0 100%",
          backgroundColor: "background.surface",
        })}
      >
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
          hoverRow
          sx={{
            "--TableCell-headBackground":
              "var(--joy-palette-background-level1)",
            "--Table-headerUnderlineThickness": "1px",
            "--TableRow-hoverBackground":
              "var(--joy-palette-background-level1)",
            "--TableCell-paddingY": "4px",
            "--TableCell-paddingX": "8px",
          }}
        >
          <thead>
            <tr>
              <th
                style={{ width: 48, textAlign: "center", padding: "12px 6px" }}
              >
                <Checkbox
                  size="sm"
                  indeterminate={
                    selected.length > 0 && selected.length !== rows.length
                  }
                  checked={selected.length === rows.length}
                  onChange={(event) => {
                    setSelected(
                      event.target.checked
                        ? rows.map((row) => row.contact_phone)
                        : []
                    );
                  }}
                  color={
                    selected.length > 0 || selected.length === rows.length
                      ? "primary"
                      : undefined
                  }
                  sx={{ verticalAlign: "text-bottom" }}
                />
              </th>
              <th style={{ width: 30, padding: "12px 6px" }}>
                {/* Menu de ações */}
              </th>
              <th style={{ width: 90, padding: "12px 6px" }}>Número</th>
              <th style={{ width: 50, padding: "12px 6px" }}>Inscrição</th>
              <th style={{ width: 130, padding: "12px 6px" }}>Nome</th>
              <th style={{ width: 90, padding: "12px 6px" }}>Status</th>
              <th style={{ width: 80, padding: "12px 6px" }}>Fluxo</th>
            </tr>
          </thead>
          <FixedSizeList
            height={400} // Ajuste a altura conforme necessário
            itemCount={rows.length}
            itemSize={70}
            width="100%"
            itemData={rows}
            outerElementType={OuterElementType}
            innerElementType={InnerElementType}
          >
            {Row}
          </FixedSizeList>
        </Table>
      </Sheet>

      {/* ... (outros componentes) */}
    </React.Fragment>
  );
}
















export const EditContactModal = ({
  chat,
  open,
  onClose,
}: {
  chat: ChatTable;
  open: boolean;
  onClose: () => void;
}) => {
  const { scriptData } = useContext(FluxoDataContext);
  const [loading, setLoading] = React.useState(false);
  const [newStatus, setNewStatus] = React.useState(chat.status);
  const [newScriptId, setNewScriptId] = React.useState(chat.script_id);
  const [newScriptBlock, setNewScriptBlock] = React.useState(chat.script_block);
  const [newIsBotBlocked, setNewIsBotBlocked] = React.useState(
    chat.is_bot_blocked
  );
  const [newIsAutoTranscription, setNewIsAutoTranscription] = React.useState(
    chat.is_auto_transcription
  );
  const { handleUpdateChats } = useContext(ChatsDataContext);

  useEffect(() => {
    setNewScriptBlock(0);
  }, [newScriptId]);

  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog
        sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
      >
        <form
          onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            setLoading(true);
            updateChat(
              {
                status: newStatus,
                script_id: newScriptId,
                script_block: newScriptBlock,
                is_bot_blocked: newIsBotBlocked,
                is_auto_transcription: newIsAutoTranscription,
                contact_phone: chat.contact_phone,
              },
              [chat.id],
              handleUpdateChats
            );
            onClose();
            setLoading(false);
          }}
        >
          <Stack spacing={1}>
            <DialogTitle>Edição do contato</DialogTitle>
            <FormControl>
              <FormLabel>status</FormLabel>
              <Select
                autoFocus
                required
                value={newStatus}
                onChange={(e, value) => {
                  if (value && value.length <= 20) setNewStatus(value);
                }}
              >
                <Option value="LEAD">Iniciado</Option>
                <Option value="FINISHED">Finalizado</Option>
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>fluxo</FormLabel>
              <Select
                defaultValue={chat.script_id}
                onChange={(e, value) => {
                  if (value) setNewScriptId(Number(value) ?? undefined);
                }}
              >
                {scriptData.map((script) => (
                  <Option key={script.id} value={script.id}>
                    {script.name}
                  </Option>
                ))}
              </Select>
            </FormControl>

            <FormControl>
              <Checkbox
                sx={{ my: 0.5 }}
                label="fluxo IA bloqueiado"
                checked={newIsBotBlocked}
                onChange={(e) => setNewIsBotBlocked(e.target.checked)}
              />
            </FormControl>

            <FormControl>
              <Checkbox
                sx={{ my: 0.5 }}
                label="transcrição de audio automatica"
                checked={newIsAutoTranscription}
                onChange={(e) => setNewIsAutoTranscription(e.target.checked)}
              />
            </FormControl>
          </Stack>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 2,
              mt: 2,
            }}
          >
            <Button
              onClick={() => {
                setLoading(false);
                onClose();
              }}
              variant="outlined"
            >
              Cancelar
            </Button>
            <Button loading={loading} type="submit" color="primary">
              Salvar
            </Button>
          </Box>
        </form>
      </ModalDialog>
    </Modal>
  );
};

const DeleteConfirmationModal = ({
  row,
  open,
  onClose,
}: {
  row: ChatTable;
  open: boolean;
  onClose: () => void;
}) => {
  const { handleUpdateChats } = useContext(ChatsDataContext);
  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog variant="outlined" role="alertdialog">
        <DialogTitle color="danger">
          <WarningRoundedIcon />
          Confirmação de exclusão
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Typography>
            Você tem certeza que deseja excluir o contato de{" "}
            <strong>{row.contact_name}</strong> de forma{" "}
            <Typography color="danger" fontWeight={800}>
              permanente
            </Typography>
            ?
          </Typography>
          <Typography>
            todo o histórico de{" "}
            <Typography color="warning">mensagens</Typography> será perdido.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="solid"
            color="danger"
            onClick={async () => {
              const deletedChat = await deleteChat([row.id]);
              if (deletedChat) handleUpdateChats(deletedChat, true);
              onClose();
            }}
          >
            Excluir para sempre
          </Button>
          <Button variant="outlined" color="neutral" onClick={onClose}>
            cancelar
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};
